import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormHelperText, Grid, Divider, Checkbox, Typography, Stack, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import keyBy from 'lodash/keyBy';
import { styles } from './styles';
import TextField from '../../../components/common/InputField/TextField';
import { formDataApi } from '../../../axios';
import UserRoles from '../../roles/UserRoles';
import { hasPermission } from '../../../utils/permission';

const UserDetailsTab = ({ isEditMode, userDetails }) => {
    const navigate = useNavigate();
    const { authorityList = [], roleIDBasedMap = {}, companyList = [], company = {} } = userDetails?.user || {};
    const authorities = useSelector((state) => state.session.user.authorities);
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState('');
    const params = useParams();

    const isSuperAdminUser = hasPermission(authorities, ['ROLE_SUPER_ADMINISTRATOR']);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isSuperAdminUser && params?.companyId) setSelectedCompany(params.companyId);
    }, [isSuperAdminUser, params]);

    useEffect(() => {
        if (!isSuperAdminUser && company) setSelectedCompany(company.id);
    }, [isSuperAdminUser, company]);

    const getDefaultSelectedRoles = () => {
        if (isSuperAdminUser) {
            const adminRoleId = authorityList.find((item) => item.authority === 'ROLE_ADMINISTRATOR');
            return adminRoleId ? [adminRoleId?.id] : [];
        } else {
            const selectedRoleValues = Object.keys(roleIDBasedMap).filter((item) => roleIDBasedMap[item] === true);
            return selectedRoleValues.map(Number);
        }
    };

    const handleCustomDetails = async (details) => {
        try {
            setLoading(true);
            const authorityListObject = keyBy(authorityList, 'id');

            const bodyFormData = new FormData();
            for (const key in details) {
                if (key === 'firstName') {
                    bodyFormData.append('employee.firstName', details['firstName']);
                } else if (key === 'lastName') {
                    bodyFormData.append('employee.lastName', details['lastName']);
                } else if (key === 'email') {
                    bodyFormData.append('employee.emailAddresses', details['email']);
                } else if (key === 'mobileNumber') {
                    bodyFormData.append('employee.mobileNumbers', details['mobileNumber']);
                } else if (key === 'enabled') {
                    bodyFormData.append('enabled', details['enabled'] ? 'on' : 'off');
                } else if (key === 'passwordExpired') {
                    bodyFormData.append('passwordExpired', details['passwordExpired'] ? 'on' : 'off');
                } else if (key === 'accountLocked') {
                    bodyFormData.append('accountLocked', details['accountLocked'] ? 'on' : 'off');
                } else if (key === 'sendCredentials') {
                    bodyFormData.append('sendCredentials', details['sendCredentials'] ? true : false);
                } else if (key === 'selectedRoles') {
                    details['selectedRoles'].forEach((selectedRole) => {
                        bodyFormData.append(authorityListObject[selectedRole]?.['authority'], 'on');
                    });
                } else bodyFormData.append(key, details[key]);
            }

            bodyFormData.append('company', selectedCompany);
            bodyFormData.append('password', '123456789afAF');
            const pathToCall = isEditMode ? '/modifyUserAction' : '/saveUser';
            if (!isEditMode) bodyFormData.delete('id');
            const response = await formDataApi.post(pathToCall, bodyFormData);
            if (response?.data?.status !== 'ERROR') {
                enqueueSnackbar(response.data.message, {
                    variant: 'success',
                });
                navigate(params.companyId ? `/company/${params.companyId}/users` : '/users');
            } else {
                enqueueSnackbar(response.data.message, {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    const formik = useFormik({
        initialValues: {
            id: userDetails?.user?.secUserInstance?.id ?? '',
            username: userDetails?.user?.employeeInstance?.user.username ?? '',
            lastName: userDetails?.user?.employeeInstance?.lastName ?? '',
            firstName: userDetails?.user?.employeeInstance?.firstName ?? '',
            email:
                (userDetails?.user?.employeeInstance?.emailAddresses?.length &&
                    userDetails?.user?.employeeInstance?.emailAddresses?.[0]) ||
                '',
            mobileNumber: userDetails?.user?.employeeInstance?.mobileNumbers?.[0] ?? '',
            enabled: userDetails?.user?.secUserInstance?.enabled ?? false,
            accountLocked: userDetails?.user?.secUserInstance?.accountLocked ?? false,
            passwordExpired: userDetails?.user?.secUserInstance?.passwordExpired ?? false,
            sendCredentials: true,
            selectedRoles: getDefaultSelectedRoles(),
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            firstName: Yup.string().max(255).required('First Name is required'),
            lastName: Yup.string().max(255).required('Last Name is required'),
            username: Yup.string().max(255).required('User Name is required'),
            email: Yup.string().email('Invalid Email ID').required('Email ID is required'),
            mobileNumber: Yup.number()
                .typeError('Invalid Contact Number')
                .positive('Invalid Contact Number')
                .test('len', 'Invalid Contact Number', (value) => {
                    const length = value?.toString().length || 0;
                    return length === 0 || length === 10;
                })
                .optional(),
        }),
        onSubmit: async (values, helpers) => {
            try {
                const initialValues = { ...values };
                handleCustomDetails(initialValues);
                // helpers.resetForm();
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
            } catch (err) {
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({
                    submit: err.message,
                });
                helpers.setSubmitting(false);
            }
        },
    });
    const { firstName, lastName, username, email, selectedRoles } = formik.values;

    const isDisabled = useMemo(() => {
        return firstName === '' || lastName === '' || username === '' || email === '' || selectedRoles.length <= 0;
    }, [formik.values]);

    if (userDetails?.isLoading) {
        return (
            <Box sx={{ py: 4 }}>
                <Skeleton height={42} />
                <Skeleton />
                <Skeleton />
            </Box>
        );
    }

    return (
        <>
            <Typography mb={3}>User Details</Typography>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <Grid container columnSpacing={6} rowSpacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.username && formik.errors.username)}
                                        fullWidth
                                        helperText={formik.touched.username && formik.errors.username}
                                        label="Username"
                                        name="username"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.email && formik.errors.email)}
                                        fullWidth
                                        helperText={formik.touched.email && formik.errors.email}
                                        label="Email ID"
                                        name="email"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                        fullWidth
                                        helperText={formik.touched.firstName && formik.errors.firstName}
                                        label="First Name"
                                        name="firstName"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                        fullWidth
                                        helperText={formik.touched.lastName && formik.errors.lastName}
                                        label="Last Name"
                                        name="lastName"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.mobileNumber && formik.errors.mobileNumber)}
                                        fullWidth
                                        helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                                        label="Contact Number"
                                        name="mobileNumber"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.mobileNumber}
                                    />
                                </Grid>
                            </Grid>
                            <Typography mt={3} mb={2}>
                                Account Settings
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sx={styles.checkboxWrapper}>
                                    <Grid item xs={4} sx={styles.checkbox}>
                                        <Checkbox
                                            color="secondary"
                                            checked={formik.values.enabled}
                                            name="enabled"
                                            disabled={false}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <Typography color="textPrimary" sx={styles.typography}>
                                            Account Enabled
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sx={styles.checkbox}>
                                        <Checkbox
                                            color="secondary"
                                            checked={formik.values.accountLocked}
                                            name="accountLocked"
                                            disabled={false}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <Typography color="textPrimary" sx={styles.typography}>
                                            Account Locked
                                        </Typography>
                                    </Grid>
                                    <Grid sx={styles.checkbox} item xs={4}>
                                        <Checkbox
                                            color="secondary"
                                            name="passwordExpired"
                                            checked={formik.values.passwordExpired}
                                            disabled={false}
                                            onChange={(event) => formik.handleChange(event)}
                                        />

                                        <Typography color="textPrimary" sx={styles.typography}>
                                            Password Expired
                                        </Typography>
                                    </Grid>
                                    <Grid sx={styles.checkbox} item xs={4}>
                                        <Checkbox
                                            color="secondary"
                                            name="sendCredentials"
                                            checked={formik.values.sendCredentials}
                                            disabled={false}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <Typography color="textPrimary" sx={styles.typography}>
                                            Send Credentials
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {formik.errors.submit && (
                                    <Grid item xs={12}>
                                        <FormHelperText error>{formik.errors.submit}</FormHelperText>
                                    </Grid>
                                )}
                                <Grid item xs={12} sx={styles.dividerWrapper}>
                                    <Divider item flexItem orientation="horizontal" sx={styles.divider} />{' '}
                                </Grid>
                                <UserRoles
                                    companies={companyList}
                                    roles={authorityList}
                                    selectedRoles={selectedRoles}
                                    setSelectedRoles={(values) => formik.setFieldValue('selectedRoles', values)}
                                    selectedCompany={selectedCompany}
                                    setSelectedCompany={setSelectedCompany}
                                />
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="end">
                                        <LoadingButton
                                            loading={loading}
                                            disabled={isDisabled || !formik.dirty}
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            {isEditMode ? 'Update' : 'Create'}
                                        </LoadingButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default UserDetailsTab;
