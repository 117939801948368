import axios from 'axios';
import toast from 'react-hot-toast';
import { CONFIG } from '../config';

const baseURL = `${CONFIG.BASE_URL}`;

const createAxiosInstance = (endpoint, responseType, headers) => {
    const instance = axios.create({
        baseURL: `${baseURL}/${endpoint}`,
        responseType: responseType,
        headers: {
            'Content-Type': 'application/json',
            appType: 'hq-ui',
            ...headers,
        },
    });

    instance.interceptors.request.use((req) => {
        const token = localStorage.getItem('token');
        if (token) req.headers.Authorization = `Bearer ${token}`;
        return req;
    });

    instance.interceptors.response.use(
        (res) => {
            if (typeof res.data === 'string') {
                try {
                    res.data = JSON.parse(res.data);
                } catch (e) {
                    console.error('Error parsing response data:', e);
                }
            }

            return res;
        },
        (err) => {
            if (typeof err?.response?.data === 'string') {
                try {
                    err.response.data = JSON.parse(err?.response?.data);
                } catch (e) {
                    console.error('Error parsing response data:', e);
                }
            }

            if (err.response && err.response.status === 403) {
                if (err.response.data.error === 'Forbidden') toast.error('Access Denied');
                else window.location.href = '/access-denied';
            } else return Promise.reject(err);
        },
    );

    return instance;
};

export const loginApi = createAxiosInstance('api', 'json');
export const api = createAxiosInstance('restApi', 'json');
export const formDataApi = createAxiosInstance('restApi', 'multipart/form-data', {
    'Content-Type': 'multipart/form-data',
});
export const downloadFileApi = createAxiosInstance('restApi', 'blob', { Accept: 'application/octet-stream' });
