import React from 'react';
import { Paper, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import { styles } from './styles';
import PositionStatusChip from '../../../features/positions/Positions/PositionStatusChip';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: '100%',
    color: theme.palette.text.primary,
}));

const statusKeyMapping = {
    'Intake Scheduled': {
        dateTitle: 'Scheduled Date',
        userTitle: 'Intake SPOC',
    },
    'Open Active Sourcing': {
        dateTitle: 'Position Start Date',
    },
    'On Hold - HM Delay': {
        dateTitle: 'On Hold - HM Delay Date',
    },
    'Offer Negotiation': {
        dateTitle: 'Offer Negotiation Date',
    },
    'Offer Released': {
        dateTitle: 'Offer Released Date',
    },
    'Offer Accepted': {
        dateTitle: 'Offer Accepted Date',
    },
    Filled: {
        dateTitle: 'Filled Date',
    },
    Cancelled: {
        dateTitle: 'Cancelled Date',
    },
};

const StatusLogTable = (props) => {
    let { status, updatedDate, updatedBy, date, user, comment } = props;

    return (
        <StyledPaper
            sx={{
                my: 3,
                mx: 'auto',
                p: 2,
                borderRadius: '8px',
                border: '1px solid var(--neutral-200, #E1E3EA)',
                background: 'var(--background-White, #FFF)',
            }}
        >
            <Grid>
                <Grid sx={{ display: 'flex', justifyContent: 'inherit' }}>
                    <Grid item xs={12 / 5} alignItems={'center'} display={'flex'}>
                        <PositionStatusChip label={status} />
                    </Grid>
                    <Grid item xs={12 / 5}>
                        <Typography sx={styles.auditLogDate} display={'flex'}>
                            <CalendarMonthIcon
                                sx={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '4px' }}
                            />
                            <Box> Updated Date:</Box>
                        </Typography>
                        <Typography sx={styles.auditLogRowTitle}>
                            {`${moment(updatedDate).format('MMM DD yyyy HH:mm:ss')}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12 / 5}>
                        <Typography sx={styles.auditLogDate} display={'flex'}>
                            <PersonIcon sx={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '4px' }} />{' '}
                            <Box> Updated By: </Box>
                        </Typography>
                        <Typography sx={styles.auditLogRowTitle}>{updatedBy}</Typography>
                    </Grid>
                    {statusKeyMapping[status]?.dateTitle && date && (
                        <Grid item xs={12 / 5}>
                            <Typography sx={styles.auditLogDate} display={'flex'}>
                                <CalendarMonthIcon
                                    sx={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '4px' }}
                                />
                                <Box>{statusKeyMapping[status]?.dateTitle}:</Box>
                            </Typography>
                            <Typography sx={styles.auditLogRowTitle}>
                                {`${moment(date).format('MMM DD yyyy')}`}
                            </Typography>
                        </Grid>
                    )}
                    {statusKeyMapping[status]?.userTitle && user && (
                        <Grid item xs={12 / 5}>
                            <Typography sx={styles.auditLogDate} display={'flex'}>
                                <PersonIcon
                                    sx={{ width: '16px', height: '16px', marginTop: '2px', marginRight: '4px' }}
                                />
                                <Box>{statusKeyMapping[status]?.userTitle}:</Box>
                            </Typography>
                            <Typography sx={styles.auditLogRowTitle}>{user}</Typography>
                        </Grid>
                    )}
                </Grid>
                {comment && (
                    <Grid
                        mt={2}
                        style={{
                            padding: '10px',
                            borderRadius: '8px',
                            border: '1px solid var(--neutral-200, #E1E3EA)',
                            background: 'rgba(243, 244, 247, 0.4)',
                        }}
                    >
                        <Typography sx={styles.auditLogDate} display={'flex'}>
                            <MessageIcon sx={{ width: '16px', height: '16px', marginTop: '4px', marginRight: '4px' }} />
                            <Box>Comment</Box>
                        </Typography>
                        <Typography variant="caption">
                            <Box mt={1}>{comment}</Box>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </StyledPaper>
    );
};

export default StatusLogTable;
