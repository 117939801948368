import React, { useEffect, useState } from 'react';
import {
    Grid,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Skeleton,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TabContext, TabPanel } from '@mui/lab';
import { useSnackbar } from 'notistack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import PositionDetailsTab from './PositionDetailsTab';
import DemandAttributes from '../../demandAttributes';
import { api, formDataApi } from '../../../axios';
import { useCustomAttributesFormik, usePersonalDetailsFormik } from './formik';

const views = [
    {
        label: 'Position Details',
        value: 'position-details',
    },
    {
        label: 'Custom Attributes',
        value: 'custom-attributes',
    },
];

const BulkModifyPositions = (props) => {
    const [view, setView] = useState('position-details');
    const [positionDetails, setPositionDetails] = useState({});
    const [preCreationDetails, setPreCreationDetails] = useState({
        isLoading: true,
    });
    const personalDetailsFormik = usePersonalDetailsFormik(
        preCreationDetails?.data?.position,
        (value) => handleBasicDetails(value),
        true,
    );
    const { customAttributesFormik, metaCustomFields } = useCustomAttributesFormik(
        null,
        (value) => handleCustomDetails(value),
        true,
    );

    const navigate = useNavigate();

    const { selectedPositions } = useSelector((state) => state.positions);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setPreCreationDetails({
            ...preCreationDetails,
            isLoading: true,
        });
        async function fetchPreCreationData() {
            const response = await api.post('/preCreatePosition');
            console.log('pre create api resp', response.data);
            setPreCreationDetails({
                ...preCreationDetails,
                data: response.data,
                isLoading: false,
            });
        }
        fetchPreCreationData();
    }, []);

    const handleBasicDetails = (details) => {
        setPositionDetails(details);
        setView('custom-attributes');
    };

    const handleCustomDetails = async (details) => {
        const { submit, ...filteredData } = {
            ...positionDetails,
            ...details,
        };
        const bodyFormData = new FormData();
        for (const key in filteredData) {
            bodyFormData.append(key, filteredData[key]);
        }

        enqueueSnackbar('No end points defined for bulk modify positions', {
            variant: 'info',
        });
        return;
        const response = await formDataApi.post('', bodyFormData);
        if (response.data) {
            navigate('/positions');
        } else {
        }
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        sx={{
                            py: '32px !important',
                        }}
                        item
                    >
                        <Typography variant="h4">Positions</Typography>
                    </Grid>

                    <Grid
                        sx={{
                            pt: '0px !important',
                        }}
                        item
                    >
                        <Card {...props}>
                            <CardContent>
                                {preCreationDetails.isLoading ? (
                                    <Box
                                        sx={{
                                            py: 4,
                                        }}
                                    >
                                        <Skeleton height={42} />
                                        <Skeleton height={42} />
                                        <Skeleton height={42} />
                                    </Box>
                                ) : (
                                    <>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Button
                                                    startIcon={<ChevronLeftIcon />}
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => navigate('/positions')}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Typography color="textPrimary" variant="h4">
                                                    Bulk Modify {selectedPositions.length} positions
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TabContext value={view}>
                                                    <Box>
                                                        <Tabs
                                                            value={view}
                                                            onChange={(event, value) => setView(value)}
                                                            aria-label="basic tabs example"
                                                        >
                                                            {views.map((option) => (
                                                                <Tab
                                                                    disabled={false}
                                                                    label={option.label}
                                                                    value={option.value}
                                                                />
                                                            ))}
                                                        </Tabs>
                                                        <Divider sx={styles.divider} />
                                                    </Box>
                                                    <TabPanel sx={styles.tabpanel} value={'position-details'}>
                                                        <PositionDetailsTab
                                                            formik={personalDetailsFormik}
                                                            preCreationDetails={preCreationDetails?.data}
                                                            isBulkModify
                                                        />
                                                    </TabPanel>
                                                    <TabPanel sx={styles.tabpanel} value={'custom-attributes'}>
                                                        <DemandAttributes
                                                            isModify
                                                            formik={customAttributesFormik}
                                                            metaCustomFields={metaCustomFields}
                                                        />
                                                    </TabPanel>
                                                </TabContext>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default BulkModifyPositions;
