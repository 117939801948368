import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Card, CardContent, Container, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { formDataApi } from '../../../axios';
import { useSnackbar } from 'notistack';
import DesignationDetailsTab from '../../../features/designations/DesignationDetailsTab';

const AddEditDesignation = (props) => {
    const params = useParams();
    const [designationDetails, setDesignationDetails] = useState({
        data: {},
        isLoading: false,
    });
    const location = useLocation();
    const isEditMode = location.pathname.includes('edit');
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async () => {
            setDesignationDetails({
                ...designationDetails,
                isLoading: true,
            });
            const bodyFormData = new FormData();
            isEditMode && bodyFormData.append('id', params.id);
            const url = isEditMode ? '/modifyDesignation' : '/newDesignation';
            try {
                const response = await formDataApi.post(url, bodyFormData);
                if (response?.data) {
                    setDesignationDetails({
                        data: response.data,
                        isLoading: false,
                    });
                }
            } catch (err) {
                enqueueSnackbar(err.message, {
                    variant: 'error',
                });
            }
        };

        fetchData();
    }, [params.id]);

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Typography variant="h4">{isEditMode ? 'Edit Designation' : 'Add Designation'}</Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0px !important',
                        }}
                    >
                        <Card {...props}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={() => navigate('/settings/designations')}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        pt: 1,
                                    }}
                                >
                                    <DesignationDetailsTab
                                        isEditMode={isEditMode}
                                        designationDetails={designationDetails}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AddEditDesignation;
