import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import 'simplebar-react/dist/simplebar.min.css';
import store from './store';
import { SettingsConsumer, SettingsProvider } from './contexts/SettingContexts';
import { createTheme } from './theme';
import Navigation from './routes/Navigation';
import ErrorBoundary from './ErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <HelmetProvider>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={2000}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Provider store={store}>
                        <SettingsProvider>
                            <SettingsConsumer>
                                {({ settings }) => (
                                    <ThemeProvider
                                        theme={createTheme({
                                            mode: 'light',
                                            direction: settings.direction,
                                        })}
                                    >
                                        <CssBaseline />
                                        <BrowserRouter>
                                            <Toaster />
                                            <Navigation />
                                        </BrowserRouter>
                                    </ThemeProvider>
                                )}
                            </SettingsConsumer>
                        </SettingsProvider>
                    </Provider>
                </SnackbarProvider>
            </HelmetProvider>
        </ErrorBoundary>
    </React.StrictMode>,
);
