import React, { useCallback } from 'react';
import ViewOverview from './view';
import EditOverview from './edit';

const Overview = ({ user, fullName, isEditMode, fetchUserData }) => {
    const getEmail = useCallback(() => {
        if (user?.permanentHomeAddressInstance?.emailAddresses.length === 1) {
            return user?.permanentHomeAddressInstance?.emailAddresses[0];
        }
        return user?.permanentHomeAddressInstance?.emailAddresses.join(', ');
    }, [user]);

    return isEditMode ? (
        <EditOverview fetchUserData={fetchUserData} user={user} email={getEmail()} fullName={fullName} />
    ) : (
        <ViewOverview user={user} email={getEmail()} fullName={fullName} />
    );
};

export default Overview;
