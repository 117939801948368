import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
};

const app = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
});

export const { setLoading } = app.actions;

export default app.reducer;
