import React, { useState } from 'react';
import {
    Grid,
    TextField,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import { styles } from '../Add/styles';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';

const MetaFields = ({ data = [], handleDelete, handleChange }) => {
    const [openDialog, setOpenDialog] = useState(null);

    return (
        <Box>
            <Dialog open={openDialog === null ? false : true} onClose={() => setOpenDialog(null)}>
                <DialogContent>
                    <Box>
                        <DialogTitle sx={styles.dialogTitle}>Delete Custom Attributes</DialogTitle>
                        <DialogContentText>Are you sure you want to delete custom Attributes?</DialogContentText>
                    </Box>
                    <DialogActions sx={styles.buttonsWrapper}>
                        <Button size="large" onClick={() => setOpenDialog(null)}>
                            Cancel
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() => {
                                handleDelete(openDialog);
                                setOpenDialog(null);
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {data.map((item, index) => {
                const { fieldLabel, fieldDataType, category, isRequired, fieldListOptions, id, isModify, original } =
                    item;
                return (
                    <Box key={id}>
                        <Grid
                            item
                            container
                            spacing={4}
                            xs={12}
                            key={index}
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={2}>
                                    <TextField
                                        name="fieldLabel"
                                        label="Title"
                                        value={fieldLabel}
                                        onChange={(event) => handleChange(index, event)}
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl
                                        sx={{
                                            mx: 1,
                                            minWidth: 120,
                                        }}
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel id="data-type-label">Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="fieldDataType"
                                            value={fieldDataType}
                                            label="Type"
                                            onChange={(event) => handleChange(index, event)}
                                        >
                                            <MenuItem value="String">Text</MenuItem>
                                            <MenuItem value="Integer">Number</MenuItem>
                                            <MenuItem value="Date">Date</MenuItem>
                                            <MenuItem value="Single Selection List">Single Selection List</MenuItem>
                                            <MenuItem value="Multiple Selection List">Multiple Selection List</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl
                                        sx={{
                                            mx: 1,
                                            minWidth: 120,
                                        }}
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel id="data-type-label">Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="category"
                                            value={category?.name || category}
                                            label="Category"
                                            onChange={(event) => handleChange(index, event)}
                                        >
                                            <MenuItem value="Minor">
                                                <img
                                                    src={minorCategoryIcon}
                                                    alt="Minor"
                                                    style={{
                                                        paddingRight: '6px',
                                                    }}
                                                />
                                                Minor
                                            </MenuItem>
                                            <MenuItem value="Major">
                                                <img
                                                    src={majorCategoryIcon}
                                                    alt="Major"
                                                    style={{
                                                        paddingRight: '6px',
                                                    }}
                                                />
                                                Major
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl
                                        sx={{
                                            mx: 1,
                                            minWidth: 120,
                                        }}
                                        size="small"
                                        fullWidth
                                    >
                                        <InputLabel id="data-type-label">Is Required?</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="isRequired"
                                            value={isRequired}
                                            label="Is Required?"
                                            onChange={(event) => handleChange(index, event)}
                                        >
                                            <MenuItem value={true}>Mandatory</MenuItem>
                                            <MenuItem value={false}>Optional</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {(fieldDataType === 'Single Selection List' ||
                                    fieldDataType === 'Multiple Selection List') && (
                                    <Grid item xs={2}>
                                        <TextField
                                            sx={{
                                                mx: 1,
                                                minWidth: 120,
                                            }}
                                            name="fieldListOptions"
                                            label="values"
                                            value={fieldListOptions}
                                            onChange={(event) => handleChange(index, event)}
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                {((!isModify && !isRequired) || (isModify && !original?.isRequired)) && (
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{
                                            mx: 1,
                                            minWidth: 120,
                                        }}
                                    >
                                        <IconButton onClick={() => setOpenDialog(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Box sx={styles.separatorLine} my="30px" />
                    </Box>
                );
            })}
        </Box>
    );
};

export default MetaFields;
