export const utilStyles = {
    mt: (n) => ({
        mt: n,
    }),
    mr: (n) => ({
        mr: n,
    }),
    p: (n) => ({
        p: n,
    }),
    ml: (n) => ({
        ml: n,
    }),
    mb: (n) => ({
        mb: n,
    }),
    m: (n) => ({
        m: n,
    }),
    mx: (n) => ({
        mx: n,
    }),
    pr: (n) => ({
        pr: n,
    }),
    pb: (n) => ({
        pb: n,
    }),
    pl: (n) => ({
        pl: n,
    }),
    pt: (n) => ({
        pt: n,
    }),
    px: (n) => ({
        px: n,
    }),
};

export const getRandomColor = () => '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
