import { styled } from '@mui/material/styles';

export const PageLayoutRoot = styled('div')(({ theme }) => ({
    background: '#EDF3FA',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
}));

PageLayoutRoot.sx = (settings) => ({
    pl: settings.pinSidebar ? '230px' : '0px',
});

export const BoxSx = {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    padding: '20px',
};
