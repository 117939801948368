import React, { useCallback } from 'react';
import { IconButton, Card, CardContent, Grid, Typography } from '@mui/material';
import numeral from 'numeral';

const StatusCard = (props) => {
    const { label, filteredStatus, value, handleStatusFilter, Icon } = props;

    const AvatarLogo = useCallback(() => {
        return (
            <IconButton
                style={{
                    backgroundColor: filteredStatus === label ? '#ED7342' : '#215263',
                    borderRadius: '50%',
                    cursor: 'default',
                }}
                disableRipple
            >
                <Icon sx={{ color: '#FFFFFF' }} size="small" />
            </IconButton>
        );
    }, [filteredStatus, label]);

    return (
        <>
            <Card
                sx={{
                    height: '100%',
                    borderRadius: '5px',
                    border: filteredStatus === label ? `2px solid #ED7342` : 'none',
                    cursor: handleStatusFilter ? 'pointer' : 'default',
                    padding: '10px',
                }}
                onClick={() => handleStatusFilter && handleStatusFilter(filteredStatus === label ? null : label)}
            >
                <CardContent
                    sx={{
                        height: '100%',
                        padding: '10px 0px !important',
                    }}
                >
                    <Grid
                        sx={{
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Grid item align="center" mb="5px">
                            <AvatarLogo />
                        </Grid>
                        <Grid
                            item
                            align="center"
                            sx={{
                                lineHeight: '1',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '0.65rem',
                                    textTransform: 'uppercase',
                                    lineHeight: '1',
                                }}
                                color="textSecondary"
                                variant="overline"
                            >
                                {label}
                            </Typography>
                        </Grid>
                        <Grid pt={1}>
                            <Typography align="center" color="textPrimary" variant="h6">
                                {numeral(value).format('0,0')}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default StatusCard;
