export const styles = {
    tableContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    table: {
        minWidth: 800,
    },
    titleWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    link: {
        display: 'block',
    },
    resourceUnavailable: {
        flexGrow: 1,
        m: 2,
    },
    colPosition: {
        position: 'fixed',
    },
    searchInput: {
        order: {
            sm: 2,
            xs: 1,
        },
    },
    filterSpace: {
        flexGrow: 1,
        order: 2,
    },
    selectBox: {
        m: 1,
        width: '425px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selectInput: {
        width: '100px',
        height: '40px',
        borderColor: '#E1E3EA',
        color: '#7C85A2',
    },
    actionMenu: (selectedPositions) => ({
        mr: 2,
        display: selectedPositions.length > 0 ? 'flex' : 'none',
        order: {
            sm: 1,
            xs: 2,
        },
    }),
    searchFilter: {
        p: 1,
        width: '191px',
    },
    filterButtons: {
        alignItems: 'center',
        pr: 2,
    },
    seveButton: {
        background: 'primary',
        color: '#fff',
    },
    filterMenu: {
        m: 1,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        border: 'none',
        boxShadow: 'none',
    },
    title: {
        px: {
            sm: 3,
        },
        pt: 4,
    },
    filterButton: {
        order: 4,
    },
    tableMenuDialog: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    viewMappedButton: {
        ml: '20px',
    },
    positionHeader: {
        py: 4,
    },
    dialogTitle: {
        textAlign: 'center',
    },
    buttonsWrapper: {
        justifyContent: 'center',
    },
    growBox: {
        flexGrow: 1,
    },
    dataWrappper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    flexCenterNone: {
        alignItems: 'center',
        display: 'flex',
    },
    mx: (n) => ({
        mx: n,
    }),
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    searchFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        py: 1.5,
    },
    actionMenu: (selectedPositions) => ({
        mr: 2,
        display: selectedPositions.length > 0 ? 'flex' : 'none',
        order: {
            sm: 1,
            xs: 2,
        },
    }),
    searchInput: {
        order: {
            sm: 2,
            xs: 1,
        },
        width: '40%',
    },
    exportButton: {
        order: 3,
        color: (theme) => theme.palette.text.secondary,
    },
    filterButton: {
        order: 4,
    },
};
