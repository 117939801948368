import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import StatusBadge from './StatusBadge';

const StatusLabel = (props) => {
    const { color, label, icon, ...other } = props;

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
            }}
            {...other}
        >
            <StatusBadge color={color} />
            <Typography
                sx={{
                    color,
                    ml: 1.75,
                }}
                variant="body2"
            >
                {label}
            </Typography>
            {icon}
        </Box>
    );
};
export default StatusLabel;

StatusLabel.propTypes = {
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
