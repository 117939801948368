/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import withAuthPermission from '../helpers/withAuthPermission';

const AuthorizedRoute = (props) => {
    return <Route {...props} />;
};

export default withAuthPermission(AuthorizedRoute);
