export const styles = {
    flexItem3: (isBulkEdit) => ({
        flexBasis: isBulkEdit ? '50%' : '33.33%',
        alignSelf: 'center',
        height: '56px',
        padding: '12px 24px 12px 16px',
        '& MuiButtonBase-root, fieldset': {
            border: 'none',
        },
    }),
    infoLine: {
        width: '100%',
        height: '1px',
        background: '#C1C4CC',
        my: '12px',
    },
    dialog: {
        '& .MuiPaper-root': {
            padding: '0 50px !important',
        },
        '& .MuiDialogTitle-root': {
            textAlign: 'center',
            paddingBottom: '0px !important',
        },
        '& .MuiDialogContent-root': {
            paddingTop: '8px !important',
        },
        '& .MuiDialogActions-root': {
            paddingTop: '12px !important',
        },
    },
};
