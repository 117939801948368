import React, { useState, useMemo } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { formDataApi } from '../../../axios';
import { hasPermission } from '../../../utils/permission';

export const UserMenu = ({ Component, row, keyColumn, handleAction }) => {
    const [actionType, setActionType] = useState();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const uId = row[keyColumn];
    const currentUser = useSelector((state) => state.session.user);
    const isLoggedInUser = currentUser?.userDBId == row['userID'];

    const handleDelete = async () => {
        setActionType();
        const bodyFormData = new FormData();
        if (params.companyId) bodyFormData.append('company', params.companyId);
        bodyFormData.append('id', uId);
        try {
            const response = await formDataApi.post('/deleteUser', bodyFormData);
            if (response.data) {
                enqueueSnackbar('User deleted successfuly', {
                    variant: 'success',
                });
                handleAction();
            }
        } catch (err) {
            enqueueSnackbar('Something went wrong! User could not be deleted', {
                variant: 'error',
            });
        }
    };

    const handleSendCredentials = async () => {
        setActionType();
        try {
            const bodyFormData = new FormData();
            if (params.companyId) bodyFormData.append('company', params.companyId);
            bodyFormData.append('id', row.id);
            const response = await formDataApi.post('/sendESSInviteToEmp', bodyFormData);
            if (response?.data?.status !== 'ERROR') {
                enqueueSnackbar(response.data.message, {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(response.data.message, {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar(error?.message, {
                variant: 'error',
            });
        }
    };

    const dialogContent = useMemo(() => {
        if (actionType === 'delete')
            return {
                title: 'Delete user',
                confirmation: `Are you sure you want to delete user?`,
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    </React.Fragment>
                ),
            };
        else if (actionType === 'send_credentials')
            return {
                title: 'Send Credentials',
                subTitle: "Please confirm if you'd like to send new credentials to the user.",
                confirmation:
                    'This will reset their current password, and they will only be able to log in with the new password provided.',
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={handleSendCredentials}>
                            Confirm
                        </Button>
                    </React.Fragment>
                ),
            };
    }, [actionType]);

    const isAdmin = useMemo(() => {
        const isAdmin = hasPermission(currentUser?.authorities, ['ROLE_ADMINISTRATOR']);
        const isSuperAdmin = hasPermission(currentUser?.authorities, ['ROLE_SUPER_ADMINISTRATOR']);
        return isAdmin || isSuperAdmin;
    }, [currentUser?.authorities]);

    const actionItems = [
        !isLoggedInUser &&
            (row.roleList.indexOf('ADMINISTRATOR') >= 0 ? isAdmin : true) && {
                label: 'Edit',
                type: 'edit',
                icon: <EditIcon />,
                onClick: () =>
                    navigate(
                        params.companyId ? `/company/${params.companyId}/users/edit/${uId}` : `/users/edit/${uId}`,
                    ),
                permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER'],
            },
        (row.roleList.indexOf('ADMINISTRATOR') >= 0 ? isAdmin : true) && {
            label: 'Delete',
            type: 'delete',
            icon: <TrashIcon />,
            onClick: (action) => setActionType(action.type),
            permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_SECUSER'],
        },
        !isLoggedInUser && {
            label: 'Send Credentials',
            type: 'send_credentials',
            icon: <MailOutlineIcon />,
            onClick: (action) => setActionType(action.type),
            permissions: ['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEND_ESS_INVITE_TO_EMPLOYEE'],
        },
    ];

    return <Component actions={actionItems} dialog={dialogContent} />;
};
