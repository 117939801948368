export const crPoliciesInitialState = {
    crPolicies: [],
    changeRequestPolicyCount: 0,
    positionsMapped: {
        hasErrored: false,
        isLoading: false,
        mapped: [],
        count: 0,
    },
    modify: [],
    isLoading: false,
    availableStatuses: [],
    majorApprovers: [],
    minorApprovers: [],
    createCRPolicy: {},
    deleteCRPolicy: {},
    viewPolicy: {
        isLoading: false,
        hasErrored: false,
        positionChangeRequestPolicy: {},
        commentsMap: [],
    },
    modifyPolicy: {
        isLoading: false,
        positionChangeRequestPolicy: {},
        approvers: [],
    },
    listPositions: [],
};
export const changeRequestInitialState = {
    selectedCRs: [],
    changeRequests: {
        count: 0,
        data: [],
        states: {},
    },
    approverChangeRequests: {
        count: 0,
        data: [],
        states: {},
        isLoading: false,
    },
    isLoading: false,
};

export const positionInitialState = {
    selectedPositions: [],
    positions: {
        count: 0,
        data: [],
        states: {},
    },
    bulkUpdatePositions: [],
};

export const sessionInitialState = {
    user: {
        userDBId: null,
        isIntegratedWithCanvas: true,
    },
};
