import React from 'react';
import { Box, Button, Container, Typography, Chip, Card, CardContent, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import { displayChangeRequestPolicy } from '../../../store/slices/listCRPolicies';
import AuditLog from '../../../components/common/AuditLog';
import { CRPoliciesMenu } from '../../../features/changeRequestPolicies/CRPolicies/CRPoliciesMenu';
import ActionButton from '../../../components/common/ActionButtonAndMenu/ActionButton';
import AuditLogTable from '../../../components/common/AuditLog/AuditLogTable';

export const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

const ViewCRPolicies = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const viewPolicy = useSelector((state) => state.crPolicies.viewPolicy);
    const { positionChangeRequestPolicy } = viewPolicy;

    useEffect(() => {
        dispatch(displayChangeRequestPolicy({ id: params.id }));
    }, []);

    const dataItem = [
        {
            label: 'Name',
            value: positionChangeRequestPolicy.name,
        },
        {
            label: 'Status',
            value: (
                <Chip
                    label={positionChangeRequestPolicy.status}
                    sx={{
                        backgroundColor: positionChangeRequestPolicy.status === 'Active' ? '#D6F5C7' : '#FFE1D3',
                    }}
                />
            ),
        },
        {
            label: 'Created On',
            value: moment(positionChangeRequestPolicy.createdOn).format('MMM DD yyyy h:mm:ss'),
        },
        {
            label: 'Created By',
            value: positionChangeRequestPolicy.createdBy?.username,
        },
    ];

    const approvers = positionChangeRequestPolicy.approverLeanList;

    return (
        <>
            <Box sx={styles.growBox}>
                <Container>
                    <Box
                        id="modifyChangeRequestHeader"
                        sx={{
                            pt: '12px !important',
                            pb: '32px',
                        }}
                    >
                        <Box>
                            <Typography variant="h4">{'Change Policy'}</Typography>
                        </Box>
                    </Box>
                    <Card
                        sx={{
                            ...styles.dataWrappper,
                            pt: 0,
                            mt: 0,
                        }}
                    >
                        <CardContent>
                            <>
                                <Grid container direction="column" spacing={2}>
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={() => navigate('/changerequest/crpolicies')}
                                        >
                                            {'Back'}
                                        </Button>
                                        <Box sx={styles.growBox} />
                                        <CRPoliciesMenu
                                            Component={ActionButton}
                                            row={{
                                                id: params.id,
                                                ...viewPolicy,
                                            }}
                                            handleAction={() => navigate('/changerequest/crpolicies')}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    <Grid item xs={12}>
                                        <Box sx={styles.addTable}>
                                            <Box sx={styles.dataBox}>
                                                {dataItem.map((item, index) => (
                                                    <Box key={index} sx={styles.dataItem}>
                                                        <Typography component={'span'} sx={styles.dataItemLable}>
                                                            {item.label}
                                                        </Typography>
                                                        <Typography component={'span'} sx={styles.dataItemValue}>
                                                            {item.value}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                            <Box sx={styles.coloreLine}></Box>
                                            <Typography variant="h6" sx={styles.basicInformationText}>
                                                Minor change request approvers
                                            </Typography>
                                            <Box sx={styles.dataBox}>
                                                {approvers
                                                    ?.filter((item) => item.type === 'Minor')
                                                    ?.map((item, index) => (
                                                        <Box key={index} sx={styles.dataItem}>
                                                            <Typography component={'span'} sx={styles.dataItemLable}>
                                                                {`Approver ${index + 1}`}
                                                            </Typography>
                                                            <Typography component={'span'} sx={styles.dataItemValue}>
                                                                {item.name}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                            </Box>
                                            <Box sx={styles.coloreLine}></Box>
                                            <Typography variant="h6" sx={styles.basicInformationText}>
                                                Major change request approvers
                                            </Typography>
                                            <Box sx={styles.dataBox}>
                                                {approvers
                                                    ?.filter((item) => item.type === 'Major')
                                                    ?.map((item, index) => (
                                                        <Box key={index} sx={styles.dataItem}>
                                                            <Typography component={'span'} sx={styles.dataItemLable}>
                                                                {`Approver ${index + 1}`}
                                                            </Typography>
                                                            <Typography component={'span'} sx={styles.dataItemValue}>
                                                                {item.name}
                                                            </Typography>
                                                        </Box>
                                                    ))}
                                            </Box>
                                            <Box sx={styles.coloreLine}></Box>
                                            <AuditLog
                                                data={[
                                                    {
                                                        title: 'Audit Logs',
                                                        logs: viewPolicy.commentsMap || [],
                                                        Component: AuditLogTable,
                                                    },
                                                ]}
                                            />
                                        </Box>
                                    </Grid>
                                }
                            </>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default ViewCRPolicies;
