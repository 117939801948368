import React from 'react';
import { Chip, Typography } from '@mui/material';
import ActionTableButton from '../../components/common/ActionButtonAndMenu/ActionTableButton';
import { CompanyMenu } from './CompanyMenu';

export const columns = [
    {
        fieldName: 'companyName',
        name: 'companyName',
        label: 'Company Name',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'positionCount',
        name: 'positionCount',
        label: 'No. of Positions',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'customerStatus',
        name: 'status',
        label: 'Status',
        type: 'list',
        listOptions: [
            { name: 'active', label: 'Active', value: 'Active' },
            { name: 'inactive', label: 'Inactive', value: 'Inactive' },
        ],
        formatValue: ({ row, column }) => {
            console.log(row);
            return (
                <>
                    <Chip
                        label={row.customer[column.fieldName]}
                        sx={{
                            backgroundColor:
                                row.customer[column.fieldName]?.toLowerCase() === 'active' ? '#BCE2E2' : '#FFE1D3',
                        }}
                        props
                    />
                </>
            );
        },
    },
    {
        fieldName: 'isIntegratedWithCanvas',
        name: 'isIntegratedWithCanvas',
        label: 'Canvas Integration',
        type: 'list',
        listOptions: [
            { name: 'active', label: 'Active', value: true },
            { name: 'inactive', label: 'Inactive', value: false },
        ],
        formatValue: ({ row, column }) => (
            <>
                <Chip
                    label={row.config[column.fieldName] ? 'Active' : 'Inactive'}
                    sx={{
                        backgroundColor: row.config[column.fieldName] ? '#BCE2E2' : '#FFE1D3',
                    }}
                    props
                />
            </>
        ),
    },
    {
        fieldName: 'isClientDisengaged',
        name: 'isClientDisengaged',
        label: 'Client Disengagement',
        type: 'list',
        listOptions: [
            { name: 'active', label: 'Active', value: true },
            { name: 'inactive', label: 'Inactive', value: false },
        ],
        formatValue: ({ row, column }) => (
            <>
                <Chip
                    label={row[column.fieldName] ? 'Active' : 'Inactive'}
                    sx={{
                        backgroundColor: row[column.fieldName] ? '#BCE2E2' : '#FFE1D3',
                    }}
                    props
                />
            </>
        ),
    },
    {
        fieldName: 'createdOn',
        name: 'createdOn',
        label: 'Created Date',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.createdOn}
            </Typography>
        ),
    },
    {
        id: 'action',
        label: 'Actions',
        filter: true,
        sticky: true,
        formatValue: ({ row, action }) => <CompanyMenu Component={ActionTableButton} row={row} handleAction={action} />,
    },
];
