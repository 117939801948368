import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CompanyData from '../CompanyData';
import { styles } from './styles';
import { formDataApi } from '../../../axios';
import { CompanyMenu } from '../CompanyMenu';
import ActionButton from '../../../components/common/ActionButtonAndMenu/ActionButton';

const View = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [companyDetails, setCompanyDetails] = useState({
        isLoading: true,
    });

    useEffect(() => {
        setCompanyDetails({ ...companyDetails, isLoading: true });
        async function fetchData() {
            const bodyFormData = new FormData();
            bodyFormData.append('id', params.id);
            try {
                const response = await formDataApi.post('/displayCompany', bodyFormData);
                if (response?.data) {
                    setCompanyDetails(response?.data);
                }
            } catch (err) {
                setCompanyDetails({ isLoading: false });
                toast.error('Something went wrong! Customer could not be fetched.');
            }
        }
        fetchData();
    }, [params.id]);

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="space-between">
                        <Typography variant="h4">Customer</Typography>
                        <CompanyMenu
                            Component={ActionButton}
                            row={{
                                id: params.id,
                                ...companyDetails,
                            }}
                            handleAction={() => navigate('/company')}
                        />
                    </Grid>
                    <Grid item>
                        <Card {...props}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={() => navigate('/company')}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <CompanyData position={companyDetails} />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default View;
