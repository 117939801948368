import React, { useEffect } from 'react';
import { Result } from 'antd';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '../../contexts/SettingContexts';

const AccessDenied = () => {
    const navigate = useNavigate();
    const { settings, saveSettings } = useSettings();

    useEffect(() => {
        saveSettings({ ...settings, pinSidebar: false });
        return () => saveSettings({ ...settings, pinSidebar: true });
    }, []);

    const handleBack = () => {
        saveSettings({ ...settings, pinSidebar: true });
        navigate(-2);
    };

    return (
        <Result
            status="403"
            title="403"
            subTitle="Access Denied"
            extra={
                <Button type="primary" variant="contained" onClick={handleBack}>
                    Go Back
                </Button>
            }
        />
    );
};

export default AccessDenied;
