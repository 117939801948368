import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';
const uid = uuid();

export const useStyles = makeStyles(
    () => ({
        appBar: {
            backgroundColor: 'primary.700 !important',
            color: '#FFFFFF !important',
        },
        toolBar: {
            alignItems: 'center !important',
            display: 'flex !important',
            minHeight: '64px !important',
            padding: '8px 24px !important',
        },
        toolBarBox: {
            alignItems: 'center !important',
            display: 'flex !important',
            justifyContent: 'center !important',
        },
        divider: {
            borderColor: 'rgba(255,255,255,0.1) !important',
            margin: '0 24px !important',
        },
        dividerBox: {
            flexGrow: 1,
        },
    }),
    {
        classNamePrefix: 'navbar',
        generateId: uid,
        index: uid,
        name: 'navbar',
    },
);

export const styles = {
    centerFlex: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    divider: {
        borderColor: '#CDD2DC',
        mx: 3,
    },
    toolBar: {
        alignItems: 'center',
        display: 'flex',
        minHeight: 64,
        px: 3,
        py: 1,
        boxShadow: '0px 1px 2px rgba(9, 30, 66, 0.12)',
    },
    appBar: {
        backgroundColor: 'background.default',
    },
    searchBar: {
        width: '20%',
        border: 'none',
    },
    dropdownIcon: (openMenu) => ({
        ml: 2,
        transition: 'transform 250ms',
        transform: openMenu ? 'rotate(180deg)' : 'none',
    }),
    dropdownButton: {
        display: {
            color: 'primary.main',
            md: 'none',
            xs: 'flex',
        },
    },
    flexGrow: {
        flexGrow: 1,
    },
    tableMenuDialog: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
};
