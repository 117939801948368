import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DoneIcon from '@mui/icons-material/Done';
import { Card, CardContent, FormHelperText, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import keyBy from 'lodash/keyBy';
import { LoadingButton } from '@mui/lab';
import { utilStyles } from '../../../../utils/styles';
import TextField from '../../../../components/common/InputField/TextField';
import { formDataApi } from '../../../../axios';
import { logout } from '../../../../store/slices/session';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ChangePassword = ({ user, fetchUserData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { authorityList = [], roleIDBasedMap = {} } = user || {};
    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        const selectedRoleValues = Object.keys(roleIDBasedMap).filter((item) => roleIDBasedMap[item] === true);
        setSelectedRoles(selectedRoleValues.map(Number));
    }, [user]);

    const formik = useFormik({
        initialValues: {
            confirmPassword: '',
            newPassword: '',
        },
        validationSchema: Yup.object().shape({
            confirmPassword: Yup.string()
                .transform((value) => value?.trim())
                .when('newPassword', {
                    // this should match with input field name
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string()
                        .oneOf([Yup.ref('newPassword')], 'Password does not match')
                        .required('No password provided.'),
                }),
            newPassword: Yup.string()
                .transform((value) => value?.trim())
                .min(8, 'New Password must be at least 8 characters long.')
                .matches(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/, {
                    message:
                        'New Password must contain at least one digit, one uppercase letter, and one lowercase letter.',
                })
                .required('No password provided.'),
        }),
        onSubmit: async (values, helpers) => {
            helpers.setSubmitting(true);
            try {
                const bodyFormData = new FormData();
                const authorityListObject = keyBy(authorityList, 'id');

                const data = {
                    id: Number(user.secUserInstance.id),
                    newPasswd: values.confirmPassword?.trim(),
                    newPasswd2: values.confirmPassword?.trim(),
                };

                Object.keys(data).forEach((key) => {
                    if (data[key] !== '' || data[key]) {
                        bodyFormData.append(key, data[key]);
                    }
                });

                selectedRoles.forEach((selectedRole) => {
                    bodyFormData.append(authorityListObject[selectedRole]['authority'], 'on');
                });
                const response = await formDataApi.post('changeMyPassword', bodyFormData);
                if (response?.data?.message) {
                    enqueueSnackbar(response?.data?.message, {
                        variant: 'success',
                    });
                    dispatch(logout());
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } catch (err) {
                enqueueSnackbar('Something went wrong! Passwords could not be updated', { variant: 'error' });
            } finally {
                helpers.setSubmitting(false);
            }
        },
    });

    return (
        <Card sx={utilStyles.mt(3)}>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item md={5} xs={12}>
                        <Typography color="textPrimary" variant="h6">
                            Change password
                        </Typography>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                                            fullWidth
                                            helperText={formik.touched.newPassword && formik.errors.newPassword}
                                            label="New password"
                                            name="newPassword"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.newPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={Boolean(
                                                formik.touched.confirmPassword && formik.errors.confirmPassword,
                                            )}
                                            fullWidth
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                            label="Confirm new password"
                                            name="confirmPassword"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmPassword}
                                            type="password"
                                        />
                                    </Grid>
                                    {formik.errors.submit && (
                                        <Grid item xs={12}>
                                            <FormHelperText error>{formik.errors.submit}</FormHelperText>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            loading={formik.isSubmitting}
                                            variant="contained"
                                            startIcon={<DoneIcon />}
                                        >
                                            Save Password
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ChangePassword;
