import React from 'react';
import { Route, Routes } from 'react-router-dom';
import App from '../App';
import Login from '../features/account/Login';
import Dashboard from '../features/dashboard';
import Positions from '../features/positions/Positions';
import ChangeRequests from '../features/changeRequests';
import Users from '../features/users';
import Locations from '../features/locations';
import AddCustomAttributes from '../features/demandAttributes/Add';
import AccessDenied from '../pages/Error/AccessDenied';
import AuthorizedRouteWithLayout from './AuthorizedRouteWithLayout';
import CRPolicies from '../features/changeRequestPolicies';
import AddPosition from '../features/positions/AddAndModifyPosition/AddPosition';
import ViewPosition from '../features/positions/ViewPosition';
import AddEditUser from '../features/users/AddEditUser';
import AddEditLocation from '../features/locations/AddEditLocation';
import ViewUser from '../features/users/ViewUsers';
import ModifyPosition from '../features/positions/AddAndModifyPosition/ModifyPosition';
import Account from '../features/account/Account';
import Company from '../features/company';
import ViewCompany from '../features/company/View';
import AddCompany from '../features/company/Add';
import ModifyCRPolicies from '../features/changeRequestPolicies/ModifyCRPolicies';
import ModifyChangeRequest from '../features/changeRequests/ModifyChangeRequest';
import ForgotPassword from '../features/account/ForgotPassword';
import ResetPassword from '../features/account/ResetPassword';
import PositionsUploadPreview from '../features/positions/PositionsUploadPreview';
import BulkModifyPositions from '../features/positions/AddAndModifyPosition/BulkModifyPositions';
import ViewChangeRequests from '../features/changeRequests/ViewChangeRequest';
import ViewCRpolicies from '../features/changeRequestPolicies/ViewCRPolicies';
import ViewUpdateMappedPositions from '../features/changeRequestPolicies/ViewUpdateMappedPositions';
import ViewLocation from '../features/locations/ViewLocations';
import ViewDesignations from '../features/designations/ViewDesignations';
import AddEditDesignation from '../features/designations/AddEditDesignation';
import Designations from '../features/designations';
import Departments from '../features/departments';
import AddEditDepartment from '../features/departments/AddEditDepartment';
import ViewDepartment from '../features/departments/ViewDepartment';
import Roles from '../features/roles/Roles';
import AddEditRoles from '../features/roles/Roles/AddEditRoles';
import ChangeRequestPolicyRoles from '../features/changeRequestPolicyRoles';
import ViewRoles from '../features/roles/Roles/ViewRoles';
import UpdateIntegration from '../features/settings/UpdateIntegration';
import CompanyConfig from '../features/company/CompanyConfig';
import { RequestMaps } from '../features/settings/RequestMaps';
import { SystemPermission } from '../features/settings/SystemPermissions';
import { UpdatePositionExternalIDs } from '../features/positions/UpdatePositionExternalIDs';
import { UploadPositionData } from '../features/positions/UploadPositionData';
import PageNotFound from '../pages/Error/PageNotFound';
import RecruitmentPolicyAttributes from '../features/recruitmentPolicy/RecruitmentPolicyAttributes';
import RecruitmentPolicy from '../features/recruitmentPolicy/RecruitmentPolicy';
import { ViewRecruitmentPolicy } from '../features/recruitmentPolicy/RecruitmentPolicy/ViewRecruitmentPolicy';
import CRPoliciesAdd from '../features/changeRequestPolicies/CRPoliciesAdd';

const Navigation = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<App />}>
                <Route
                    path="dashboard"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_BASE_PERMISSION_SENTINEL_EMP']}>
                            <Dashboard />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="dashboard/account/update/:id"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={[]}>
                            <Account />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="dashboard/account"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={[]}>
                            <Account />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="changerequest/crpolicies"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_CHANGE_REQUEST_POLICIES']}
                        >
                            <CRPolicies />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="changerequests"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_CHANGE_REQUESTS']}
                        >
                            <ChangeRequests />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/roles"
                    element={
                        <AuthorizedRouteWithLayout>
                            <Roles />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    exact
                    path="positions"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_POSITIONS']}
                        >
                            <Positions />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    exact
                    path="positions/add"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_POSITION']}
                        >
                            <AddPosition />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="positions/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_VIEW_POSITION']}
                        >
                            <ViewPosition />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="positions/modify/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION']}
                        >
                            <ModifyPosition />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    exact
                    path="recruitmentPolicies"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_FETCH_RECRUITMENT_POLICY']}
                        >
                            <RecruitmentPolicy />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    exact
                    path="recruitmentPolicies/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RECRUITMENT_POLICY_DETAILS']}
                        >
                            <ViewRecruitmentPolicy />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="/changerequest/crpolicies/modify/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST_POLICY']}
                        >
                            <ModifyCRPolicies />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="/changerequest/crpolicies/view/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_VIEW_CHANGE_REQUEST_POLICY']}
                        >
                            <ViewCRpolicies />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="/changerequest/crpolicies/add"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST_POLICY']}
                        >
                            <CRPoliciesAdd />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="changeRequests/:id/modify/:positionId"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST']}
                        >
                            <ModifyChangeRequest />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="changeRequests/create/:positionId"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST']}
                        >
                            <ModifyChangeRequest />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="changeRequests/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_VIEW_CHANGE_REQUEST']}
                        >
                            <ViewChangeRequests />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="/changeRequests/bulk/modify"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST']}
                        >
                            <ModifyChangeRequest />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="/changeRequests/bulk/create"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST']}
                        >
                            <ModifyChangeRequest />
                        </AuthorizedRouteWithLayout>
                    }
                />

                <Route
                    exact
                    path="company"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_SYSTEM_INDEX_LIST_COMPANIES']}
                        >
                            <Company />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_SYSTEM_INDEX_VIEW_COMPANY']}
                        >
                            <ViewCompany />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/add"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_SYSTEM_INDEX_CREATE_COMPANY']}
                        >
                            <AddCompany />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_SYSTEM_INDEX_CREATE_COMPANY']}
                        >
                            <AddCompany />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="positions/preview"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_LIST_POSITIONS']}
                        >
                            <PositionsUploadPreview />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="positions/bulk-modify"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS']}
                        >
                            <BulkModifyPositions />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/company-config"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_ADMINISTRATOR']}>
                            <CompanyConfig />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/modify-position-external-ids"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_ADMINISTRATOR']}>
                            <UpdatePositionExternalIDs />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/upload-position-data"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_ADMINISTRATOR']}>
                            <UploadPositionData />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/request-maps"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_SUPER_ADMINISTRATOR']}>
                            <RequestMaps />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/system-permissions"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_SUPER_ADMINISTRATOR']}>
                            <SystemPermission />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="users"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER']}
                        >
                            <Users />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="users/add"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER']}
                        >
                            <AddEditUser redirectRoute={'/users'} />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="users/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER']}
                        >
                            <AddEditUser redirectRoute={'/users'} />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="users/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER']}
                        >
                            <ViewUser />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/:companyId/config"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_SUPER_ADMINISTRATOR']}>
                            <CompanyConfig />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/:companyId/users"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={[
                                'ROLE_SUPER_ADMINISTRATOR',
                                'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_LIST_SECUSER',
                            ]}
                        >
                            <Users />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/:companyId/users/add"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={[
                                'ROLE_SUPER_ADMINISTRATOR',
                                'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER',
                            ]}
                        >
                            <AddEditUser />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/:companyId/users/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER']}
                        >
                            <AddEditUser />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="company/:companyId/users/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_VIEW_SECUSER']}
                        >
                            <ViewUser />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/locations"
                    element={
                        <AuthorizedRouteWithLayout>
                            <Locations />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/locations/add"
                    element={
                        <AuthorizedRouteWithLayout>
                            <AddEditLocation />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/locations/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout>
                            <AddEditLocation />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/locations/:id"
                    element={
                        <AuthorizedRouteWithLayout>
                            <ViewLocation />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/designations"
                    element={
                        <AuthorizedRouteWithLayout>
                            <Designations />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/designations/add"
                    element={
                        <AuthorizedRouteWithLayout>
                            <AddEditDesignation />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/designations/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout>
                            <AddEditDesignation />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/designations/:id"
                    element={
                        <AuthorizedRouteWithLayout>
                            <ViewDesignations />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/change-policy/roles"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_ADMINISTRATOR']}>
                            <ChangeRequestPolicyRoles />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/roles"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_BASE_PERMISSION_SECROLE_LIST']}>
                            <Roles />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/roles/add"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_BASE_PERMISSION_SECROLE_CREATE']}>
                            <AddEditRoles />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/roles/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_BASE_PERMISSION_SECROLE_UPDATE']}>
                            <AddEditRoles />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/roles/:id"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_BASE_PERMISSION_SECROLE_VIEW']}>
                            <ViewRoles />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/demand-attributes"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={[
                                'ROLE_BASE_PERMISSION_INDEX_CREATE_META_OBJECT_DEFN',
                                'ROLE_BASE_PERMISSION_INDEX_LIST_META_OBJECT_DEFN',
                                'ROLE_BASE_PERMISSION_INDEX_VIEW_META_OBJECT_DEFN',
                                'ROLE_BASE_PERMISSION_INDEX_UPDATE_META_OBJECT_DEFN',
                                'ROLE_BASE_PERMISSION_INDEX_DELETE_META_OBJECT_DEFN',
                                'ROLE_BASE_PERMISSION_INDEX_SEARCH_META_OBJECT_DEFN',
                            ]}
                        >
                            <AddCustomAttributes />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/recruitment-policy/attributes"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={[
                                'ROLE_BASE_PERMISSION_RECRUIT_INDEX_PRE_CREATE_RULE_SETTINGS_FOR_RECRUITMENT_POLICY',
                            ]}
                        >
                            <RecruitmentPolicyAttributes />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/departments"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_LIST_DEPARTMENTS']}
                        >
                            <Departments />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/departments/add"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DEPARTMENT']}
                        >
                            <AddEditDepartment />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/departments/edit/:id"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_UPDATE_DEPARTMENT']}
                        >
                            <AddEditDepartment />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/departments/:id"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_VIEW_DEPARTMENT']}>
                            <ViewDepartment />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="crpolicies/mappedpositions/view/:crPolicyId"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={[
                                'ROLE_BASE_PERMISSION_RECRUIT_INDEX_VIEW_POSITIONS_MAPPED_TO_CHANGE_REQUEST_POLICY',
                            ]}
                        >
                            <ViewUpdateMappedPositions />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="crpolicies/mappedpositions/update/:crPolicyId"
                    element={
                        <AuthorizedRouteWithLayout
                            requiredPermissions={[
                                'ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_CHANGE_REQUEST_POLICY_MAPPINGS',
                            ]}
                        >
                            <ViewUpdateMappedPositions />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="settings/update-integration"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={['ROLE_SUPER_ADMINISTRATOR']}>
                            <UpdateIntegration />
                        </AuthorizedRouteWithLayout>
                    }
                />
                <Route
                    path="access-denied"
                    element={
                        <AuthorizedRouteWithLayout requiredPermissions={[]}>
                            <AccessDenied />
                        </AuthorizedRouteWithLayout>
                    }
                />
            </Route>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/*" element={<PageNotFound />} />
        </Routes>
    );
};

export default Navigation;
