import React, { useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { api } from '../../../axios';
import { styles } from './styles';

const UpdateIntegration = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        const response = await api.post('/loadIntegrationConfiguration');
        if (response.data) {
            enqueueSnackbar('Integration Updated Succesfuly', {
                variant: 'success',
            });
            setLoading(false);
        }
    };
    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        container
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Grid item>
                            <Typography variant="h4">Refresh Integration</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <LoadingButton
                                loading={loading}
                                color="primary"
                                onClick={handleClick}
                                size="large"
                                variant="contained"
                                sx={{
                                    ':hover': {
                                        backgroundColor: 'secondary.main',
                                    },
                                }}
                            >
                                Refresh
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default UpdateIntegration;
