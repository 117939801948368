import React from 'react';
import { Grid, Alert, Typography } from '@mui/material';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    /* eslint-disable react/no-danger */
    render() {
        const { error } = this.state;
        if (error) {
            return (
                <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                    <Grid item>
                        <Typography variant="h6" color="secondary">
                            Something went wrong.
                        </Typography>
                        <Alert severity="error">
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: error.stack.replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;'),
                                }}
                            />
                        </Alert>
                        <Typography component="a" href="/" color="secondary">
                            Go to home
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        const { children } = this.props;
        return children;
    }
}

export default ErrorBoundary;
