import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus as PlusIcon } from '../../assets/icons/plus';
import { useSelection } from '../../hooks/use-selection';
import { styles } from './styles';
import CustomTable from '../../components/common/CustomTable';
import { api } from '../../axios';
import { UsersFilter } from './Users';
import { useColumns } from './columns';
import PermissionChecker from '../../components/common/PermissionChecker';
import {
    clearFilters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    setFilters,
    setInitialFilters,
} from '../../store/slices/tableController';

const Users = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const controller = useSelector((state) => state.tableController);

    useEffect(() => {
        dispatch(
            setInitialFilters([
                {
                    property: {
                        name: 'firstName',
                        type: 'string',
                    },
                    operator: {
                        value: '',
                        typesAccepted: [],
                    },
                    value: '',
                },
            ]),
        );
    }, []);

    const fetchData = async (searched, filters, sort, sortBy, page, pageSize) => {
        setLoading(true);
        try {
            if (searched) {
                const query = new URLSearchParams({});
                if (params.companyId) {
                    query.append('company', params.companyId);
                }
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                if (filters.length) {
                    // filters.forEach((filter) => query.append(filter.property, filter.value));
                    query.append('c', filters[0].property);
                    query.append('q', filters[0].value);
                }
                const response = await api.post(`/searchUsers?${query.toString()}`);
                if (response.data) {
                    setUsers(response.data.employeeInstanceList);
                    setTotalUsers(response.data.employeeInstanceCount);
                }
            } else {
                const query = new URLSearchParams({});
                if (params.companyId) {
                    query.append('company', params.companyId);
                }
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/listUsers?${query.toString()}`);
                if (response.data) {
                    setUsers(response.data.employeeInstanceList);
                    setTotalUsers(response.data.employeeInstanceCount);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const columns = useColumns(fetchData);
    const [users, setUsers] = useState([]);
    const [selectedUsers, handleSelect, handleSelectAll] = useSelection(users);

    useEffect(() => {
        fetchData(
            controller.searched,
            controller.filters,
            controller.sort,
            controller.sortBy,
            controller.page,
            controller.pageSize,
        );
    }, [
        controller.searched,
        controller.filters,
        controller.sort,
        controller.sortBy,
        controller.page,
        controller.pageSize,
    ]);

    const handleFiltersApply = (newFilters) => {
        const parsedFilters = newFilters.map((filter) => ({
            property: filter.property.name,
            value: filter.value,
            operator: filter.operator.value,
        }));

        dispatch(setFilters(parsedFilters));
    };

    const handleFiltersClear = () => {
        dispatch(clearFilters());
    };

    const handlePageChange = (newPage) => {
        dispatch(onPageChange(newPage - 1));
    };

    const handlePageSizeChange = (newPageSize) => {
        dispatch(onPageSizeChange(newPageSize));
    };

    const handleSortChange = (event, property) => {
        const isAsc = controller.sortBy === property && controller.sort === 'asc';
        dispatch(
            onSortChange({
                sort: isAsc ? 'desc' : 'asc',
                sortBy: property,
            }),
        );
    };

    const onRowClick = (userId) => {
        navigate(params.companyId ? `/company/${params.companyId}/users/${userId}` : `/users/${userId}`);
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        sx={{
                            py: '32px !important',
                        }}
                        item
                        container
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        py={4}
                    >
                        <Grid item>
                            <Typography variant="h4">Users</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs="auto">
                            <PermissionChecker
                                requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_CREATE_SECUSER']}
                                noAuthRedirect={false}
                            >
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        navigate(
                                            params.companyId ? `/company/${params.companyId}/users/add` : '/users/add',
                                        )
                                    }
                                    size="large"
                                    startIcon={<PlusIcon fontSize="small" />}
                                    variant="contained"
                                >
                                    Add User
                                </Button>
                            </PermissionChecker>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <UsersFilter
                                disabled={false}
                                filters={controller.filters}
                                onFiltersApply={handleFiltersApply}
                                onFiltersClear={handleFiltersClear}
                                selectedUsers={selectedUsers}
                                filterProperties={columns}
                                initialFilters={controller.initialFilters}
                            />
                            <CustomTable
                                onRowClick={onRowClick}
                                isLoading={loading}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handlePageSizeChange}
                                onSelect={handleSelect}
                                onSelectAll={handleSelectAll}
                                onSortChange={handleSortChange}
                                page={controller.page + 1}
                                columns={columns}
                                rows={users}
                                rowsCount={totalUsers}
                                selectedRows={selectedUsers}
                                sort={controller.sort}
                                sortBy={controller.sortBy}
                                showPagignation
                                keyColumn="userID"
                                action={() =>
                                    fetchData(
                                        controller.searched,
                                        controller.filters,
                                        controller.sort,
                                        controller.sortBy,
                                        controller.page,
                                        controller.pageSize,
                                    )
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        </Box>
    );
};

export default Users;
