import React from 'react';
import { Typography } from '@mui/material';
import numeral from 'numeral';
import PositionStatusChip from '../Positions/PositionStatusChip';
import moment from 'moment';

export const columns = [
    {
        fieldName: 'positionCode',
        name: 'c',
        label: 'POSITION CODE',
        type: 'number',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'title',
        name: 't',
        label: 'TITLE',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'status',
        name: 's',
        label: 'STATUS',
        type: 'string',
        formatValue: ({ row, column }) => <PositionStatusChip label={row[column.fieldName]} />,
    },
    // {
    //   fieldName: 'publicationStatus',
    //   name: 'publicationStatus',
    //   label: 'PUBLICATION STATUS',
    //   type: 'string',
    //   formatValue: ({ row, column }) => (
    //     <StatusLabel color={row[column.fieldName] === 'Unpublished' ? '#EFA12D' : '#27AB6E'} label={row[column.fieldName]} />
    //   ),
    // },
    {
        fieldName: 'budgetLowerLimit',
        name: 'l',
        label: 'BUDGET LOWER LIMIT',
        type: 'number',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {numeral(row[column.fieldName]).format('0,0.00')}
            </Typography>
        ),
    },
    {
        fieldName: 'budgetMidLimit',
        name: 'm',
        label: 'BUDGET MID LIMIT',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {numeral(row[column.fieldName]).format('0,0.00')}
            </Typography>
        ),
    },
    {
        fieldName: 'budgetUpperLimit',
        name: 'u',
        label: 'BUDGET UPPER LIMIT',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {numeral(row[column.fieldName]).format('0,0.00')}
            </Typography>
        ),
    },
    {
        fieldName: 'department',
        name: 'department',
        label: 'DEPARTMENT',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.name}
            </Typography>
        ),
    },
    {
        fieldName: 'designation',
        name: 'designation',
        label: 'DESIGNATION',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.name}
            </Typography>
        ),
    },
    {
        fieldName: 'location',
        name: 'location',
        label: 'LOCATION',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.name}
            </Typography>
        ),
    },
    {
        fieldName: 'startDate',
        name: 'startDate',
        label: 'Position Start Date',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName] ? moment(row[column.fieldName]).format('MMM DD yyyy') : ''}
            </Typography>
        ),
    },
];
