import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Plus as PlusIcon } from '../../assets/icons/plus';
import { styles } from './styles';
import CustomTable from '../../components/common/CustomTable';
import { api, formDataApi } from '../../axios';
import { columns } from './columns';
import { updateCompanies, updateSelectedCompanies, updateAllSelectedCompanies } from '../../store/slices/companies';
import PermissionChecker from '../../components/common/PermissionChecker';
import {
    clearFilters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    setFilters,
    setInitialFilters,
} from '../../store/slices/tableController';
import { CompanyFilter } from './CompanyFilter';

const Company = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const controller = useSelector((state) => state.tableController);
    const { companies, selectedCompanies } = useSelector((state) => state.companies);

    const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
        setLoading(true);
        try {
            if (searched) {
                const query = new URLSearchParams({});
                if (filters.length) {
                    filters.forEach((filter) => query.append(filter.property, filter.value));
                }
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/searchCompanies?${query.toString()}`);
                if (response.data) {
                    dispatch(updateCompanies(response.data));
                }
            } else {
                const query = new URLSearchParams({});
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/listCompanies?${query.toString()}`);
                if (response.data) {
                    dispatch(updateCompanies(response.data));
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        dispatch(
            setInitialFilters([
                {
                    property: {
                        name: 'companyName',
                        type: 'string',
                    },
                    operator: {
                        value: '',
                        typesAccepted: [],
                    },
                    value: '',
                },
            ]),
        );
    }, []);

    useEffect(() => {
        if (fetchData)
            fetchData(
                controller.searched,
                controller.filters,
                controller.sort,
                controller.sortBy,
                controller.page,
                controller.pageSize,
            );
    }, [
        controller.searched,
        controller.filters,
        controller.sort,
        controller.sortBy,
        controller.page,
        controller.pageSize,
        fetchData,
    ]);

    const handlePageChange = (newPage) => {
        dispatch(onPageChange(newPage - 1));
    };

    const handlePageSizeChange = (newPageSize) => {
        dispatch(onPageSizeChange(newPageSize));
    };

    const handleSortChange = (event, property) => {
        const isAsc = controller.sortBy === property && controller.sort === 'asc';
        dispatch(
            onSortChange({
                sort: isAsc ? 'desc' : 'asc',
                sortBy: property,
            }),
        );
    };

    const handleRowClick = (id) => {
        navigate(`/company/${id}`);
    };

    const handleSelect = (event, rowId) => {
        dispatch(
            updateSelectedCompanies({
                isChecked: event.target.checked,
                rowId,
            }),
        );
    };

    const handleSelectAll = (event) => {
        dispatch(
            updateAllSelectedCompanies({
                isChecked: event.target.checked,
            }),
        );
    };

    const handleFiltersApply = (newFilters) => {
        const parsedFilters = newFilters.map((filter) => ({
            property: filter.property.name,
            value: filter.value,
            operator: filter.operator.value,
        }));

        dispatch(setFilters(parsedFilters));
    };

    const handleFiltersClear = () => {
        dispatch(clearFilters());
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column">
                    <Grid item container spacing={1} justifyContent="flex-start" alignItems="center" py={4}>
                        <Grid item>
                            <Typography variant="h4">Company</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item xs="auto">
                            <PermissionChecker
                                requiredPermissions={['ROLE_BASE_PERMISSION_SYSTEM_INDEX_CREATE_COMPANY']}
                                noAuthRedirect={false}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => navigate('/company/add')}
                                    size="large"
                                    startIcon={<PlusIcon fontSize="small" />}
                                    variant="contained"
                                    sx={{
                                        ':hover': {
                                            backgroundColor: 'secondary.main',
                                        },
                                    }}
                                >
                                    Add Company
                                </Button>
                            </PermissionChecker>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Card>
                            <CardContent>
                                <CompanyFilter
                                    disabled={false}
                                    initialFilters={controller.initialFilters}
                                    onFiltersApply={handleFiltersApply}
                                    onFiltersClear={handleFiltersClear}
                                    filterProperties={columns}
                                    filters={controller.filters}
                                />
                                <CustomTable
                                    isLoading={loading}
                                    onPageChange={handlePageChange}
                                    onPageSizeChange={handlePageSizeChange}
                                    onSelect={handleSelect}
                                    onSelectAll={handleSelectAll}
                                    onRowClick={handleRowClick}
                                    onSortChange={handleSortChange}
                                    page={controller.page + 1}
                                    columns={columns}
                                    rows={companies.data}
                                    rowsCount={companies.count}
                                    selectedRows={selectedCompanies}
                                    sort={controller.sort}
                                    sortBy={controller.sortBy}
                                    showRowSelector={false}
                                    showPagignation={true}
                                    keyColumn="id"
                                    action={() =>
                                        fetchData(
                                            controller.filters,
                                            controller.sort,
                                            controller.sortBy,
                                            controller.page,
                                            controller.pageSize,
                                        )
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Company;
