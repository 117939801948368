import { createSlice } from '@reduxjs/toolkit';

const designations = createSlice({
    name: 'designations',
    initialState: {
        selectedDesignations: [],
        designations: {
            count: 0,
            data: [],
        },
    },
    reducers: {
        updateDesignations(state, action) {
            const { designationInstanceList = [], designationInstanceCount = 0 } = action.payload;
            state.designations.data = designationInstanceList;
            state.designations.count = designationInstanceCount;
        },
        updateSelectedDesignations(state, action) {
            const { isChecked, rowId } = action.payload;

            if (isChecked) {
                state.selectedDesignations.push(rowId);
                return;
            }
            state.selectedDesignations = state.selectedDesignations.filter((selectedRow) => selectedRow !== rowId);
        },
        updateAllSelectedDesignations(state, action) {
            const { isChecked } = action.payload;
            state.selectedDesignations = [];
            if (isChecked) {
                state.designations.data.map((row) => state.selectedDesignations.push(row['id']));
                return;
            }
            state.selectedDesignations = [];
        },
    },
});

export const { updateDesignations, updateSelectedDesignations, updateAllSelectedDesignations } = designations.actions;

export default designations.reducer;
