import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Popover,
    Typography,
} from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Logout as LogoutIcon } from '../../../assets/icons/logout';
import { User as UserIcon } from '../../../assets/icons/user';

export const AccountPopover = (props) => {
    const {
        currentOrganization,
        darkMode,
        onLanguageChange,
        onOrganizationChange,
        onSwitchDirection,
        onSwitchTheme,
        organizations,
        rtlDirection,
        onLogout,
        loading,
        ...other
    } = props;
    const navigate = useNavigate();
    const [anchorRef, open, handleOpen, handleClose] = usePopover();

    const currentUser = useSelector((state) => state.session.user);

    const navigateTo = (path) => {
        navigate(path);
        handleClose();
    };

    return (
        <>
            <Box
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    ml: 2,
                }}
                {...other}
            >
                <Avatar
                    src="/static/user-chen_simmons.png"
                    variant="rounded"
                    sx={{
                        height: 40,
                        width: 40,
                    }}
                />
                <Box
                    sx={{
                        alignItems: 'center',
                        display: {
                            md: 'flex',
                            xs: 'none',
                        },
                        flex: 1,
                        ml: 1,
                        minWidth: 120,
                    }}
                >
                    <div>
                        <Typography
                            sx={{
                                color: 'neutral.500',
                            }}
                            variant="caption"
                        >
                            {!!currentUser?.designation ? '' : currentUser?.designation}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'neutral.1000',
                            }}
                            variant="subtitle2"
                        >
                            {`${currentUser?.firstName} ${currentUser?.lastName}`}
                        </Typography>
                    </div>
                    <ExpandMoreIcon
                        sx={{
                            color: 'secondary.secondary',
                            ml: 1,
                        }}
                    />
                </Box>
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: 260,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
            >
                <List>
                    <ListItem divider>
                        <ListItemAvatar>
                            <Avatar variant="rounded" src="/static/user-chen_simmons.png" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${currentUser?.firstName} ${currentUser?.lastName}`}
                            secondary={currentUser?.company}
                        />
                    </ListItem>
                    <ListItem button component="a" divider onClick={() => navigateTo('/dashboard/account')}>
                        <ListItemIcon>
                            <UserIcon />
                        </ListItemIcon>
                        <ListItemText primary="Account" />
                    </ListItem>
                    <ListItem button onClick={() => onLogout()}>
                        <ListItemIcon>
                            {loading ? <CircularProgress color="inherit" size="1.2rem" /> : <LogoutIcon />}
                        </ListItemIcon>
                        <ListItemText primary="Log out" />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

AccountPopover.propTypes = {
    currentOrganization: PropTypes.object.isRequired,
    darkMode: PropTypes.bool.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
    onOrganizationChange: PropTypes.func.isRequired,
    onSwitchDirection: PropTypes.func.isRequired,
    onSwitchTheme: PropTypes.func.isRequired,
    organizations: PropTypes.array.isRequired,
    rtlDirection: PropTypes.bool.isRequired,
};
