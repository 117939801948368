import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    Skeleton,
    TextField,
    InputAdornment,
    FormGroup,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { LoadingButton } from '@mui/lab';
import { formDataApi } from '../../axios';
import { useSnackbar } from 'notistack';

const ChangeRequestPolicyRoles = (props) => {
    const styles = {};
    const [isLoading, setIsLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [searchText, setSearchText] = useState({
        major: '',
        minor: '',
    });
    const [selectedRoles, setSelectedRoles] = useState({
        major: [],
        minor: [],
    });

    const { enqueueSnackbar } = useSnackbar();

    const fetchPreCreateUpdateData = async () => {
        setIsLoading(true);
        try {
            const response = await formDataApi.post('/preCreateRolesMappingToChangeRequestPolicy');
            if (response?.data) {
                setRoles(response?.data?.roles || []);
                setSelectedRoles({
                    major: response?.data?.majorApproverRoles ? JSON.parse(response?.data?.majorApproverRoles) : [],
                    minor: response?.data?.minorApproverRoles ? JSON.parse(response?.data?.minorApproverRoles) : [],
                });
            }
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPreCreateUpdateData();
    }, []);

    const updateRoles = async () => {
        const bodyFormData = new FormData();
        bodyFormData.append('majorApproverRoles', selectedRoles.major);
        bodyFormData.append('minorApproverRoles', selectedRoles.minor);
        try {
            const response = await formDataApi.post('/saveRolesMappedToChangeRequestPolicy', bodyFormData);
            if (response?.data && response?.data?.status === 'SUCCESS') {
                enqueueSnackbar(response?.data?.message, {
                    variant: 'success',
                });
                fetchPreCreateUpdateData();
            } else
                enqueueSnackbar(response?.data?.message, {
                    variant: 'error',
                });
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        }
    };

    const handleChange = (id, isChecked, type) => {
        let roles = [...selectedRoles[type]];
        if (!isChecked) roles.splice(roles.indexOf(id), 1);
        else roles = [...roles, id];
        setSelectedRoles({ ...selectedRoles, [type]: roles });
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Grid item>
                            <Typography variant="h4">Change Policy Roles</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0 !important',
                        }}
                    >
                        <Card>
                            <CardContent>
                                <Grid container direction="column">
                                    <Grid container direction="flex" spacing={4} p={3}>
                                        <Grid
                                            item
                                            sx={{
                                                width: '50%',
                                            }}
                                            p={2}
                                        >
                                            <Typography variant="h4">Minor Roles</Typography>
                                            <Grid item>
                                                {isLoading ? (
                                                    <Box pt="10px">
                                                        <Skeleton height={42} />
                                                        <Skeleton />
                                                        <Skeleton />
                                                    </Box>
                                                ) : (
                                                    <Box pt="20px">
                                                        <Card
                                                            sx={{
                                                                boxShadow: 'none',
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    placeholder="Search here"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <SearchOutlinedIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onChange={(e) =>
                                                                        setSearchText({
                                                                            ...searchText,
                                                                            minor: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {roles.length === 0 ? (
                                                                    <Box pt="20px" textAlign="center">
                                                                        No roles found
                                                                    </Box>
                                                                ) : (
                                                                    <Box pt="20px">
                                                                        <FormGroup>
                                                                            {roles
                                                                                .filter(
                                                                                    (item) =>
                                                                                        item.displayName
                                                                                            ?.toLowerCase()
                                                                                            ?.search(
                                                                                                searchText.minor?.toLowerCase(),
                                                                                            ) >= 0,
                                                                                )
                                                                                .map((item) => (
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={selectedRoles[
                                                                                                    'minor'
                                                                                                ].includes(item.id)}
                                                                                                onChange={(e) =>
                                                                                                    handleChange(
                                                                                                        item.id,
                                                                                                        e.target
                                                                                                            .checked,
                                                                                                        'minor',
                                                                                                    )
                                                                                                }
                                                                                                name={item.id}
                                                                                            />
                                                                                        }
                                                                                        label={item.displayName}
                                                                                    />
                                                                                ))}
                                                                        </FormGroup>
                                                                    </Box>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                width: '50%',
                                            }}
                                            p={2}
                                        >
                                            <Typography variant="h4">Major Roles</Typography>
                                            <Grid item>
                                                {isLoading ? (
                                                    <Box pt="10px">
                                                        <Skeleton height={42} />
                                                        <Skeleton />
                                                        <Skeleton />
                                                    </Box>
                                                ) : (
                                                    <Box pt="20px">
                                                        <Card
                                                            sx={{
                                                                boxShadow: 'none',
                                                            }}
                                                        >
                                                            <CardContent>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    placeholder="Search here"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    fullWidth
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <SearchOutlinedIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onChange={(e) =>
                                                                        setSearchText({
                                                                            ...searchText,
                                                                            major: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                {roles.length === 0 ? (
                                                                    <Box pt="20px" textAlign="center">
                                                                        No roles found
                                                                    </Box>
                                                                ) : (
                                                                    <Box pt="20px">
                                                                        <FormGroup>
                                                                            {roles
                                                                                .filter(
                                                                                    (item) =>
                                                                                        item.displayName
                                                                                            ?.toLowerCase()
                                                                                            ?.search(
                                                                                                searchText.major?.toLowerCase(),
                                                                                            ) >= 0,
                                                                                )
                                                                                .map((item) => (
                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={selectedRoles[
                                                                                                    'major'
                                                                                                ].includes(item.id)}
                                                                                                onChange={(e) =>
                                                                                                    handleChange(
                                                                                                        item.id,
                                                                                                        e.target
                                                                                                            .checked,
                                                                                                        'major',
                                                                                                    )
                                                                                                }
                                                                                                name={item.id}
                                                                                            />
                                                                                        }
                                                                                        label={item.displayName}
                                                                                    />
                                                                                ))}
                                                                        </FormGroup>
                                                                    </Box>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </Box>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            pt: '0 !important',
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        />
                                        <LoadingButton
                                            size="medium"
                                            sx={{
                                                ml: 2,
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                selectedRoles.major.length === 0 || selectedRoles.minor.length === 0
                                            }
                                            onClick={updateRoles}
                                        >
                                            Update
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ChangeRequestPolicyRoles;
