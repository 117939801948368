import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../axios';
import { endpoints } from '../constants';

const initialState = {
    positions: [],
    bulkUpdatePositions: [],
};

export const setBulkUpdatePositionIds = createAsyncThunk(
    'bulkUpdatePositionStatuses',
    (data = {}, { rejectWithValue }) =>
        api
            .post(endpoints.BULK_UPDATE_POSITION_STATUSES, data)
            .then((res) => {
                if (res?.data?.status !== 'SUCCESS') {
                    return rejectWithValue(res.data);
                }
                return res.data;
            })
            .catch((error) => console.log(error)),
);

const positions = createSlice({
    name: 'positions',
    initialState: {
        selectedPositions: [],
        positions: {
            count: 0,
            data: [],
            states: {},
            extendedDef: {},
        },
    },
    reducers: {
        updatePositions(state, action) {
            const {
                positionInstanceList = [],
                statusWiseStats = {},
                positionInstanceCount = 0,
                totalCount = 0,
                statuses,
                extendedDef = {},
            } = action.payload;
            state.positions.data = positionInstanceList;
            state.positions.count = positionInstanceCount || totalCount;
            state.positions.states = statusWiseStats;
            state.positions.statusList = statuses;
            state.positions.extendedDef = extendedDef;
        },
        updateSelectedPositions(state, action) {
            const { isChecked, rowId } = action.payload;

            if (isChecked) {
                state.selectedPositions.push(rowId);
                return;
            }
            state.selectedPositions = state.selectedPositions.filter((selectedRow) => selectedRow !== rowId);
        },
        updateAllSelectedPositions(state, action) {
            const { isChecked } = action.payload;
            state.selectedPositions = [];
            if (isChecked) {
                state.positions.data.map((row) => state.selectedPositions.push(row['id']));
                return;
            }
            state.selectedPositions = [];
        },
        setBulkUpdatePositionIds(state, action) {
            state.bulkUpdatePositions = action.payload;
        },
    },
});

export const { updatePositions, updateSelectedPositions, updateAllSelectedPositions } = positions.actions;

export default positions.reducer;
