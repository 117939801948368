import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useRuleInputFormik } from './formik';
import { useSnackbar } from 'notistack';
import { styles } from './styles';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { formDataApi } from '../../../axios';
import PermissionChecker from '../../../components/common/PermissionChecker';
import DynamicField from '../../../components/common/InputField/DynamicField';

export const ModifyRule = (props) => {
    const { index, rule, data, modifyId, handleModify, handleRefresh } = props;
    const params = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const { formik } = useRuleInputFormik(data?.attributes, rule, () => handleSubmit());
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = () => {
        setOpenDialog(true);
    };

    const handleSave = async () => {
        if (isSaving) return;
        const bodyFormData = new FormData();
        bodyFormData.append('recruitmentPolicyId', params.id);
        bodyFormData.append('ruleId', rule?.id);
        bodyFormData.append('ruleDefiningIds', Object.keys(formik.values));
        bodyFormData.append('rulesValues', Object.values(formik.values));
        try {
            setSaving(true);
            const response = await formDataApi.post('/saveRulesForRecruitmentPolicy', bodyFormData);
            if (response?.data && response?.data?.status === 'SUCCESS') {
                enqueueSnackbar(response?.data?.message, {
                    variant: 'success',
                });
                handleModify();
                handleRefresh();
            } else
                enqueueSnackbar(response?.data?.message, {
                    variant: 'error',
                });
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        } finally {
            setOpenDialog(false);
            setSaving(false);
        }
    };

    const handleDelete = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteConfirm = async (id) => {
        const bodyFormData = new FormData();
        bodyFormData.append('recruitmentPolicyId', params.id);
        bodyFormData.append('deletedRuleId', rule?.id);
        try {
            const response = await formDataApi.post('/deleteRulesForRecruitmentPolicy', bodyFormData);
            if (response?.data && response?.data?.status === 'SUCCESS') {
                enqueueSnackbar(response?.data?.message, {
                    variant: 'success',
                });
                handleModify();
                handleRefresh();
            } else
                enqueueSnackbar(response?.data?.message, {
                    variant: 'error',
                });
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        } finally {
            setOpenDeleteDialog(false);
        }
    };

    return (
        <Box>
            <Box>
                <Divider
                    style={{
                        marginBottom: '20px',
                        marginTop: '30px',
                    }}
                />
                <Box
                    mb={3}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <Typography variant="h5" sx={styles.basicInformationText}>{`Rule ${index + 1}`}</Typography>
                    </Box>
                    <Box>
                        {!modifyId && data?.recruitmentPolicyStatus?.toLowerCase() === 'active' && (
                            <PermissionChecker
                                requiredPermissions={[
                                    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_SAVE_RULES_FOR_RECRUITMENT_POLICY',
                                ]}
                                noAuthRedirect={false}
                            >
                                <Button
                                    startIcon={<Edit />}
                                    variant="text"
                                    color="primary"
                                    onClick={() => handleModify(rule?.id)}
                                >
                                    Modify
                                </Button>
                            </PermissionChecker>
                        )}
                        {data?.recruitmentPolicyStatus?.toLowerCase() === 'active' && (
                            <PermissionChecker
                                requiredPermissions={[
                                    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_DELETE_RULES_FOR_RECRUITMENT_POLICY',
                                ]}
                                noAuthRedirect={false}
                            >
                                <Button
                                    sx={{
                                        marginLeft: '10px',
                                    }}
                                    startIcon={<Delete />}
                                    variant="text"
                                    color="primary"
                                    onClick={() => handleDelete(rule?.id)}
                                >
                                    Delete
                                </Button>
                            </PermissionChecker>
                        )}
                    </Box>
                </Box>
                {
                    <Grid container spacing={3}>
                        {data?.attributes?.map((item) => (
                            <Grid item xs={4} key={item.id}>
                                <DynamicField
                                    formik={formik}
                                    field={item.definitions}
                                    fieldKey="id"
                                    disabled={modifyId !== rule?.id}
                                />
                            </Grid>
                        ))}
                    </Grid>
                }
                {modifyId && modifyId === rule?.id && (
                    <Grid
                        item
                        marginTop={4}
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        />
                        <Button
                            sx={{
                                marginRight: '20px',
                            }}
                            size="medium"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleModify()}
                        >
                            Cancel
                        </Button>
                        <Button size="medium" variant="contained" color="primary" onClick={formik.handleSubmit}>
                            Save
                        </Button>
                    </Grid>
                )}
            </Box>
            <Dialog open={openDialog}>
                <DialogContent sx={styles.tableMenuDialog}>
                    <Box sx={styles.tableMenuDialog}>
                        <DialogTitle>Save Recruitment Policy Rules</DialogTitle>
                        <DialogContentText>Are you sure you want to save this Recruitment Policy?</DialogContentText>
                    </Box>
                    <DialogActions>
                        <Button size="medium" variant="text" onClick={() => setOpenDialog(false)} disabled={isSaving}>
                            No
                        </Button>
                        <Button
                            size="medium"
                            variant="contained"
                            onClick={handleSave}
                            startIcon={isSaving ? <CircularProgress color="inherit" size="1.2rem" /> : <></>}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={openDeleteDialog}>
                <DialogContent sx={styles.tableMenuDialog}>
                    <Box sx={styles.tableMenuDialog}>
                        <DialogTitle>Delete Recruitment Policy Rule</DialogTitle>
                        <DialogContentText>
                            Are you sure you want to delete this Recruitment Policy Rule?
                        </DialogContentText>
                    </Box>
                    <DialogActions>
                        <Button size="medium" variant="text" onClick={() => setOpenDeleteDialog(false)}>
                            No
                        </Button>
                        <Button size="medium" variant="contained" onClick={handleDeleteConfirm}>
                            Yes
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
