import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { usePopover } from '../../../hooks/use-popover';
import { hasPermission } from '../../../utils/permission';
import ActionMenu from './ActionMenu';

const ActionButton = (props) => {
    const { disabled, actions, label } = props;
    const { user } = useSelector((state) => state.session);
    const [anchorRef, open, handleOpen, handleClose] = usePopover();
    const filteredActions = useMemo(() => {
        return actions.filter((action) => {
            if (!action) return false;
            if (Array.isArray(action.permissions)) return hasPermission(user.authorities || [], action.permissions);
            else return true;
        });
    }, [actions]);

    return (
        filteredActions?.length > 0 && (
            <React.Fragment>
                <Button
                    color="primary"
                    endIcon={<ExpandMoreIcon fontSize="small" />}
                    onClick={handleOpen}
                    size="large"
                    variant="contained"
                    ref={anchorRef}
                    disabled={disabled}
                >
                    {label || 'Actions'}
                </Button>
                <ActionMenu
                    {...props}
                    filteredActions={filteredActions}
                    anchorRef={anchorRef}
                    open={open}
                    handleClose={handleClose}
                />
            </React.Fragment>
        )
    );
};

export default ActionButton;
