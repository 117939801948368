import { createSlice } from '@reduxjs/toolkit';

const customAttributes = createSlice({
    name: 'customAttributes',
    initialState: {
        data: [],
    },
    reducers: {
        updateCustomAttributes(state, action) {
            state.data = action.payload;
        },
    },
});

export const { updateCustomAttributes } = customAttributes.actions;

export default customAttributes.reducer;
