import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { usePopover } from '../../../hooks/use-popover';
import { DotsVertical as DotsVerticalIcon } from '../../../assets/icons/dots-vertical';
import { hasPermission } from '../../../utils/permission';
import ActionMenu from './ActionMenu';

const ActionTableButton = (props) => {
    const { disabled, actions } = props;
    const { user } = useSelector((state) => state.session);
    const [anchorRef, open, handleOpen, handleClose] = usePopover();
    const filteredActions = useMemo(() => {
        return actions.filter((action) => {
            if (!action) return false;
            if (Array.isArray(action.permissions)) return hasPermission(user.authorities || [], action.permissions);
            else return true;
        });
    }, [actions]);

    return (
        <React.Fragment>
            <IconButton
                onClick={handleOpen}
                ref={anchorRef}
                color="primary"
                disabled={disabled || filteredActions.length <= 0}
            >
                <DotsVerticalIcon fontSize="small" />
            </IconButton>
            <ActionMenu
                {...props}
                filteredActions={filteredActions}
                anchorRef={anchorRef}
                open={open}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
};

export default ActionTableButton;
