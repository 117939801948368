import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, FormHelperText, Grid, MenuItem, Divider, Typography, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { styles } from '../../../features/users/UserDetailsTab/styles';
import TextField from '../../../components/common/InputField/TextField';
import { formDataApi } from '../../../axios';
import { utilStyles } from '../../../utils/styles';

const CompanyTab = ({ isEditMode, company, addressId, companyId, customerId, address }) => {
    const initialAddress = {
        addressLine1: isEditMode ? address?.addressLine1 : '',
        addressLine2: isEditMode ? address?.addressLine2 : '',
        addressLine3: isEditMode ? address?.addressLine3 : '',
        city: isEditMode ? address?.city : '',
        country: isEditMode ? address?.country : '',
        state: isEditMode ? address?.state : '',
        locationName: isEditMode ? address?.locationName : '',
        postalCode: isEditMode ? address?.pinOrZip : '',
    };
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (details) => {
        const bodyFormData = new FormData();
        const data = {
            'customer.customerStatus': details.status,
            'customer.numberOfEmployees': details.numberOfEmployees,
            'customer.description': details.description,
            companyName: details.companyName,
            'address.locationName': details.locationName,
            'address.addressLine1': details.addressLine1,
            'address.addressLine2': details.addressLine2,
            'address.addressLine3': details.addressLine3,
            'address.city': details.city,
            'address.state': details.state,
            'address.country': details.country,
            'address.pinOrZip': details.postalCode,
        };

        if (!isEditMode) {
            data['user.username'] = details.username;
            data['user.employee.emailAddresses[0]'] = details.email;
            data['user.employee.firstName'] = details.firstName;
            data['user.employee.lastName'] = details.lastName;
            data['user.employee.mobileNumbers[0]'] = details.contactNo;
        }

        if (isEditMode) {
            data['customer.id'] = customerId;
            data['id'] = companyId;
            data['address.id'] = addressId;
        }

        Object.keys(data).forEach((key) => {
            if (data[key]) {
                bodyFormData.append(key, data[key]);
            }
        });
        const apiPath = isEditMode ? '/modifyCompanyAction' : '/saveCompany';

        try {
            const response = await formDataApi.post(apiPath, bodyFormData);
            if (response?.data?.status && response?.data?.status?.toLowerCase() === 'success') {
                enqueueSnackbar(response?.data?.message, {
                    variant: 'success',
                });
                navigate('/company');
            } else {
                enqueueSnackbar(response?.data?.message, {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        }
    };

    const statusTypeOptions = ['Active', 'Inactive'];
    const adminValidations = isEditMode
        ? {}
        : {
              username: Yup.string().required('User name is required'),
              email: Yup.string().email('Invalid email').required('Email is required'),
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last name is required'),
              contactNo: Yup.number()
                  .typeError('Invalid Contact No.')
                  .positive('Invalid Contact No.')
                  .test('len', 'Invalid Contact No.', (value) => {
                      const length = value?.toString().length || 0;
                      return length === 0 || length === 10;
                  })
                  .optional(),
          };

    const adminInitialValues = isEditMode
        ? {}
        : {
              username: '',
              email: '',
              firstName: '',
              lastName: '',
              contactNo: '',
          };

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {
            companyName: isEditMode ? company?.customerName : '',
            numberOfEmployees: isEditMode ? company?.numberOfEmployees : '',
            status: isEditMode ? company?.customerStatus : 'Active',
            description: isEditMode ? company?.description : '',
            ...adminInitialValues,
            ...initialAddress,
        },
        validationSchema: Yup.object().shape({
            companyName: Yup.string().required('Company name is required'),
            ...adminValidations,
        }),
        onSubmit: async (values, helpers) => {
            try {
                handleSubmit(values);
                helpers.resetForm();
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
            } catch (err) {
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({
                    submit: err.message,
                });
                helpers.setSubmitting(false);
            }
        },
    });

    return (
        <>
            <Typography sx={styles.header}>Basic Information</Typography>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <Grid container columnSpacing={6} rowSpacing={3}>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.companyName && formik.errors.companyName)}
                                        helperText={formik.touched.companyName && formik.errors.companyName}
                                        fullWidth
                                        label="Company Name"
                                        name="companyName"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.companyName}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(
                                            formik.touched.numberOfEmployees && formik.errors.numberOfEmployees,
                                        )}
                                        fullWidth
                                        helperText={formik.touched.numberOfEmployees && formik.errors.numberOfEmployees}
                                        label="Number Of Employees"
                                        name="numberOfEmployees"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.numberOfEmployees}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        error={Boolean(formik.touched.status && formik.errors.status)}
                                        fullWidth
                                        helperText={formik.touched.status && formik.errors.status}
                                        label="Status"
                                        name="status"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        select
                                        value={formik.values.status}
                                    >
                                        {statusTypeOptions?.map((relationshipTypeOption) => (
                                            <MenuItem key={relationshipTypeOption} value={relationshipTypeOption}>
                                                {relationshipTypeOption}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sx={styles.checkboxWrapper}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        size="small"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: formik.values.description,
                                        }}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        multiline
                                        name="description"
                                        rows={4}
                                        value={formik.values.description}
                                        error={Boolean(formik.touched.description && formik.errors.description)}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={styles.dividerWrapper}>
                                    <Divider item flexItem orientation="horizontal" sx={styles.divider} />{' '}
                                </Grid>

                                {/* HQ Address Section */}

                                <Grid xs={12} item sx={styles.headerWrapper}>
                                    <Grid xs={8.27}>
                                        <Typography>Address</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.locationName && formik.errors.locationName}
                                        error={Boolean(formik.touched.locationName && formik.errors.locationName)}
                                        label="Location name (Optional)"
                                        onBlur={formik.handleBlur}
                                        name={`locationName`}
                                        value={formik.values.locationName}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                                        error={Boolean(formik.touched.addressLine1 && formik.errors.addressLine1)}
                                        label="Address Line 1"
                                        onBlur={formik.handleBlur}
                                        name={`addressLine1`}
                                        value={formik.values.addressLine1}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                                        error={Boolean(formik.touched.addressLine2 && formik.errors.addressLine2)}
                                        label="Address Line 2 (Optional)"
                                        onBlur={formik.handleBlur}
                                        name={`addressLine2`}
                                        value={formik.values.addressLine2}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.addressLine3 && formik.errors.addressLine3}
                                        error={Boolean(formik.touched.addressLine3 && formik.errors.addressLine3)}
                                        label="Address Line 3 (Optional)"
                                        onBlur={formik.handleBlur}
                                        name={`addressLine3`}
                                        value={formik.values.addressLine3}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.city && formik.errors.city}
                                        error={Boolean(formik.touched.city && formik.errors.city)}
                                        label="City"
                                        onBlur={formik.handleBlur}
                                        name={`city`}
                                        value={formik.values.city}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.state && formik.errors.state}
                                        error={Boolean(formik.touched.state && formik.errors.state)}
                                        label="State"
                                        onBlur={formik.handleBlur}
                                        name={`state`}
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.country && formik.errors.country}
                                        error={Boolean(formik.touched.country && formik.errors.country)}
                                        label="Country"
                                        onBlur={formik.handleBlur}
                                        name={`country`}
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                                        error={Boolean(formik.touched.postalCode && formik.errors.postalCode)}
                                        label="Postal/Zip code"
                                        onBlur={formik.handleBlur}
                                        name={`postalCode`}
                                        value={formik.values.postalCode}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={styles.dividerWrapper}>
                                    <Divider item flexItem orientation="horizontal" sx={styles.divider} />{' '}
                                </Grid>

                                {!isEditMode && (
                                    <>
                                        <Grid xs={12} item sx={styles.headerWrapper}>
                                            <Grid xs={8.27}>
                                                <Typography>Admin Details</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                helperText={formik.touched.username && formik.errors.username}
                                                error={Boolean(formik.touched.username && formik.errors.username)}
                                                label="User name"
                                                onBlur={formik.handleBlur}
                                                name={`username`}
                                                value={formik.values.username}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                helperText={formik.touched.email && formik.errors.email}
                                                error={Boolean(formik.touched.email && formik.errors.email)}
                                                label="Email"
                                                onBlur={formik.handleBlur}
                                                name={`email`}
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                helperText={formik.touched.firstName && formik.errors.firstName}
                                                error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                                label="First name"
                                                onBlur={formik.handleBlur}
                                                name={`firstName`}
                                                value={formik.values.firstName}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                helperText={formik.touched.lastName && formik.errors.lastName}
                                                error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                                label="Last name"
                                                onBlur={formik.handleBlur}
                                                name={`lastName`}
                                                value={formik.values.lastName}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                helperText={formik.touched.contactNo && formik.errors.contactNo}
                                                error={Boolean(formik.touched.contactNo && formik.errors.contactNo)}
                                                label="Contact No."
                                                onBlur={formik.handleBlur}
                                                name={`contactNo`}
                                                value={formik.values.contactNo}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="end">
                                        <Button
                                            sx={utilStyles.mr(2)}
                                            color="primary"
                                            size="large"
                                            variant="outlined"
                                            onClick={() => navigate('/company')}
                                        >
                                            Cancel
                                        </Button>
                                        <Button color="primary" size="large" type="submit" variant="contained">
                                            {isEditMode ? 'Update' : 'Create'}
                                        </Button>
                                    </Stack>
                                </Grid>
                                {formik.errors.submit && (
                                    <Grid item xs={12}>
                                        <FormHelperText error>{formik.errors.submit}</FormHelperText>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default CompanyTab;
