import React, { useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'react-mui-dropzone';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { formDataApi, downloadFileApi } from '../../../axios';
import { updatePositions } from '../../../store/slices/bulkUploadPositions';
import PermissionChecker from '../../../components/common/PermissionChecker';
import { hasPermission } from '../../../utils/permission';

const PositionBulkUploadDialog = ({ openBulkUploadDialog, onResult }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const authorities = useSelector((state) => state.session.user.authorities);
    const userHasCompaDownloadPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_DOWNLOAD_POSITION_TEMPLATE',
    ]);

    const handleFiles = (files) => {
        setFiles(files[0]);
    };

    const downloadSampleFile = async () => {
        const dowmloadApiPath = userHasCompaDownloadPermisson
            ? '/downloadPositionDataTemplateWithCompaFields'
            : '/downloadPositionDataTemplate';
        try {
            setDownloading(true);
            const response = await downloadFileApi.post(dowmloadApiPath);

            fileDownload(new Blob([response.data]), 'Template-PositionData.xlsx');
        } catch (error) {
            console.log('inside catche ', error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            setDownloading(false);
        }
    };

    const validate = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('dataFile', files);
            const response = await formDataApi.post(`/loadPositionDataAction`, formData);
            if (response.data?.status !== 'ERROR') {
                enqueueSnackbar(response.data?.message, {
                    variant: 'success',
                });
                onResult(true);
            } else {
                enqueueSnackbar(response.data?.message, {
                    variant: 'error',
                });
                dispatch(updatePositions(response.data));
                //navigate('/positions/preview');
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <Dialog open={openBulkUploadDialog} fullWidth maxWidth="sm" onClose={() => onResult(null)}>
            <DialogTitle>Bulk Upload Positions</DialogTitle>
            <DialogContent>
                <PermissionChecker
                    requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_DOWNLOAD_POSITION_TEMPLATE']}
                    noAuthRedirect={false}
                >
                    <DialogContentText>
                        To download a template file
                        <LoadingButton
                            loading={downloading}
                            variant="text"
                            color="primary"
                            onClick={downloadSampleFile}
                        >
                            Click here
                        </LoadingButton>
                    </DialogContentText>
                </PermissionChecker>
                <Grid container justifyContent="center" alignItems="center" mt={2}>
                    <Grid item xs={12}>
                        <DropzoneArea
                            dropzoneText="Drag and drop a file here or click"
                            filesLimit={1}
                            onChange={handleFiles}
                            showAlerts={false}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview
                            previewGridProps={{
                                container: {
                                    spacing: 1,
                                    direction: 'row',
                                },
                            }}
                            previewText="Selected files"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    disabled={!files}
                    loading={loading}
                    variant="contained"
                    color="primary"
                    onClick={validate}
                >
                    Upload
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default PositionBulkUploadDialog;
