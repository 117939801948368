import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography, Select, MenuItem, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PaginationRoot = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(2),
}));

const Pagination = (props) => {
    const { disabled, onPageChange, onPageSizeChange, page, rowsCount, ...other } = props;
    const [pageSize, setPageSize] = useState(10);
    // NOTE: Usually, this should be received from the server

    useEffect(() => {
        onPageSizeChange?.(pageSize);
    }, [pageSize]);

    const handlePreviousPage = () => {
        onPageChange?.(page - 1);
    };

    const handleNextPage = () => {
        onPageChange?.(page + 1);
    };

    const pagesCount = Math.ceil(rowsCount / pageSize);
    const isFirstPage = page === 1;
    const isLastPage = page >= pagesCount;

    return (
        <PaginationRoot {...other}>
            {pagesCount > 1 && (
                <Typography
                    color="textSecondary"
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                    }}
                    variant="body2"
                    whiteSpace="nowrap"
                >
                    Page{' '}
                    <Typography color="textPrimary" component="span" sx={{ mx: 1 }} variant="subtitle2">
                        {page}
                    </Typography>
                    of{' '}
                    <Typography color="textPrimary" component="span" sx={{ ml: 1 }} variant="subtitle2">
                        {pagesCount}
                    </Typography>
                </Typography>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Typography
                color="textSecondary"
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                }}
                variant="body2"
                whiteSpace="nowrap"
            >
                Rows per page:
            </Typography>
            <FormControl sx={{ m: 1 }} size="small">
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    size="small"
                    defaultValue={10}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value)}
                >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                </Select>
            </FormControl>
            <IconButton disabled={isFirstPage || disabled} onClick={handlePreviousPage}>
                <ChevronLeftIcon />
            </IconButton>
            <IconButton disabled={isLastPage || disabled} onClick={handleNextPage}>
                <ChevronRightIcon />
            </IconButton>
        </PaginationRoot>
    );
};

export default Pagination;

Pagination.defaultProps = {
    disabled: false,
    page: 1,
    rowsCount: 1,
};

Pagination.propTypes = {
    disabled: PropTypes.bool,
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    rowsCount: PropTypes.number,
};
