import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Chip, Container, Grid, Skeleton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { formDataApi } from '../../../axios';
import { styles } from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ModifyRule } from './ModifyRule';
import { AddRule } from './AddRule';
import { PolicyStatusColor } from './columns';
import AuditLog from '../../../components/common/AuditLog';
import { RecruitmentPolicyMenu } from './RecruitmentPolicyMenu';
import ActionButton from '../../../components/common/ActionButtonAndMenu/ActionButton';
import AuditLogTable from '../../../components/common/AuditLog/AuditLogTable';

export const initialFieldDefinitions = {
    1: {
        id: 1,
        fieldLabel: 'Designation',
        fieldDataType: 'Single Selection List',
        fieldListOptionsData: {
            key: 'designationsList',
            id: 'id',
            value: 'name',
        },
    },
    2: {
        id: 2,
        fieldLabel: 'Budget Lower Limit',
        fieldDataType: 'String',
    },
    3: {
        id: 3,
        fieldLabel: 'Budget Upper Limit',
        fieldDataType: 'String',
    },
    4: {
        id: 4,
        fieldLabel: 'Budget Mid Limit',
        fieldDataType: 'String',
    },
    5: {
        id: 5,
        fieldLabel: 'Department',
        fieldDataType: 'Single Selection List',
        fieldListOptionsData: {
            key: 'departmentsList',
            id: 'id',
            value: 'name',
        },
    },
    6: {
        id: 6,
        fieldLabel: 'Location',
        fieldDataType: 'Single Selection List',
        fieldListOptionsData: {
            key: 'locationsList',
            id: 'id',
            value: 'name',
        },
    },
    7: {
        id: 7,
        fieldLabel: 'Position Code',
        fieldDataType: 'String',
    },
    8: {
        id: 8,
        fieldLabel: 'Position Status',
        fieldDataType: 'String',
    },
    9: {
        id: 9,
        fieldLabel: 'Position Start Date',
        fieldDataType: 'Date',
    },
};

export const ViewRecruitmentPolicy = () => {
    const [isLoading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [modifyId, setModifyId] = useState();
    const [action, setAction] = useState('view');

    useEffect(() => {
        action === 'add' && window.scrollTo(0, document.body.scrollHeight);
    }, [action]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('recruitmentPolicyId', params.id);
            const preRuleData = await formDataApi.post(`/preCreateRulesForRecruitmentPolicy`, bodyFormData);
            const prePositionData = await formDataApi.post(`/preCreatePosition`);
            if (prePositionData.data) {
                let definitions = JSON.parse(JSON.stringify(initialFieldDefinitions));
                Object.keys(definitions)?.map((item) => {
                    if (
                        definitions[item].fieldDataType === 'Single Selection List' ||
                        definitions[item].fieldDataType === 'Multiple Selection List'
                    ) {
                        const fieldListOptionsData = definitions[item]['fieldListOptionsData'];
                        const listOptions = prePositionData.data[fieldListOptionsData?.key]?.map((item) => ({
                            key: item[fieldListOptionsData?.id],
                            label: item[fieldListOptionsData?.value],
                        }));
                        definitions[item]['fieldListOptions'] = listOptions || [];
                    }
                });

                if (prePositionData.data?.extendedDef?.metaCustomFieldDefinitions) {
                    prePositionData.data?.extendedDef?.metaCustomFieldDefinitions?.map((item) => {
                        definitions[item.id] = {
                            id: item.id,
                            fieldLabel: item.fieldLabel,
                            fieldDataType: item.fieldDataType,
                            fieldListOptions: item.fieldListOptions,
                        };
                    });
                }

                if (preRuleData?.data) {
                    const attributes = preRuleData?.data?.attributes.map((item) => ({
                        ...item,
                        definitions: definitions[item.id],
                    }));

                    setData({
                        ...preRuleData?.data,
                        attributes,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const handleBack = () => {
        navigate('/recruitmentPolicies');
    };

    const handleRefresh = () => {
        fetchData();
        setAction('view');
    };

    const handleModify = (id) => {
        setAction('modify');
        setModifyId(id);
    };

    return (
        <>
            <Box sx={styles.growBox}>
                <Container>
                    <Box
                        sx={{
                            pl: '8px',
                            pt: '12px !important',
                            pb: '32px',
                        }}
                    >
                        <Box>
                            <Typography variant="h4">Recruitment Policy</Typography>
                        </Box>
                    </Box>
                    <Card
                        sx={{
                            ...styles.dataWrappper,
                            pt: 0,
                            mt: 0,
                        }}
                    >
                        <CardContent>
                            <>
                                <Grid container direction="column" spacing={2}>
                                    <Grid
                                        item
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={styles.growBox} />
                                        <Box>
                                            <RecruitmentPolicyMenu
                                                Component={ActionButton}
                                                page="view"
                                                setRuleAction={setAction}
                                                row={{
                                                    id: params.id,
                                                    ...data,
                                                }}
                                                handleAction={() => navigate('/recruitmentPolicies')}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item marginX={4}>
                                        <Box>
                                            <Typography variant="h5">Recruitment Policy Details</Typography>
                                        </Box>
                                    </Grid>
                                    {isLoading ? (
                                        <Grid item marginX={4}>
                                            <Skeleton height={42} />
                                            <Skeleton height={42} />
                                            <Skeleton height={42} />
                                        </Grid>
                                    ) : (
                                        <Grid item marginX={4}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={4}>
                                                    <Box sx={styles.dataItem}>
                                                        <Typography component={'span'} sx={styles.dataItemLable}>
                                                            Name
                                                        </Typography>
                                                        <Typography component={'span'} sx={styles.dataItemValue}>
                                                            {data?.recruitmentPolicyName}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box sx={styles.dataItem}>
                                                        <Typography component={'span'} sx={styles.dataItemLable}>
                                                            Status
                                                        </Typography>
                                                        <Box>
                                                            <Chip
                                                                label={data?.recruitmentPolicyStatus}
                                                                sx={{
                                                                    backgroundColor: PolicyStatusColor(
                                                                        data?.recruitmentPolicyStatus,
                                                                    ),
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                {data?.attributes?.length > 0 ? (
                                                    data?.rules?.length > 0 || action === 'add' ? (
                                                        data?.rules?.map((item, index) => (
                                                            <ModifyRule
                                                                key={item.id}
                                                                index={index}
                                                                rule={item}
                                                                data={data}
                                                                modifyId={modifyId}
                                                                handleModify={handleModify}
                                                                handleRefresh={handleRefresh}
                                                            />
                                                        ))
                                                    ) : (
                                                        <Box py="20px" textAlign="center">
                                                            No rules found
                                                        </Box>
                                                    )
                                                ) : (
                                                    action !== 'add' && (
                                                        <Box py="20px" textAlign="center">
                                                            No rule attributes found
                                                        </Box>
                                                    )
                                                )}
                                                {action === 'add' && (
                                                    <AddRule
                                                        data={data}
                                                        handleCancel={() => setAction('view')}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                                <Box mt={3} mx={2}>
                                    <AuditLog
                                        data={[
                                            {
                                                title: 'Audit Logs',
                                                logs: data?.commentsMap?.commentsMap || [],
                                                Component: AuditLogTable,
                                            },
                                        ]}
                                    />
                                </Box>
                            </>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};
