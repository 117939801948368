import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography, CardActions, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { styles } from './styles';
import CustomTable from '../../../components/common/CustomTable';
import { columns } from './columns';
import PositionBulkUploadDialog from '../Positions/PositionBulkUploadDialog';
import { updateSelectedPositions, updateAllSelectedPositions } from '../../../store/slices/positions';
import { exceptionColumns } from './exceptionColumns';

const PositionsUploadPreview = (props) => {
    const [loading, setLoading] = useState(false);
    const { positions, exceptionList, selectedPositions } = useSelector((state) => state.bulkUploadPositions);
    const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);

    const dispatch = useDispatch();

    const handleConfirm = async () => {
        setLoading(false);
        try {
            await 20;
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleBulkUploadResult = () => {
        setOpenBulkUploadDialog(false);
    };

    const handleSelect = (event, rowId) => {
        dispatch(
            updateSelectedPositions({
                isChecked: event.target.checked,
                rowId,
            }),
        );
    };

    const handleSelectAll = (event) => {
        dispatch(
            updateAllSelectedPositions({
                isChecked: event.target.checked,
            }),
        );
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        sx={{
                            py: '32px !important',
                        }}
                        item
                        container
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        py={4}
                    >
                        <Grid item>
                            <Typography variant="h4">Positions</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0 !important',
                        }}
                    >
                        <Card>
                            <CardContent>
                                <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                    sx={{
                                        mb: 2,
                                    }}
                                >
                                    Preview
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color="text.secondary"
                                    sx={{
                                        mb: 2,
                                        textDecoration: 'none',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Template-PositionData.xlsx
                                </Typography>
                                {exceptionList.length > 0 && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            mb: 2,
                                            textDecoration: 'none',
                                            color: 'error.main',
                                        }}
                                    >
                                        {exceptionList.length} {exceptionList.length > 1 ? 'errors' : 'error'} found.
                                        Please correct them and upload again.
                                    </Typography>
                                )}
                                {exceptionList.length > 0 ? (
                                    <CustomTable
                                        columns={exceptionColumns}
                                        rows={exceptionList}
                                        showRowSelector={false}
                                        showPagignation={false}
                                    />
                                ) : (
                                    <CustomTable
                                        isLoading={loading}
                                        onSelect={handleSelect}
                                        onSelectAll={handleSelectAll}
                                        columns={columns}
                                        rows={positions.data}
                                        selectedRows={selectedPositions}
                                        showRowSelector={true}
                                        showPagignation={false}
                                        keyColumn="id"
                                    />
                                )}
                            </CardContent>
                            <CardActions
                                sx={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                                    <PositionBulkUploadDialog
                                        openBulkUploadDialog={openBulkUploadDialog}
                                        onResult={handleBulkUploadResult}
                                    />
                                    <Button
                                        variant={exceptionList.length > 0 ? 'contained' : 'text'}
                                        color="primary"
                                        onClick={() => setOpenBulkUploadDialog(true)}
                                    >
                                        upload again
                                    </Button>
                                    {exceptionList.length === 0 && (
                                        <LoadingButton
                                            loading={loading}
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            onClick={handleConfirm}
                                            disabled={selectedPositions.length === 0}
                                        >
                                            Confirm
                                        </LoadingButton>
                                    )}
                                </Stack>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PositionsUploadPreview;
