import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { hasPermission } from '../utils/permission';
import withAuth from './withAuth';
import { useSnackbar } from 'notistack';

/**
 * HOC that allows to check auth user permissions agains the `requiredPermissions` prop.
 * The component will only be rendered if the auth user has the right permissions.
 */
const withAuthPermission = () =>
    withAuth(({ auth, requiredPermissions = [], noAuthRedirect = true, children }) => {
        const { enqueueSnackbar } = useSnackbar();
        const hasAuthPermission = useCallback(
            () => hasPermission(auth.authorities || [], requiredPermissions),
            [auth.authorities, requiredPermissions],
        );

        const renderNoAuthComponent = () => (noAuthRedirect ? <Navigate to="/access-denied" /> : null);

        useEffect(() => {
            if (!hasAuthPermission()) {
                //enqueueSnackbar('You have no permission to access this page', { variant: 'error' });
            }
        }, [hasAuthPermission]);

        if (hasAuthPermission()) {
            return children;
        }

        return renderNoAuthComponent();
    });

withAuthPermission.propTypes = {
    /**
     * Array of required permissions the auth user needs to have to access the component
     */
    requiredPermissions: PropTypes.arrayOf(PropTypes.string),

    /**
     * Redirect the user if unauthorized
     */
    noAuthRedirect: PropTypes.bool,
};

withAuthPermission.defaultProps = {
    requiredPermissions: [],
    noAuthRedirect: true,
};

export default withAuthPermission;
