import React from 'react';
import { Box, Card } from '@mui/material';
import PropTypes from 'prop-types';
import { styles } from './styles';
import FilterButton from '../../../components/common/FilterButton';

export const DepartmentFilter = ({
    disabled,
    onFiltersApply,
    onFiltersClear,
    filterProperties,
    filters,
    initialFilters,
}) => {
    return (
        <>
            <Card sx={styles.filterMenu}>
                <Box sx={styles.filterButtons}>
                    <FilterButton
                        disabled={disabled}
                        onFiltersApply={onFiltersApply}
                        onFiltersClear={onFiltersClear}
                        filterProperties={filterProperties}
                        filters={filters}
                        initialFilters={initialFilters}
                    />
                </Box>
            </Card>
        </>
    );
};

DepartmentFilter.defaultProps = {
    filters: [],
    view: 'all',
    actionItems: <></>,
};

DepartmentFilter.propTypes = {
    disabled: PropTypes.bool,
    filters: PropTypes.array,
    onFiltersApply: PropTypes.func,
    onFiltersClear: PropTypes.func,
    onQueryChange: PropTypes.func,
    onViewChange: PropTypes.func,
    query: PropTypes.string,
    view: PropTypes.string,
    actionItems: PropTypes.element,
};
