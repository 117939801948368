import React from 'react';
import { Button, FormHelperText, Grid, Divider, Stack } from '@mui/material';
import DynamicField from '../../../components/common/InputField/DynamicField';

const DynamicForm = ({ formik, formFields, primaryButton, handlePrimaryButtonClick }) => {
    return (
        <form onSubmit={formik.handleSubmit}>
            <div>
                <Grid container spacing={3}>
                    {formFields?.length ? (
                        formFields.map((field, index) => (
                            <Grid item xs={4} key={index}>
                                <DynamicField field={field} formik={formik} />
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            No custom fields available
                        </Grid>
                    )}
                    {formik.errors.submit && (
                        <Grid item xs={12}>
                            <FormHelperText error>{formik.errors.submit}</FormHelperText>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="end">
                            {/*!!secondaryButton && !isModify && (
                <Button
                  color='primary'
                  size='large'
                  variant='text'
                  onClick={handleSecondaryButtonClick}
                  sx={{
                    mr: 2,
                  }}
                >
                  {secondaryButton}
                </Button>
                )*/}
                            {!!primaryButton && (
                                <Button
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={handlePrimaryButtonClick}
                                    sx={{
                                        ':hover': {
                                            backgroundColor: 'secondary.main',
                                        },
                                    }}
                                >
                                    {primaryButton}
                                </Button>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </form>
    );
};

export default DynamicForm;
