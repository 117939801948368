import React, { useEffect, useState } from 'react';
import {
    Grid,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Skeleton,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { setNestedObjectValues } from 'formik';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import PositionDetailsTab from './PositionDetailsTab';
import DemandAttributes from '../../demandAttributes';
import { api, formDataApi } from '../../../axios';
import { useCustomAttributesFormik, usePersonalDetailsFormik } from './formik';
import { hasPermission } from '../../../utils/permission';

const views = [
    {
        label: 'Position Details',
        value: 'position-details',
    },
    {
        label: 'Custom Attributes',
        value: 'custom-attributes',
    },
];

const AddPosition = (props) => {
    const [view, setView] = useState('position-details');
    const [isLoading, setLoading] = useState(false);
    const [positionDetails, setPositionDetails] = useState({});
    const [preCreationDetails, setPreCreationDetails] = useState({
        isLoading: true,
    });
    const navigate = useNavigate();

    const authorities = useSelector((state) => state.session.user.authorities);
    const userHasCompaCreatePermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_CREATE_POSITION',
    ]);
    const userHasCompaModifyPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_UPDATE_POSITION',
    ]);

    const personalDetailsFormik = usePersonalDetailsFormik(null, (value) => handleBasicDetails(value));
    const { customAttributesFormik, metaCustomFieldDefinitions: metaCustomFields } = useCustomAttributesFormik(
        preCreationDetails?.data?.extendedDef,
        null,
        (value) => handleCustomDetails(value),
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setPreCreationDetails({
            ...preCreationDetails,
            isLoading: true,
        });
        async function fetchPreCreationData() {
            const response = await api.post('/preCreatePosition');
            setPreCreationDetails({
                ...preCreationDetails,
                data: response.data,
                isLoading: false,
            });
        }
        fetchPreCreationData();
    }, []);

    const handleBasicDetails = (details) => {
        setPositionDetails(details);
        setView('custom-attributes');
    };

    const handleCustomDetails = async (details) => {
        if (isLoading) return;
        const { submit, ...filteredData } = {
            ...positionDetails,
        };
        const customAttributesMetaData = isEmpty(metaCustomFields)
            ? []
            : [
                  {
                      attribute: 'customObjectInstance.customerID',
                      value: metaCustomFields.customerID,
                  },
                  {
                      attribute: 'customObjectInstance.baseObjectMetaClassName',
                      value: metaCustomFields.baseObjectMetaClassName,
                  },
                  {
                      attribute: 'customObjectInstance.metaCustomObjectDefinitionUID',
                      value: metaCustomFields.metaCustomObjectDefinitionID,
                  },
              ];

        let index = 0;
        (metaCustomFields?.metaCustomFieldDefinitions || []).forEach((customField) => {
            customAttributesMetaData.push(
                {
                    attribute: `customObjectInstance.customFieldValues[${index}].fieldValue`,
                    value: details[customField.fieldLabel] || '',
                },
                {
                    attribute: `customObjectInstance.customFieldValues[${index}].metaCustomFieldDefinitionID`,
                    value: customField.metaCustomFieldDefinitionID,
                },
            );
            index++;
        });

        const bodyFormData = new FormData();
        for (const key in filteredData) {
            bodyFormData.append(key, filteredData[key]);
        }

        customAttributesMetaData.forEach((customAttribute) => {
            bodyFormData.append(customAttribute.attribute, customAttribute.value);
        });

        try {
            const createPositionApiPath = userHasCompaCreatePermisson
                ? '/createPositionWithCompaFields'
                : '/createPosition';
            setLoading(true);
            const response = await formDataApi.post(createPositionApiPath, bodyFormData);
            setLoading(false);
            if (response.status === 200) {
                enqueueSnackbar('Position created successfully', {
                    variant: 'success',
                });
                navigate('/positions');
            } else {
                enqueueSnackbar(response?.data?.message, {
                    variant: 'error',
                });
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error?.response?.data?.message, {
                variant: 'error',
            });
        }
    };

    const validateTabChange = async (value) => {
        if (value === 'custom-attributes') {
            setPositionDetails({
                ...positionDetails,
                ...personalDetailsFormik.values,
            });
        }
        const tabFormikMap = {
            'position-details': personalDetailsFormik,
            'custom-attributes': customAttributesFormik,
        };

        const errors = await tabFormikMap[view].validateForm();
        if (Object.keys(errors || {}).length === 0) {
            setView(value);
        } else {
            tabFormikMap[view].setTouched(setNestedObjectValues(errors, true));
        }
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Typography variant="h4">Positions</Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0px !important',
                        }}
                    >
                        <Card {...props}>
                            <CardContent>
                                {preCreationDetails.isLoading ? (
                                    <Box
                                        sx={{
                                            py: 4,
                                        }}
                                    >
                                        <Skeleton height={42} />
                                        <Skeleton height={42} />
                                        <Skeleton height={42} />
                                    </Box>
                                ) : (
                                    <>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Button
                                                    startIcon={<ChevronLeftIcon />}
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => navigate('/positions')}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Typography color="textPrimary" variant="h4">
                                                    Create Position
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TabContext value={view}>
                                                    <Box>
                                                        <Tabs
                                                            value={view}
                                                            onChange={(event, value) => validateTabChange(value)}
                                                            aria-label="basic tabs example"
                                                        >
                                                            {views.map((option) => (
                                                                <Tab
                                                                    key={option.label}
                                                                    disabled={false}
                                                                    label={option.label}
                                                                    value={option.value}
                                                                />
                                                            ))}
                                                        </Tabs>
                                                        <Divider sx={styles.divider} />
                                                    </Box>
                                                    <TabPanel sx={styles.tabpanel} value={'position-details'}>
                                                        <PositionDetailsTab
                                                            isCreate
                                                            formik={personalDetailsFormik}
                                                            preCreationDetails={preCreationDetails?.data}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel sx={styles.tabpanel} value={'custom-attributes'}>
                                                        <DemandAttributes
                                                            formik={customAttributesFormik}
                                                            metaCustomFields={metaCustomFields}
                                                        />
                                                    </TabPanel>
                                                </TabContext>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AddPosition;
