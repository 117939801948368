import React, { useEffect, useState } from 'react';
import {
    Grid,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Skeleton,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import FormData from 'form-data';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { setNestedObjectValues } from 'formik';
import { useSelector } from 'react-redux';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import PositionDetailsTab from './PositionDetailsTab';
import DemandAttributes from '../../demandAttributes';
import { formDataApi } from '../../../axios';
import { useCustomAttributesFormik, usePersonalDetailsFormik } from './formik';
import { hasPermission } from '../../../utils/permission';

const views = [
    {
        label: 'Position Details',
        value: 'position-details',
    },
    {
        label: 'Custom Attributes',
        value: 'custom-attributes',
    },
];

const ModifyPosition = (props) => {
    const [view, setView] = useState('position-details');
    const [positionDetails, setPositionDetails] = useState({});
    const [preCreationDetails, setPreCreationDetails] = useState({
        isLoading: true,
    });
    const params = useParams();
    const navigate = useNavigate();

    const authorities = useSelector((state) => state.session.user.authorities);

    const userHasCompaModifyPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_UPDATE_POSITION',
    ]);

    const personalDetailsFormik = usePersonalDetailsFormik(preCreationDetails?.data?.position, (value) =>
        handleBasicDetails(value),
    );

    const { customAttributesFormik, metaCustomFieldDefinitions: metaCustomFields } = useCustomAttributesFormik(
        preCreationDetails?.data?.extendedDef,
        preCreationDetails?.data?.customObjectInstance,
        (value) => handleCustomDetails(value),
    );

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append('id', params.id);
        async function fetchPreCreationData() {
            const preModifyPositionApipath = userHasCompaModifyPermisson
                ? '/preModifyPositionWithCompaFields'
                : '/preModifyPosition';
            setPreCreationDetails({
                ...preCreationDetails,
                isLoading: true,
            });
            const response = await formDataApi.post(preModifyPositionApipath, bodyFormData);
            if (response.status === 200)
                setPreCreationDetails({
                    ...preCreationDetails,
                    data: response.data,
                    isLoading: false,
                });
        }
        fetchPreCreationData();
    }, []);

    const handleBasicDetails = (details) => {
        setPositionDetails(details);
        setView('custom-attributes');
    };

    const handleCustomDetails = async (details) => {
        const { submit, ...filteredData } = {
            ...positionDetails,
        };
        const customAttributesMetaData = [
            {
                attribute: 'customObjectInstance.customerID',
                value: metaCustomFields.customerID,
            },
            {
                attribute: 'customObjectInstance.baseObjectMetaClassName',
                value: metaCustomFields.baseObjectMetaClassName,
            },
            {
                attribute: 'customObjectInstance.metaCustomObjectDefinitionUID',
                value: metaCustomFields.metaCustomObjectDefinitionID,
            },
        ];

        let index = 0;
        (metaCustomFields?.metaCustomFieldDefinitions || []).forEach((customField) => {
            customAttributesMetaData.push(
                {
                    attribute: `customObjectInstance.customFieldValues[${index}].fieldValue`,
                    value: details[customField.fieldLabel] || '',
                },
                {
                    attribute: `customObjectInstance.customFieldValues[${index}].metaCustomFieldDefinitionID`,
                    value: customField.metaCustomFieldDefinitionID,
                },
            );
            index++;
        });

        const bodyFormData = new FormData();
        bodyFormData.append('id', params.id);
        delete filteredData?.['startDate'];
        for (const key in filteredData) {
            bodyFormData.append(key, filteredData[key]);
        }

        customAttributesMetaData.forEach((customAttribute) => {
            bodyFormData.append(customAttribute.attribute, customAttribute.value);
        });

        try {
            const modifyPositionApipath = userHasCompaModifyPermisson
                ? '/modifyPositionWithCompaFields'
                : '/modifyPosition';
            const response = await formDataApi.post(modifyPositionApipath, bodyFormData);
            if (response.status === 200) {
                enqueueSnackbar('Position updated successfully', {
                    variant: 'success',
                });
                navigate('/positions');
            }
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        }
    };

    const validateTabChange = async (value) => {
        const tabFormikMap = {
            'position-details': personalDetailsFormik,
            'custom-attributes': customAttributesFormik,
        };

        const errors = await tabFormikMap[view].validateForm();
        if (Object.keys(errors || {}).length === 0) {
            setView(value);
        } else {
            tabFormikMap[view].setTouched(setNestedObjectValues(errors, true));
        }
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Typography variant="h4">Positions</Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0 !important',
                        }}
                    >
                        <Card {...props}>
                            <CardContent>
                                {preCreationDetails.isLoading ? (
                                    <Box
                                        sx={{
                                            py: 4,
                                        }}
                                    >
                                        <Skeleton height={42} />
                                        <Skeleton height={42} />
                                        <Skeleton height={42} />
                                    </Box>
                                ) : (
                                    <>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Button
                                                    startIcon={<ChevronLeftIcon />}
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => navigate('/positions')}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Typography color="textPrimary" variant="h4">
                                                    {`Modify Position Details - ${preCreationDetails?.data?.position?.positionCode}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <TabContext value={view}>
                                                    <Box>
                                                        <Tabs
                                                            value={view}
                                                            onChange={(event, value) => validateTabChange(value)}
                                                            aria-label="basic tabs example"
                                                        >
                                                            {views.map((option, index) => (
                                                                <Tab
                                                                    disabled={false}
                                                                    label={option.label}
                                                                    value={option.value}
                                                                    key={index}
                                                                />
                                                            ))}
                                                        </Tabs>
                                                        <Divider sx={styles.divider} />
                                                    </Box>
                                                    <TabPanel sx={styles.tabpanel} value={'position-details'}>
                                                        <PositionDetailsTab
                                                            isModify
                                                            preCreationDetails={preCreationDetails?.data}
                                                            formik={personalDetailsFormik}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel sx={styles.tabpanel} value={'custom-attributes'}>
                                                        <DemandAttributes
                                                            isModify
                                                            formik={customAttributesFormik}
                                                            metaCustomFields={metaCustomFields}
                                                        />
                                                    </TabPanel>
                                                </TabContext>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ModifyPosition;
