import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import FormData from 'form-data';
import { useDispatch, useSelector } from 'react-redux';
import OnlinePredictionOutlinedIcon from '@mui/icons-material/OnlinePredictionOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import TodayIcon from '@mui/icons-material/Today';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import ArticleIcon from '@mui/icons-material/Article';
import HandshakeIcon from '@mui/icons-material/Handshake';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { OpenActiveIcon } from '../../../assets/icons/openFolder';
import { endpoints } from '../../../store/constants';
import {
    clearFilters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    resetController,
    setController,
    setFilters,
    setInitialFilters,
} from '../../../store/slices/tableController';
import StatusCard from '../../../components/common/StatusUI/StatusCard';
import { Plus as PlusIcon } from '../../../assets/icons/plus';
import { Upload as UploadIcon } from '../../../assets/icons/upload';
import { styles } from './styles';
import CustomTable from '../../../components/common/CustomTable';
import PositionBulkUploadDialog from './PositionBulkUploadDialog';
import PositionFilter from './PositionFilter';
import { api } from '../../../axios';
import { useColumns } from './columns';
import { updatePositions, updateSelectedPositions, updateAllSelectedPositions } from '../../../store/slices/positions';
import { searchChangeCRPolicesAction } from '../../../store/slices/listCRPolicies';
import PermissionChecker from '../../../components/common/PermissionChecker';

const icons = {
    Draft: ArticleIcon,
    'Open Pending Intake': OpenActiveIcon,
    'Intake Scheduled': TodayIcon,
    'Intake Completed': EventAvailableIcon,
    'Open Active Sourcing': OnlinePredictionOutlinedIcon,
    'On Hold - HM Delay': MoreTimeIcon,
    'Offer Negotiation': RequestQuoteIcon,
    'Offer Released': NextWeekIcon,
    'Offer Accepted': HandshakeIcon,
    Filled: CheckCircleOutlineIcon,
    'On Hold': PauseOutlinedIcon,
    Cancelled: DoNotDisturbAltOutlinedIcon,
};

const PositionsList = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const controller = useSelector((state) => state.tableController);
    const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);
    const { positions, selectedPositions } = useSelector((state) => state.positions);
    const columns = useColumns(positions);

    const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
        setLoading(true);
        try {
            const statusResponse = await api.post(`/statusByPositionCount`);
            if (searched) {
                const query = new URLSearchParams({});
                if (filters.length) {
                    filters.forEach((filter) => query.append(filter.property, filter.value));
                }
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/searchPositions?${query.toString()}`);
                if (response.data) {
                    dispatch(
                        updatePositions({
                            ...response.data,
                            ...statusResponse?.data,
                        }),
                    );
                }
            } else {
                const query = new URLSearchParams({});
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`${endpoints.LIST_POSITIONS}?${query.toString()}`);
                if (response.data) {
                    dispatch(
                        updatePositions({
                            ...response.data,
                            ...statusResponse?.data,
                        }),
                    );
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (fetchData)
            fetchData(
                controller.searched,
                controller.filters,
                controller.sort,
                controller.sortBy,
                controller.page,
                controller.pageSize,
            );
    }, [
        controller.searched,
        controller.filters,
        controller.sort,
        controller.sortBy,
        controller.page,
        controller.pageSize,
        fetchData,
    ]);

    useEffect(() => {
        dispatch(
            setInitialFilters([
                {
                    property: {
                        name: 'c',
                        type: 'string',
                    },
                    operator: {
                        value: '',
                        typesAccepted: [],
                    },
                    value: '',
                },
            ]),
        );
    }, []);

    useEffect(() => {
        const data = new FormData();
        data.append('s', 'Active');
        data.append('all', true);
        dispatch(searchChangeCRPolicesAction(data));
    }, [dispatch]);

    const handleFiltersApply = (newFilters) => {
        const parsedFilters = newFilters.map((filter) => ({
            property: filter.property.name,
            value: filter.value,
            operator: filter.operator.value,
        }));

        dispatch(setFilters(parsedFilters));
    };

    const handleFiltersClear = () => {
        dispatch(clearFilters());
    };

    const handlePageChange = (newPage) => {
        dispatch(onPageChange(newPage - 1));
    };

    const handlePageSizeChange = (newPageSize) => {
        dispatch(onPageSizeChange(newPageSize));
    };

    const handleSortChange = (event, property) => {
        const isAsc = controller.sortBy === property && controller.sort === 'asc';
        dispatch(
            onSortChange({
                sort: isAsc ? 'desc' : 'asc',
                sortBy: property,
            }),
        );
    };

    const handleRowClick = (id) => {
        navigate(`/positions/${id}`);
    };

    const handleBulkUploadResult = (result) => {
        setOpenBulkUploadDialog(false);
        result &&
            fetchData(
                controller.searched,
                controller.filters,
                controller.sort,
                controller.sortBy,
                controller.page,
                controller.pageSize,
            );
    };

    const handleSelect = (event, rowId) => {
        dispatch(
            updateSelectedPositions({
                isChecked: event.target.checked,
                rowId,
            }),
        );
    };

    const handleSelectAll = (event) => {
        dispatch(
            updateAllSelectedPositions({
                isChecked: event.target.checked,
            }),
        );
    };

    const handleStatusFilter = (value) => {
        if (!value)
            return dispatch(
                resetController({
                    initialFilters: [
                        {
                            property: {
                                name: 'c',
                                type: 'string',
                            },
                            operator: {
                                value: '',
                                typesAccepted: [],
                            },
                            value: '',
                        },
                    ],
                }),
            );
        else
            dispatch(
                setController({
                    page: 0,
                    filters: [
                        {
                            operator: '',
                            property: 's',
                            value: value,
                        },
                    ],
                    initialFilters: [
                        {
                            property: {
                                name: 's',
                                type: 'string',
                            },
                            operator: {
                                value: '',
                                typesAccepted: [],
                            },
                            value: value,
                        },
                    ],
                    searched: true,
                }),
            );
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        container
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Grid item>
                            <Typography variant="h4">Positions</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        <PermissionChecker
                            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_IMPORT_POSITIONS_DATA']}
                            noAuthRedirect={false}
                        >
                            <Grid item xs="auto">
                                <Button
                                    sx={styles.mx(2)}
                                    color="primary"
                                    onClick={() => setOpenBulkUploadDialog(true)}
                                    size="large"
                                    startIcon={<UploadIcon fontSize="small" />}
                                    variant="outlined"
                                >
                                    Bulk Upload
                                </Button>
                                <PositionBulkUploadDialog
                                    openBulkUploadDialog={openBulkUploadDialog}
                                    onResult={handleBulkUploadResult}
                                />
                            </Grid>
                        </PermissionChecker>
                        <Grid item xs="auto">
                            <PermissionChecker
                                requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_POSITION']}
                                noAuthRedirect={false}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => navigate('/positions/add')}
                                    size="large"
                                    startIcon={<PlusIcon fontSize="small" />}
                                    variant="contained"
                                    sx={{
                                        ':hover': {
                                            backgroundColor: 'secondary.main',
                                        },
                                    }}
                                >
                                    Add Position
                                </Button>
                            </PermissionChecker>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            pt: '0 !important',
                        }}
                        item
                        container
                        spacing={3}
                        justifyContent="flex-start"
                        alignItems="stretch"
                        columns={18}
                    >
                        {(positions?.statusList || []).map((item) => {
                            const filteredStatus = controller.filters?.find((item) => item.property === 's')?.value;
                            return (
                                <Grid item xs={12} sm={3} md={3}>
                                    <StatusCard
                                        Icon={icons[item]}
                                        label={item}
                                        filteredStatus={filteredStatus}
                                        value={positions.states[item]}
                                        handleStatusFilter={handleStatusFilter}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Positions: {positions.count}
                                </Typography>
                                <PositionFilter
                                    filters={controller.filters}
                                    initialFilters={controller.initialFilters}
                                    onFiltersApply={handleFiltersApply}
                                    onFiltersClear={handleFiltersClear}
                                    selectedPositions={selectedPositions}
                                    filterProperties={columns}
                                    action={() =>
                                        fetchData(
                                            controller.searched,
                                            controller.filters,
                                            controller.sort,
                                            controller.sortBy,
                                            controller.page,
                                            controller.pageSize,
                                        )
                                    }
                                />
                                <CustomTable
                                    isLoading={loading}
                                    onPageChange={handlePageChange}
                                    onPageSizeChange={handlePageSizeChange}
                                    onSelect={handleSelect}
                                    onSelectAll={handleSelectAll}
                                    onRowClick={handleRowClick}
                                    onSortChange={handleSortChange}
                                    page={controller.page + 1}
                                    columns={columns}
                                    rows={positions.data}
                                    rowsCount={positions.count}
                                    selectedRows={selectedPositions}
                                    sort={controller.sort}
                                    sortBy={controller.sortBy}
                                    showRowSelector={true}
                                    showPagignation={true}
                                    keyColumn="id"
                                    action={() =>
                                        fetchData(
                                            controller.searched,
                                            controller.filters,
                                            controller.sort,
                                            controller.sortBy,
                                            controller.page,
                                            controller.pageSize,
                                        )
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PositionsList;
