import React, { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Card, CardContent, Container, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import CompanyTab from './companyTab';
import { formDataApi } from '../../../axios';

const AddCompany = (props) => {
    const [view] = useState('user-details');
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [companyDetails, setCompanyDetails] = useState({
        isLoading: true,
    });

    useEffect(() => {
        if (id && isEditMode) {
            setCompanyDetails({
                ...companyDetails,
                isLoading: true,
            });
            async function fetchData() {
                const bodyFormData = new FormData();
                bodyFormData.append('id', id);
                try {
                    const response = await formDataApi.post('/displayCompany', bodyFormData);
                    if (response?.data) {
                        setCompanyDetails(response?.data);
                    }
                } catch (err) {
                    setCompanyDetails({
                        isLoading: false,
                    });
                    toast.error('Something went wrong! Customer could not be fetched.');
                }
            }
            fetchData();
        }
    }, [id, isEditMode]);

    useEffect(() => {
        if (id && location.pathname.includes('/edit')) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [id, location.pathname]);
    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h4">{isEditMode ? 'Edit Customer' : 'Customer onboarding'}</Typography>
                    </Grid>
                    <Grid item>
                        <Card {...props}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={() => navigate('/company')}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textPrimary" variant="h4">
                                            {!!isEditMode && 'Edit'} Company details
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TabContext value={view}>
                                        <TabPanel sx={styles.tabpanel} value={'user-details'}>
                                            <CompanyTab
                                                isEditMode={isEditMode}
                                                address={companyDetails?.address}
                                                addressId={companyDetails?.company?.hoAddressID}
                                                company={companyDetails?.company?.customer}
                                                companyId={companyDetails?.company?.id}
                                                customerId={companyDetails?.company?.customer?.id}
                                            />
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AddCompany;
