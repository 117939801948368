import React from 'react';
import ReactDOM from 'react-dom';
import { LinearProgress } from '@mui/material';

const Loader = () => {
    return ReactDOM.createPortal(
        <React.Fragment>
            <div style={styles.overlay} />
            <div style={styles.container}>
                <LinearProgress />
            </div>
        </React.Fragment>,
        document.getElementById('root-loader'),
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.005)',
        zIndex: 2099,
        pointerEvents: 'all',
    },
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 2100,
    },
};

export default Loader;
