import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';
const uid = uuid();

export const useStyles = makeStyles(
    (theme) => ({
        dialogTitle: {
            alignItems: 'center',
            textAlign: 'center',
        },
        typography: {
            ml: 2,
        },
    }),

    {
        classNamePrefix: 'confirmation-dialog',
        generateId: uid,
        index: uid,
        name: 'confirmation-dialog',
    },
);
