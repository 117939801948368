import React from 'react';
import { Box } from '@mui/material';
import { useSettings } from '../../../contexts/SettingContexts';
import PageNavbar from '../PageNavbar';
import PageSidebar from '../PageSidebar';
import { BoxSx, PageLayoutRoot } from './styles';

const PageLayout = ({ children, menu }) => {
    const { settings, saveSettings } = useSettings();

    const handlePinSidebar = () => {
        saveSettings({
            ...settings,
            pinSidebar: !settings.pinSidebar,
        });
    };

    return (
        <>
            <PageNavbar />
            <PageSidebar menu={menu} onPin={handlePinSidebar} pinned={settings.pinSidebar} />
            <PageLayoutRoot sx={PageLayoutRoot.sx(settings)}>
                <Box sx={BoxSx}>{children}</Box>
            </PageLayoutRoot>
        </>
    );
};

export default PageLayout;
