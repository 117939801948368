import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formDataApi } from '../../../axios';

export const RoleMenu = ({ Component, row, handleAction }) => {
    const [actionType, setActionType] = useState();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = async () => {
        setActionType();
        const bodyFormData = new FormData();
        bodyFormData.append('id', row.id);
        try {
            const response = await formDataApi.post('/deleteRole', bodyFormData);
            if (response.data?.status === 'SUCCESS') {
                enqueueSnackbar('Role deleted successfuly', {
                    variant: 'success',
                });
                handleAction();
            }
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: 'error',
            });
        }
    };

    const dialogContent = useMemo(() => {
        if (actionType === 'delete')
            return {
                title: 'Delete Role',
                confirmation: `Are you sure you want to delete this Role?`,
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    </React.Fragment>
                ),
            };
    }, [actionType]);

    const actionItems = [
        {
            label: 'Modify',
            type: 'modify_role',
            icon: <EditIcon />,
            onClick: () => navigate(`/settings/roles/edit/${row.id}`),
            permissions: ['ROLE_BASE_PERMISSION_SECROLE_UPDATE'],
        },
        // {
        //   label: 'Delete',
        //   type: 'delete',
        //   icon: <Delete />,
        //   onClick: (action) => setActionType(action.type),
        //   permissions: ['ROLE_BASE_PERMISSION_SECROLE_DELETE'],
        // },
    ];

    return <Component actions={actionItems} dialog={dialogContent} />;
};
