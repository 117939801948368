export const styles = {
    growBox: {
        flexGrow: 1,
    },
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    tableMenuDialog: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
};
