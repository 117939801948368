import React from 'react';
import { Typography } from '@mui/material';
import ActionTableButton from '../../../components/common/ActionButtonAndMenu/ActionTableButton';
import { RoleMenu } from './RoleMenu';

export const columns = [
    {
        fieldName: 'displayName',
        name: 'c',
        label: 'NAME',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'grantedPermissionCount',
        name: 'p',
        label: 'Granted Permission',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.[column.fieldName]}
            </Typography>
        ),
    },
    {
        id: 'action',
        label: 'Actions',
        sticky: true,
        filter: true,
        formatValue: ({ row, action }) => <RoleMenu Component={ActionTableButton} row={row} handleAction={action} />,
    },
];
