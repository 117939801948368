import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    containsOperator,
    endsWithOperator,
    equalOperator,
    greaterThanOperator,
    isBeforeOperator,
    isBlankOperator,
    isAfterOperator,
    isPresentOperator,
    lessThanOperator,
    notContainsOperator,
    notEqualOperator,
    startsWithOperator,
} from '../../utils/filter-operators';
import { ChangeRequestBulkActionMenu } from './ChangeRequestBulkActionMenu';
import { styles } from './styles';
import ActionButton from '../../components/common/ActionButtonAndMenu/ActionButton';
import FilterDialog from '../../components/common/FilterButton/FilterDialog';

const filterOperators = [
    equalOperator,
    notEqualOperator,
    containsOperator,
    notContainsOperator,
    startsWithOperator,
    endsWithOperator,
    greaterThanOperator,
    lessThanOperator,
    isAfterOperator,
    isBeforeOperator,
    isBlankOperator,
    isPresentOperator,
];

export const ChangeRequestFilter = (props) => {
    const { filters, onFiltersApply, onFiltersClear, selectedChangeRequests, filterProperties, initialFilters, tab } =
        props;

    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    return (
        <>
            <Box sx={styles.searchFilterContainer}>
                {/* TODO : Uncomment later */}
                {/* <Query
          disabled={false}
          onChange={onQueryChange}
          sx={styles.searchInput}
          value={query}
          searchChangeAction={searchChangeRequestAction}
        /> */}
                <Box sx={{ flexGrow: 1, order: 2 }} />
                {/* <Button
          disabled={false}
          onClick={() => console.log(true)}
          size="large"
          sx={styles.exportButton}
          variant="text"
          startIcon={<ExportIcon fontSize="small" />}
        >
          Export
        </Button> */}
                <Button
                    color="primary"
                    disabled={false}
                    onClick={() => setOpenFilterDialog(true)}
                    startIcon={<FilterAltIcon />}
                    size="large"
                    sx={styles.filterButton}
                    variant={filters.length ? 'contained' : 'text'}
                >
                    Filter
                </Button>
                {selectedChangeRequests.length > 0 && (
                    <ChangeRequestBulkActionMenu
                        Component={ActionButton}
                        tab={tab}
                        label={'Bulk Actions'}
                        selectedCount={selectedChangeRequests.length}
                    />
                )}
                <FilterDialog
                    enableAddButton={false}
                    onApply={onFiltersApply}
                    onClear={onFiltersClear}
                    onClose={() => setOpenFilterDialog(false)}
                    open={openFilterDialog}
                    operators={filterOperators}
                    properties={filterProperties}
                    initialFilters={initialFilters}
                />
            </Box>
        </>
    );
};

ChangeRequestFilter.defaultProps = {
    filters: [],
    selectedChangeRequests: [],
    view: 'all',
    actionItems: <></>,
};

ChangeRequestFilter.propTypes = {
    disabled: PropTypes.bool,
    filters: PropTypes.array,
    onFiltersApply: PropTypes.func,
    onFiltersClear: PropTypes.func,
    onViewChange: PropTypes.func,
    selectedChangeRequests: PropTypes.array,
    view: PropTypes.string,
    actionItems: PropTypes.element,
};
