import React from 'react';
import { Box, Button, Card } from '@mui/material';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { styles } from './styles';
import PermissionChecker from '../../../components/common/PermissionChecker';

export const CRPoliciesFilter = ({
    disabled,
    flters,
    onFiltersApply,
    onFiltersClear,
    selectedPositions,
    filterProperties,
    viewMappedPositionsShow,
    updatePolicyMappings,
    handelBulkUpdate,
}) => {
    return (
        <>
            <Card sx={styles.filterMenu}>
                <Box sx={styles.filterButtons}>
                    {/*<PermissionChecker
            requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SEARCH_CHANGE_REQUEST_POLICIES']}
            noAuthRedirect={false}
          >
            <FilterButton
              disabled={disabled}
              onFiltersApply={onFiltersApply}
              onFiltersClear={onFiltersClear}
              filterProperties={filterProperties}
            />
          </PermissionChecker>*/}
                    {viewMappedPositionsShow ? (
                        <PermissionChecker
                            requiredPermissions={[
                                'ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY',
                            ]}
                            noAuthRedirect={false}
                        >
                            <Button
                                sx={styles.viewMappedButton}
                                size="large"
                                variant="contained"
                                onClick={handelBulkUpdate}
                                startIcon={!updatePolicyMappings ? <EditIcon /> : <CheckIcon />}
                                disabled={!selectedPositions.length && updatePolicyMappings}
                            >
                                {!updatePolicyMappings ? ' Update Policy Mappings' : 'Save Mappings'}
                            </Button>
                        </PermissionChecker>
                    ) : (
                        <></>
                    )}
                </Box>
            </Card>
        </>
    );
};

CRPoliciesFilter.defaultProps = {
    filters: [],
    selectedPositions: [],
    view: 'all',
    actionItems: <></>,
};

CRPoliciesFilter.propTypes = {
    disabled: PropTypes.bool,
    filters: PropTypes.array,
    onFiltersApply: PropTypes.func,
    onFiltersClear: PropTypes.func,
    onViewChange: PropTypes.func,
    selectedPositions: PropTypes.array,
    view: PropTypes.string,
    actionItems: PropTypes.element,
};
