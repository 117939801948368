export const equalOperator = {
    label: 'equal',
    typesAccepted: ['number', 'string'],
    value: 'equal',
};

export const notEqualOperator = {
    label: 'not equal',
    typesAccepted: ['number', 'string'],
    value: 'notEqual',
};

export const containsOperator = {
    label: 'contains',
    typesAccepted: ['string'],
    value: 'contains',
};

export const notContainsOperator = {
    label: 'not contains',
    typesAccepted: ['string'],
    value: 'notContains',
};

export const startsWithOperator = {
    label: 'starts with',
    typesAccepted: ['string'],
    value: 'startsWith',
};

export const endsWithOperator = {
    label: 'ends with',
    typesAccepted: ['string'],
    value: 'endsWith',
};

export const greaterThanOperator = {
    label: 'greater than',
    typesAccepted: ['number'],
    value: 'greaterThan',
};

export const lessThanOperator = {
    label: 'less than',
    typesAccepted: ['number'],
    value: 'lessThan',
};

export const isAfterOperator = {
    label: 'is after',
    typesAccepted: ['date'],
    value: 'isAfter',
};

export const isBeforeOperator = {
    label: 'is before',
    typesAccepted: ['date'],
    value: 'isBefore',
};

export const isBlankOperator = {
    label: 'is blank',
    typesAccepted: ['blank'],
    value: 'isBlank',
};

export const isPresentOperator = {
    label: 'is present',
    typesAccepted: ['blank'],
    value: 'isPresent',
};
