import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { crPoliciesInitialState } from '../state';
import { api, formDataApi } from '../../axios';
import { endpoints } from '../constants';

export const getCRPolicies = createAsyncThunk('getCRPolicies', (data = {}, { rejectWithValue }) =>
    api
        .post(`${endpoints.LIST_CHANGE_REQUEST_POLICIES}?${data.query.toString()}`)
        .then((res) => {
            if (res?.data?.status !== 'SUCCESS') {
                return rejectWithValue(res.data);
            }
            return res.data;
        })
        .catch((error) => rejectWithValue(error)),
);

export const preCreateChangeRequestPolicy = createAsyncThunk(
    'preCreateChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        api
            .post(endpoints.PRE_CREATE_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                if (res.status !== 200 || res?.data?.status === 'ERROR') {
                    return rejectWithValue(res.data);
                }

                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const createChangeRequestPolicy = createAsyncThunk(
    'createChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.CREATE_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                if (res?.data?.status !== 'SUCCESS') {
                    return rejectWithValue(res.data);
                }
                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const preModifyChangeRequestPolicy = createAsyncThunk(
    'preModifyChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.PRE_MODIFY_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                if (res.status !== 200) {
                    return rejectWithValue(res.data);
                }

                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const modifyChangeRequestPolicy = createAsyncThunk(
    'modifyChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.MODIFY_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                if (res?.data?.status !== 'SUCCESS') {
                    return rejectWithValue(res.data);
                }
                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const searchChangeCRPolicesAction = createAsyncThunk(
    'searchChangeCRPolicesAction',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.SEARCH_CHANGE_REQUEST_POLICIES_ACTION, data)
            .then((res) => {
                if (res?.data?.status !== 'SUCCESS') {
                    return rejectWithValue(res.data);
                }
                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const getPositionsMappedToChangeRequestPolicy = createAsyncThunk(
    'getPositionsMappedToChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.GET_POSITIONS_MAPPED_TO_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                if (res?.data?.status !== 'SUCCESS') {
                    return rejectWithValue(res.data);
                }
                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const deleteChangeRequestPolicy = createAsyncThunk(
    'deleteChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.DELETE_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const bulkUpdatePositionChangeRequestPolicyMappings = createAsyncThunk(
    'bulkUpdatePositionChangeRequestPolicyMappings',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.BULK_UPDATE_POSITION_CHANGE_REQUEST_POLICY_MAPPINGS, data)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                return error.data;
            }),
);

export const displayChangeRequestPolicy = createAsyncThunk(
    'displayChangeRequestPolicy',
    (data = {}, { rejectWithValue }) =>
        formDataApi
            .post(endpoints.DISPLAY_CHANGE_REQUEST_POLICY, data)
            .then((res) => {
                if (res.status !== 200) {
                    return rejectWithValue(res.data);
                }
                return res.data;
            })
            .catch((error) => rejectWithValue(error)),
);

export const getPositionList = createAsyncThunk('getPositionList', (data = {}, { rejectWithValue }) =>
    api
        .post(`${endpoints.LIST_POSITIONS}?${data.query.toString()}`)
        .then((res) => {
            if (res.status !== 200) {
                return rejectWithValue(res.data);
            }
            return res.data;
        })
        .catch((error) => rejectWithValue(error)),
);

const listCRPolicies = createSlice({
    name: 'crPolicies',
    initialState: crPoliciesInitialState,
    extraReducers: {
        [getCRPolicies.pending]: (state) => {
            state.isLoading = true;
        },
        [getCRPolicies.rejected]: (state) => {
            state.hasErrored = true;
        },
        [getCRPolicies.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.createCRPolicy = {};
            state.crPolicies = action.payload.changeRequestPolicies;
            state.deleteCRPolicy = {};
            state.changeRequestPolicyCount = action.payload.changeRequestPolicyCount;
        },
        [preCreateChangeRequestPolicy.fulfilled]: (state, action) => {
            state.availableStatuses = action.payload.availableStatuses;
            state.majorApprovers = action.payload.majorApprovers;
            state.minorApprovers = action.payload.minorApprovers;
        },
        [createChangeRequestPolicy.fulfilled]: (state, action) => {
            state.createCRPolicy = action.payload;
        },
        [modifyChangeRequestPolicy.fulfilled]: (state, action) => {
            state.createCRPolicy = action.payload;
        },
        [deleteChangeRequestPolicy.fulfilled]: (state, action) => {
            state.deleteCRPolicy = action.payload;
        },
        [searchChangeCRPolicesAction.pending]: (state) => {
            state.isLoading = true;
        },
        [searchChangeCRPolicesAction.rejected]: (state) => {
            state.hasErrored = true;
        },
        [searchChangeCRPolicesAction.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.crPolicies = action.payload.changeRequestPolicies;
        },
        [getPositionsMappedToChangeRequestPolicy.pending]: (state) => {
            state.positionsMapped.isLoading = true;
        },
        [getPositionsMappedToChangeRequestPolicy.rejected]: (state) => {
            state.positionsMapped.hasErrored = true;
        },
        [getPositionsMappedToChangeRequestPolicy.fulfilled]: (state, action) => {
            state.positionsMapped.isLoading = false;
            state.positionsMapped.mapped = action.payload.mappedPositions;
            state.positionsMapped.count = action.payload.totalCount;
            state.isLoading = false;
        },
        [getPositionList.pending]: (state) => {
            state.isLoading = true;
        },
        [getPositionList.rejected]: (state) => {
            state.hasErrored = true;
        },
        [getPositionList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.listPositions = action.payload.positionInstanceList;
            state.changeRequestPolicyCount = action.payload.positionInstanceCount;
        },
        [displayChangeRequestPolicy.pending]: (state) => {
            state.viewPolicy.isLoading = true;
        },
        [displayChangeRequestPolicy.rejected]: (state) => {
            state.viewPolicy.hasErrored = true;
        },
        [displayChangeRequestPolicy.fulfilled]: (state, action) => {
            state.viewPolicy.isLoading = false;
            state.viewPolicy.positionChangeRequestPolicy = action.payload.positionChangeRequestPolicy;
            state.viewPolicy.commentsMap = action.payload.commentsMap;
        },
        [preModifyChangeRequestPolicy.pending]: (state) => {
            state.modifyPolicy.isLoading = true;
        },
        [preModifyChangeRequestPolicy.fulfilled]: (state, action) => {
            state.modifyPolicy.isLoading = false;
            state.modifyPolicy.positionChangeRequestPolicy = action.payload.changeRequestPolicy;
            state.modifyPolicy.approvers = action.payload.changeRequestPolicy?.approvers;
            state.availableStatuses = action.payload.availableStatuses;
            state.majorApprovers = action.payload.majorApprovers;
            state.minorApprovers = action.payload.minorApprovers;
        },
    },
});

export default listCRPolicies.reducer;
