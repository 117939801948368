import React from 'react';
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomDrawer = styled(Drawer)(({ theme }) => ({
    zIndex: 1000,
    '& .MuiPaper-root': {
        top: 64,
        height: 'calc(100% - 64px)',
        overflowX: 'hidden',
        '& .simplebar-content': {
            height: '100%',
        },
        '&:hover': {
            '& span, p': {
                display: 'flex',
            },
        },
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.neutral[50] : theme.palette.neutral[900],
    },
}));

const PageSidebar = (props) => {
    const { menu, pinned } = props;
    return <>{pinned && <CustomDrawer variant="permanent">{menu}</CustomDrawer>}</>;
};

export default PageSidebar;
