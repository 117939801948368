import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import CustomTable from '../../../../components/common/CustomTable';
import TextField from '../../../../components/common/InputField/TextField';
import { DatePicker } from '../../../../components/common/Datepicker';
import { api } from '../../../../axios';

const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

const columns = [
    {
        fieldName: 'positionCode',
        name: 'c',
        label: 'POSITION CODE',
        type: 'number',
        title: ({ row, column }) => row[column.fieldName],
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'designation',
        name: 'de_name',
        label: 'DESIGNATION',
        type: 'string',
        title: ({ row, column }) => row[column.fieldName]?.name,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.name}
            </Typography>
        ),
    },
    {
        fieldName: 'department',
        name: 'dp_name',
        label: 'DEPARTMENT',
        type: 'string',
        title: ({ row, column }) => row[column.fieldName]?.name,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.name}
            </Typography>
        ),
    },
];

const newColumns = [
    {
        fieldName: 'intakeUser',
        name: 'intakeUser',
        label: 'Intake User',
        type: 'string',
        title: ({ row, column }) => row[column.fieldName],
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.fullName}
            </Typography>
        ),
    },
    {
        fieldName: 'scheduleIntakeDate',
        name: 'scheduleIntakeDate',
        label: 'Scheduled Intake Date',
        type: 'string',
        title: ({ row, column }) => {
            return row[column.fieldName] ? moment(row[column.fieldName]).format('MMM DD yyyy') : '';
        },
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName] ? moment(row[column.fieldName]).format('MMM DD yyyy') : ''}
            </Typography>
        ),
    },
];

const IntakeDialogContent = (props) => {
    const { actionType, type, data, handleCancel, handleSubmit } = props;
    const [users, setUsers] = useState([]);
    const params = useParams();
    const { selectedPositions } = useSelector((state) => state.positions);

    const formik = useFormik({
        initialValues: {
            intakeUserId: '',
            date: '',
            comment: '',
        },
        validationSchema:
            actionType === 'Complete'
                ? undefined
                : Yup.object().shape({
                      intakeUserId: Yup.string().max(512).required('Username is required'),
                      date: Yup.date()
                          .nullable()
                          .required('Date is required')
                          .min(currentDate, 'Date cannot be in the Past'),
                  }),
        onSubmit: async (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        if (data.length > 1) return;
        formik.setValues({
            intakeUserId: data[0].intakeUserId,
            date: data[0].scheduleIntakeDate && moment(data[0].scheduleIntakeDate).format('yyyy-MM-DD'),
        });
    }, [data]);

    const getUsers = async () => {
        try {
            const query = new URLSearchParams({});
            if (params.companyId) query.append('company', params.companyId);
            query.append('sort', 'firstName');
            query.append('order', 'asc');
            query.append('offset', 0);
            query.append('max', 100);
            const response = await api.post(`/listUsers?${query.toString()}`);
            if (response.data) setUsers(response.data.employeeInstanceList);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const rowData = useMemo(() => {
        return type === 'bulk' ? data.filter((item) => selectedPositions.indexOf(item.id) >= 0) : data;
    }, [data, type, selectedPositions]);

    return (
        <Box>
            <CustomTable
                showPagignation={false}
                isLoading={false}
                columns={actionType === 'Schedule' ? columns : [...columns, ...newColumns]}
                rows={rowData}
                keyColumn="id"
                route="positions"
                sx={{
                    tableContainer: {
                        maxHeight: actionType !== 'Complete' ? window.innerHeight - 500 : window.innerHeight - 330,
                        overflowY: 'auto',
                    },
                    tableHead: {
                        position: 'sticky',
                        top: 0,
                        backgroundColor: `rgb(249, 250, 251)`,
                        borderBottom: `1px solid rgb(225, 227, 234)`,
                        zIndex: 2,
                    },
                }}
            />
            {actionType !== 'Complete' && (
                <Grid container>
                    <Grid item xs={12} pt={5}>
                        <Divider />
                    </Grid>
                    <Grid pt={5} container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                error={Boolean(formik.touched.intakeUserId && formik.errors.intakeUserId)}
                                fullWidth
                                helperText={formik.touched.intakeUserId && formik.errors.intakeUserId}
                                label="User Name"
                                name="intakeUserId"
                                select
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.intakeUserId}
                            >
                                {users.map((user) => {
                                    const userRoles = user.roles?.[0] || {};
                                    const roles = Object.keys(userRoles).filter((role) => userRoles[role]);
                                    return (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.firstName} {user.lastName}
                                            {roles.length > 0 && ` (${roles.join(',')})`}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                error={Boolean(formik.touched.date && formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                                label="Date"
                                name="date"
                                minDate={moment()}
                                fullWidth
                                onChange={formik.setFieldValue}
                                onBlur={formik.handleBlur}
                                value={formik.values.date}
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={3}>
                        <TextField
                            size="small"
                            name="comment"
                            label="Comment"
                            placeholder="Type here..."
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container pt={5}>
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'right',
                    }}
                >
                    <Button variant="text" color="primary" onClick={handleCancel} sx={{ mr: '20px' }}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={formik.handleSubmit}>
                        {actionType} Intake
                    </Button>
                </Box>
            </Grid>
        </Box>
    );
};

export default IntakeDialogContent;
