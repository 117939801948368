import React from 'react';
import { Typography } from '@mui/material';
import numeral from 'numeral';

export const exceptionColumns = [
    {
        fieldName: 'sn',
        name: 'sn',
        label: 'SN',
        type: 'number',
        formatValue: ({ rowIndex }) => (
            <Typography variant="body2" noWrap>
                {numeral(rowIndex + 1).format('00')}
            </Typography>
        ),
    },
    {
        fieldName: 'positionName',
        name: 'positionName',
        label: 'POSITION NAME',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'error',
        name: 'error',
        label: 'ERRORS',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row}
            </Typography>
        ),
    },
];
