export const styles = {
    growBox: {
        flexGrow: 1,
    },
    dataWrappper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        boxShadow: '0 0 9px #9c9c9c61',
        background: '#fff',
        pt: '20px',
        mt: '20px',
    },
    addTable: {
        width: ' 100%',
        p: '30px 60px',
        height: '75vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    backButton: {
        width: '100px',
        color: '#3F455A',
    },
    addTableTitle: {
        color: '#3F455A',
    },
    basicInformation: {
        width: '100%',
        mt: '24px',
    },
    basicInformationText: {
        color: '#9AA1B7',
    },
    display: 'flex',
    inputBox: {
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: '20px',
    },
    inputs: {
        width: '400px',
        m: '7px 0',
    },
    removeButtonBox: {
        width: '400px',
    },
    removeButton: {
        color: '#7C85A2',
    },
    bottomBLine: {
        width: '100%',
        height: '1px',
        background: '#C1C4CC',
        mt: '30px',
    },
    inputsAdditionalPreOfferBox: {
        flexWrap: 'wrap',
        pt: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputsAdditionalPreOffer: {
        width: '400px',
        m: '5px 0',
    },
    additionalPreOfferButton: {
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: '20px',
    },
    createButtonBox: {
        width: '100%',
        pt: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
        mt: '5px',
    },
    createButton: {
        mt: '10px',
    },
    flexItem1: (isBulkModify) => ({
        flexBasis: isBulkModify ? '50%' : '33.33%',
        height: '56px',
        background: '#E1E3EA',
        boxShadow: 'inset 0px -1px 0px #CBCBCB',
        padding: '12px 24px 12px 16px',
        color: 'primary',
        minWidth: isBulkModify ? '50%' : '33.33%',
    }),
    flexItem2: {
        flexBasis: '33.33%',
        background: '#F3F4F7',
        boxShadow: 'inset 0px -1px 0px #E1E3EA',
        height: '56px',
        padding: '12px 24px 12px 16px',
        color: 'primary',
        minWidth: '33.33%',
    },
    flexItem3: (isBulkModify) => ({
        flexBasis: isBulkModify ? '50%' : '33.33%',
        height: '56px',
        maxWidth: isBulkModify ? '50%' : '33.33%',
        padding: '12px 24px 12px 16px',
        '& fieldset, label': {
            border: 'none !important',
        },
        'label, input[type="file"]': {
            cursor: 'pointer',
        },
        '& .fileLabel': {
            top: '4px !important',
        },
        '& .fileName': {
            transform: 'translate(-25px, 9px) scale(0.8)',
            transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        },
    }),
    flexItem3Title: {
        flexBasis: '50%',
    },
    textBlock: {
        alignItems: 'center',
        display: 'table-cell ',
    },
    text: {
        display: 'inline-block',
        mr: 0.5,
    },
};
