import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Typography,
} from '@mui/material';
import { styles } from './styles';

const ActionMenu = (props) => {
    const { filteredActions, dialog, anchorRef, open, handleClose, onDialogClose, style } = props;
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setOpenDialog(dialog ? true : false);
    }, [dialog]);

    return (
        <React.Fragment>
            <Menu
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open ? open : false}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuList dense>
                    {filteredActions?.map((item) => (
                        <MenuItem
                            key={item.label}
                            onClick={() => {
                                if (item.onClick) item.onClick(item);
                                handleClose();
                            }}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText variant="body2">{item.label}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
            <Dialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(false);
                    onDialogClose();
                }}
                maxWidth="sm"
                fullWidth
                {...style?.dialog}
            >
                <DialogTitle sx={{ textAlign: 'center' }}>{dialog?.title}</DialogTitle>
                <DialogContent sx={styles.tableMenuDialog}>
                    <DialogContentText>
                        {dialog?.subTitle && (
                            <Typography variant="h6" mb={2}>
                                {dialog?.subTitle}
                            </Typography>
                        )}
                        {dialog?.confirmation && dialog?.confirmation}
                    </DialogContentText>
                    {dialog?.children}
                </DialogContent>
                <DialogActions>{dialog?.actions}</DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ActionMenu;
