import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

const nonEmptyTest = (key) => ({
    name: key,
    message: `${key} is required`,
    test: (value) => {
        if (typeof value === 'string') {
            return value.trim().length > 0;
        }
        if (typeof value === 'number') {
            return !isNaN(value) && value !== null;
        }
        if (typeof value === 'boolean') {
            return value === true || value === false;
        }
        return value !== undefined && value !== null;
    },
});

export const useRuleInputFormik = (attributes, data, handleSubmit) => {
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState({
        2: Yup.number()
            .label('Budget Lower Limit')
            .min(0, 'Budget Lower Limit must be a positive number')
            .required('Budget Lower Limit is required'),
        3: Yup.number()
            .label('Budget Upper Limit')
            .min(0, 'Budget Upper Limit must be a positive number')
            .required('Budget Upper Limit is required'),
        4: Yup.number()
            .label('Budget Mid Limit')
            .min(0, 'Budget Mid Limit must be a positive number')
            .required('Budget Mid Limit is required'),
    });

    useEffect(() => {
        if (attributes?.length > 0) {
            let initialData = {};
            const ruleDefiningIds = data?.ruleDefiningIds?.split(',');
            const ruleValues = data?.ruleValues?.split(',');
            let ruleValueById = {};
            ruleDefiningIds?.forEach((id, index) => (ruleValueById[id] = ruleValues?.[index]));
            attributes?.forEach((item) => (initialData[item.id] = ruleValueById[item.id] || ''));

            const validationData = attributes.reduce((field, item) => {
                if (validationSchema[item.id]) field[item.id] = validationSchema[item.id];
                else
                    field[item.id] = Yup.mixed()
                        .label(item.definitions.fieldLabel)
                        .test(nonEmptyTest)
                        .required(`${item.definitions.fieldLabel} is required`);
                return field;
            }, {});
            setValidationSchema(validationData);
            setInitialValues(initialData);
        }
    }, [attributes, data]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object().shape(validationSchema),
        validateOnMount: false,
        validateOnChange: true,
        onSubmit: async (values, helpers) => {
            try {
                handleSubmit(values);
                // helpers.resetForm();
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
            } catch (err) {
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({
                    submit: err.message,
                });
                helpers.setSubmitting(false);
            }
        },
    });

    return { formik };
};
