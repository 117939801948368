import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styles } from './styles';
import RoleDetails from './RoleDetails';
import { formDataApi } from '../../../../axios';
import { RoleMenu } from '../RoleMenu';
import ActionButton from '../../../../components/common/ActionButtonAndMenu/ActionButton';

const ViewRoles = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [roleDetails, setRolesDetails] = useState({ isLoading: true });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setRolesDetails({ ...roleDetails, isLoading: true });
        async function fetchData() {
            const bodyFormData = new FormData();
            bodyFormData.append('id', params.id);
            try {
                const response = await formDataApi.post('/viewRole', bodyFormData);
                console.log('view role ', response.data);
                if (response?.data?.grantedPermissions) {
                    setRolesDetails({
                        name: 'Granted Permissions',
                        candidate: response.data.grantedPermissions || [],
                        displayName: response.data.secRoleInstance?.displayName || '',
                    });
                }
            } catch (err) {
                setRolesDetails({ isLoading: false });
                enqueueSnackbar('Something went wrong! Position could not be fetched', {
                    variant: 'error',
                });
            }
        }
        fetchData();
    }, [params.id]);

    return (
        <>
            <Box sx={styles.growBox}>
                <Container maxWidth="lg" sx={styles.pageWrapper}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            item
                            container
                            justifyContent="space-between"
                            sx={{
                                py: '32px !important',
                            }}
                        >
                            <Grid item>
                                <Typography variant="h4">Roles</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                pt: '0px !important',
                            }}
                        >
                            <Card {...props}>
                                <CardContent>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid
                                            item
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Button
                                                startIcon={<ChevronLeftIcon />}
                                                variant="text"
                                                color="secondary"
                                                onClick={() => navigate('/settings/roles')}
                                            >
                                                {'Back'}
                                            </Button>
                                            <Box sx={styles.growBox} />
                                            <Box>
                                                <RoleMenu
                                                    Component={ActionButton}
                                                    row={{
                                                        id: params.id,
                                                        ...roleDetails,
                                                    }}
                                                    handleAction={() => navigate('/settings/roles')}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                py: 2,
                                            }}
                                            variant="h6"
                                        >
                                            {roleDetails?.displayName}
                                        </Typography>
                                        <RoleDetails data={roleDetails} />
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default ViewRoles;
