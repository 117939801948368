import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import { Box, Container } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'react-mui-dropzone';
import FormData from 'form-data';
import { useSnackbar } from 'notistack';
import { styles } from './styles';
import { formDataApi } from '../../../axios';

export const RequestMaps = () => {
    const [files, setFiles] = useState('');
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleFiles = (files) => {
        setFiles(files[0]);
    };

    const validate = async () => {
        if (!files) {
            enqueueSnackbar('Please select a file', {
                variant: 'error',
            });
            return;
        }
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('configFile', files);
            const response = await formDataApi.post(`/resetSystemRequestMapsAction`, formData);
            if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
            enqueueSnackbar(response.data?.message, {
                variant: 'success',
            });
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        sx={{
                            py: '32px !important',
                        }}
                        item
                        container
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        py={4}
                    >
                        <Grid item>
                            <Typography variant="h4">Upload configuration file</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="center" alignItems="center" mt={2}>
                                <Grid item xs={12}>
                                    <DropzoneArea
                                        dropzoneText="Drag and drop a file here or click"
                                        filesLimit={1}
                                        onChange={handleFiles}
                                        showAlerts={false}
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{
                                            container: {
                                                spacing: 1,
                                                direction: 'row',
                                            },
                                        }}
                                        previewText="Selected files"
                                    />
                                    <LoadingButton
                                        sx={{
                                            mt: 2,
                                        }}
                                        loading={loading}
                                        variant="contained"
                                        color="primary"
                                        onClick={validate}
                                    >
                                        Upload
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        </Box>
    );
};
