import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { styles } from './styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: '100%',
    color: theme.palette.text.primary,
}));

const AuditLogTable = ({ user, message, timestamp }) => {
    return (
        <StyledPaper
            sx={{
                my: 3,
                mx: 'auto',
                p: 2,
                borderRadius: '8px',
                border: '1px solid var(--neutral-200, #E1E3EA)',
                background: 'var(--background-White, #FFF)',
            }}
        >
            <Grid container rowSpacing={1} display={'flex'} justifyContent={'space-between'}>
                <Grid item width={'200px'}>
                    <Typography component="span" sx={styles.auditLogRowTitle}>{`${user} :`}</Typography>
                </Grid>
                <Grid item width={'calc(100% - 200px - 160px)'}>
                    <Typography
                        component="span"
                        sx={styles.auditLogRowTitle}
                        dangerouslySetInnerHTML={{
                            __html: message,
                        }}
                    />
                </Grid>
                <Grid item width={'160px'}>
                    <Typography sx={styles.auditLogDate}>
                        {`${moment(timestamp).format('MMM DD yyyy HH:mm:ss')}`}
                    </Typography>
                </Grid>
            </Grid>
        </StyledPaper>
    );
};

export default AuditLogTable;
