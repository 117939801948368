/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Remove } from '../../../assets/icons/remove';
import { styles } from './styles';
import { Plus } from '../../../assets/icons/plus';
import { createChangeRequestPolicy, preCreateChangeRequestPolicy } from '../../../store/slices/listCRPolicies';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export default function CRPoliciesAdd(props) {
    const dispatch = useDispatch();
    const crPolicies = useSelector((state) => state.crPolicies);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { availableStatuses, createCRPolicy, majorApprovers, minorApprovers } = crPolicies;
    const [employeeList, setEmployeeList] = useState([
        {
            id: `employeeList_0`,
            label: 'Approver',
            selectValue: '',
            type: 'Minor',
            isActive: true,
        },
        {
            id: `employeeList_1`,
            label: 'Approver',
            selectValue: '',
            type: 'Major',
            isActive: true,
        },
    ]);

    const [policy, setPolicy] = useReducer(
        (state, action) => ({
            ...state,
            ...action,
        }),
        {
            name: '',
            status: 'Active',
            nameError: false,
            statusError: false,
        },
    );

    useEffect(() => {
        dispatch(preCreateChangeRequestPolicy());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!Object.keys(createCRPolicy).length) {
            enqueueSnackbar(createCRPolicy.message, {
                variant: 'success',
            });
            navigate('/changerequest/crpolicies');
        }
    }, [createCRPolicy]);

    const handleRemove = (id, type) => {
        const newEmployeeList = [...employeeList].map((item) => {
            if (item.id === id && item.type === type) {
                item.isActive = false;
            }
            return item;
        });
        setEmployeeList(newEmployeeList);
    };

    const handleCreate = () => {
        let majorApprovers = [];
        let minorApprovers = [];

        const { name, status } = policy;

        if (!!name && !!status) {
            const payload = {
                name,
                status,
            };

            employeeList
                .filter((item) => item.isActive)
                .forEach((item) => {
                    if (item.selectValue === '') return;
                    item.type === 'Minor'
                        ? minorApprovers.push(item.selectValue.id)
                        : majorApprovers.push(item.selectValue.id);
                });
            payload['approverCount'] = minorApprovers.length + majorApprovers.length;
            payload['majorApproverIds'] = `${majorApprovers}`;
            payload['minorApproverIds'] = `${minorApprovers}`;
            dispatch(createChangeRequestPolicy(payload));
        }
        setPolicy({
            nameError: !name,
            statusError: !status,
        });
    };

    const handleAddEmployeeList = (type) => {
        setEmployeeList([
            ...employeeList,
            {
                id: `employeeList_${employeeList.length}`,
                label: 'Approver',
                selectValue: '',
                type,
                isActive: true,
            },
        ]);
    };

    const handleChange = (e, id, type) => {
        const newEmployeeList = [...employeeList].map((item) => {
            if (item.id === id && item.type === type) {
                item.selectValue = e.target.value;
            }
            return item;
        });
        setEmployeeList(newEmployeeList);
    };

    const isDisabled = useMemo(() => {
        const activeMajorList = employeeList?.filter(
            (item) => item.isActive && item.type === 'Major' && item.selectValue !== '',
        );
        const activeMinorList = employeeList?.filter(
            (item) => item.isActive && item.type === 'Minor' && item.selectValue !== '',
        );
        return activeMajorList.length === 0 || activeMinorList.length === 0;
    }, [employeeList]);

    const { majorApproverList, minorApproverList } = useMemo(() => {
        const minorEmployeeSelectedList = employeeList
            ?.filter((item) => item.type === 'Minor' && item.isActive && item.selectValue !== '')
            ?.map((item) => parseInt(item.selectValue.id));
        const majorEmployeeSelectedList = employeeList
            ?.filter((item) => item.type === 'Major' && item.isActive && item.selectValue !== '')
            ?.map((item) => parseInt(item.selectValue.id));
        const minorApproverList = minorApprovers?.filter((item) => minorEmployeeSelectedList.indexOf(item.id) < 0);
        const majorApproverList = majorApprovers?.filter((item) => majorEmployeeSelectedList.indexOf(item.id) < 0);
        return { majorApproverList, minorApproverList };
    }, [majorApprovers, minorApprovers, employeeList]);

    const { minorEmployeeList, majorEmployeeList } = useMemo(() => {
        const minorEmployeeList = employeeList?.filter((item) => item.type === 'Minor' && item.isActive);
        const majorEmployeeList = employeeList?.filter((item) => item.type === 'Major' && item.isActive);
        return { minorEmployeeList, majorEmployeeList };
    }, [employeeList]);

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Typography variant="h4">{'Add User'}</Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0px !important',
                        }}
                    >
                        <Card {...props}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={() => navigate('/changerequest/crpolicies')}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Box sx={styles.basicInformation} component="form" noValidate autoComplete="off">
                                        <Typography variant="h6" sx={styles.basicInformationText}>
                                            Basic information
                                        </Typography>
                                        <Box sx={styles.inputBox}>
                                            <TextField
                                                required
                                                error={policy.nameError}
                                                id="outlined-name"
                                                label="Name"
                                                sx={styles.inputs}
                                                size="small"
                                                defaultValue={policy.name}
                                                onChange={(e) =>
                                                    setPolicy({
                                                        name: e.target.value,
                                                        nameError: false,
                                                    })
                                                }
                                            />
                                            <FormControl sx={styles.inputs} size="small" error={policy.statusError}>
                                                <InputLabel required id="demo-simple-select-label">
                                                    Available Status
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Available Statuses"
                                                    value={policy.status ?? ''}
                                                    onChange={(e) =>
                                                        setPolicy({
                                                            status: e.target.value,
                                                            statusError: false,
                                                        })
                                                    }
                                                >
                                                    {availableStatuses.map((item, index) => (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box sx={styles.bottomBLine}></Box>
                                    <Box sx={styles.additionalPreOfferButton}>
                                        <Typography variant="h6" sx={styles.basicInformationText}>
                                            Minor change request approvers
                                        </Typography>

                                        <Button
                                            color="primary"
                                            onClick={() => handleAddEmployeeList('Minor')}
                                            size="large"
                                            variant="text"
                                            startIcon={<Plus />}
                                        >
                                            Add Approver
                                        </Button>
                                    </Box>
                                    <Box sx={styles.employeeListsBox}>
                                        {minorEmployeeList?.map((item) => (
                                            <Box key={item.id} sx={styles.inputsAdditionalPreOfferBox}>
                                                <FormControl sx={styles.inputsAdditionalPreOffer} size="small">
                                                    <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label={item.label}
                                                        defaultValue=""
                                                        value={item.selectValue}
                                                        onChange={(e) => handleChange(e, item.id, 'Minor')}
                                                    >
                                                        {(item.selectValue === ''
                                                            ? minorApproverList
                                                            : [item.selectValue, ...minorApproverList]
                                                        )?.map((val) => (
                                                            <MenuItem value={val} key={val.id}>
                                                                {val.fullNameWithRole}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {minorEmployeeList.length > 1 && (
                                                    <Box sx={styles.removeButtonBox}>
                                                        <Button
                                                            onClick={() => handleRemove(item.id, 'Minor')}
                                                            size="large"
                                                            sx={styles.removeButton}
                                                            variant="text"
                                                            startIcon={<Remove />}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box sx={styles.bottomBLine}></Box>
                                    <Box sx={styles.additionalPreOfferButton}>
                                        <Typography variant="h6" sx={styles.basicInformationText}>
                                            Major change request approvers
                                        </Typography>

                                        <Button
                                            color="primary"
                                            onClick={() => handleAddEmployeeList('Major')}
                                            size="large"
                                            variant="text"
                                            startIcon={<Plus />}
                                        >
                                            Add Approver
                                        </Button>
                                    </Box>
                                    <Box sx={styles.employeeListsBox}>
                                        {majorEmployeeList?.map((item) => (
                                            <Box key={item.id} sx={styles.inputsAdditionalPreOfferBox}>
                                                <FormControl sx={styles.inputsAdditionalPreOffer} size="small">
                                                    <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label={item.label}
                                                        defaultValue=""
                                                        value={item.selectValue}
                                                        onChange={(e) => handleChange(e, item.id, 'Major')}
                                                    >
                                                        {(item.selectValue === ''
                                                            ? majorApproverList
                                                            : [item.selectValue, ...majorApproverList]
                                                        )?.map((val) => (
                                                            <MenuItem value={val} key={val.id}>
                                                                {val.fullNameWithRole}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {majorEmployeeList.length > 1 && (
                                                    <Box sx={styles.removeButtonBox}>
                                                        <Button
                                                            onClick={() => handleRemove(item.id, 'Major')}
                                                            size="large"
                                                            sx={styles.removeButton}
                                                            variant="text"
                                                            startIcon={<Remove />}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box>
                                        <Box sx={styles.bottomBLine}></Box>
                                        <Box sx={styles.createButtonBox}>
                                            <Button
                                                disabled={
                                                    !policy.status ||
                                                    !policy.name ||
                                                    (policy.status === 'Active' && isDisabled)
                                                }
                                                color="primary"
                                                onClick={handleCreate}
                                                size="medium"
                                                sx={styles.createButton}
                                                variant="contained"
                                            >
                                                Create
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
