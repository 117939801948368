import React from 'react';
import { Typography, Chip } from '@mui/material';
import { LocationsMenu } from '../../features/locations/Locations/LocationsMenu';
import ActionTableButton from '../../components/common/ActionButtonAndMenu/ActionTableButton';

export const columns = [
    {
        fieldName: 'name',
        name: 'name',
        label: 'Name',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'status',
        name: 'status',
        label: 'Status',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Chip
                label={row[column.fieldName]}
                sx={{
                    backgroundColor: row[column.fieldName] === 'Active' ? '#BCE2E2' : '#F3F4F7',
                }}
            />
        ),
    },
    {
        id: 'action',
        label: 'Actions',
        sticky: true,
        filter: true,
        formatValue: ({ row, action }) => (
            <LocationsMenu Component={ActionTableButton} row={row} handleAction={action} />
        ),
    },
];
