import React, { useCallback, useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Plus } from '../../assets/icons/plus';
import { styles } from './styles';
import { columns } from './columns';
import CustomTable from '../../components/common/CustomTable';
import { DepartmentFilter } from '../../features/departments/Department/DepartmentFilter';
import { api } from '../../axios';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateDepartments,
    updateSelectedDepartments,
    updateAllSelectedDepartments,
} from '../../store/slices/departments';
import PermissionChecker from '../../components/common/PermissionChecker';
import {
    clearFilters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    setFilters,
    setInitialFilters,
} from '../../store/slices/tableController';

const Departments = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const controller = useSelector((state) => state.tableController);

    const { enqueueSnackbar } = useSnackbar();
    const { departments } = useSelector((state) => state.departments);

    useEffect(() => {
        dispatch(
            setInitialFilters([
                {
                    property: {
                        name: 'name',
                        type: 'string',
                    },
                    operator: {
                        value: '',
                        typesAccepted: [],
                    },
                    value: '',
                },
            ]),
        );
    }, []);

    const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
        setLoading(true);
        try {
            if (searched) {
                const query = new URLSearchParams({});
                if (filters.length) {
                    // filters.forEach((filter) => query.append(filter.property, filter.value));
                    query.append('c', filters[0].property);
                    query.append('q', filters[0].value);
                }
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/searchDepartmentAction?${query.toString()}`);
                if (response.data) {
                    dispatch(updateDepartments(response.data));
                }
            } else {
                const query = new URLSearchParams({});
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/departments?${query.toString()}`);
                if (response.data) {
                    dispatch(updateDepartments(response.data));
                }
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (fetchData)
            fetchData(
                controller.searched,
                controller.filters,
                controller.sort,
                controller.sortBy,
                controller.page,
                controller.pageSize,
            );
    }, [
        controller.searched,
        controller.filters,
        controller.sort,
        controller.sortBy,
        controller.page,
        controller.pageSize,
        fetchData,
    ]);

    const handleFiltersApply = (newFilters) => {
        const parsedFilters = newFilters.map((filter) => ({
            property: filter.property.name,
            value: filter.value,
            operator: filter.operator.value,
        }));

        dispatch(setFilters(parsedFilters));
    };

    const handleFiltersClear = () => {
        dispatch(clearFilters());
    };

    const handlePageChange = (newPage) => {
        dispatch(onPageChange(newPage - 1));
    };

    const handlePageSizeChange = (newPageSize) => {
        dispatch(onPageSizeChange(newPageSize));
    };

    const handleSortChange = (event, property) => {
        const isAsc = controller.sortBy === property && controller.sort === 'asc';
        dispatch(
            onSortChange({
                sort: isAsc ? 'desc' : 'asc',
                sortBy: property,
            }),
        );
    };

    const handleRowClick = (row) => {
        navigate(`/settings/departments/${row}`);
    };

    const handleSelect = (event, rowId) => {
        dispatch(
            updateSelectedDepartments({
                isChecked: event.target.checked,
                rowId,
            }),
        );
    };

    const handleSelectAll = (event) => {
        dispatch(
            updateAllSelectedDepartments({
                isChecked: event.target.checked,
            }),
        );
    };

    return (
        <>
            <Box sx={styles.growBox}>
                <Container maxWidth="lg" sx={styles.pageWrapper}>
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            sx={{
                                py: '32px !important',
                            }}
                            item
                            container
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="center"
                            py={4}
                        >
                            <Grid item>
                                <Typography variant="h4">Departments</Typography>
                            </Grid>
                            <Grid item xs></Grid>
                            <PermissionChecker
                                requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DEPARTMENT']}
                                noAuthRedirect={false}
                            >
                                <Grid item xs="auto">
                                    <Button
                                        color="primary"
                                        onClick={() => navigate('/settings/departments/add')}
                                        size="medium"
                                        startIcon={<Plus fontSize="small" />}
                                        variant="contained"
                                    >
                                        Add Department
                                    </Button>
                                </Grid>
                            </PermissionChecker>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Card>
                            <CardContent>
                                <DepartmentFilter
                                    disabled={false}
                                    initialFilters={controller.initialFilters}
                                    onFiltersApply={handleFiltersApply}
                                    onFiltersClear={handleFiltersClear}
                                    filterProperties={columns}
                                    filters={controller.filters}
                                />
                                <CustomTable
                                    isLoading={loading}
                                    onSelect={handleSelect}
                                    onSelectAll={handleSelectAll}
                                    onRowClick={handleRowClick}
                                    onPageChange={handlePageChange}
                                    onPageSizeChange={handlePageSizeChange}
                                    onSortChange={handleSortChange}
                                    page={controller.page + 1}
                                    columns={columns}
                                    rows={departments.data}
                                    rowsCount={departments.count}
                                    sort={controller.sort}
                                    sortBy={controller.sortBy}
                                    showRowSelector={false}
                                    showPagignation
                                    keyColumn="id"
                                    action={() =>
                                        fetchData(
                                            controller.searched,
                                            controller.filters,
                                            controller.sort,
                                            controller.sortBy,
                                            controller.page,
                                            controller.pageSize,
                                        )
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Departments;
