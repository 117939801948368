import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getFormInitialValues } from '../../../utils/get-form-initial-values';
import { hasPermission } from '../../../utils/permission';

export const yoes = Array.from({ length: 51 }, (v, k) => `${k}`);

export const usePersonalDetailsFormik = (position, handleSubmit, isBulkModify = false) => {
    const authorities = useSelector((state) => state.session.user.authorities);
    const userHasCompaCreatePermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_CREATE_POSITION',
    ]);
    const userHasCompaModifyPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_UPDATE_POSITION',
    ]);
    const userHasCompaDisplayPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_POSITION',
    ]);

    const compaFields =
        userHasCompaCreatePermisson || (userHasCompaModifyPermisson && position !== null)
            ? {
                  budgetLowerLimit: position?.budgetLowerLimit || '',
                  budgetUpperLimit: position?.budgetUpperLimit || '',
                  budgetMidLimit: position?.budgetMidLimit || '',
              }
            : {};

    const compaFieldsValidations =
        userHasCompaCreatePermisson || userHasCompaModifyPermisson
            ? {
                  budgetLowerLimit: Yup.number()
                      .min(0, 'Budget Lower Limit must be a positive number')
                      .required('Budget Lower Limit is required'),
                  budgetMidLimit: Yup.number()
                      .min(0, 'Budget Mid Limit must be a positive number')
                      .required('Budget Mid Limit is required'),
                  budgetUpperLimit: Yup.number()
                      .min(0, 'Budget Upper Limit must be a positive number')
                      .required('Budget Upper Limit is required'),
              }
            : {};

    const formik = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        initialValues: {
            // title: position?.title || '',
            positionId: position?.id || '',
            positionCode: position?.positionCode || '',
            department: position?.departmentId || '',
            designation: position?.designationId || '',
            location: position?.locationId || '',
            startDate: position?.startDate || '',
            ...compaFields,
            submit: null,
        },
        validationSchema: Yup.object().shape({
            // title: !isBulkModify ? Yup.string().max(255).required('Title is required') : '',
            //positionId: !isBulkModify ? Yup.string().max(255).required('Position ID is required') : '',
            positionCode: !isBulkModify ? Yup.string().max(255).required('Position code is required') : '',
            department: !isBulkModify ? Yup.string().max(255).required('Department is required') : '',
            designation: !isBulkModify ? Yup.string().max(255).required('Designation is required') : '',
            location: !isBulkModify ? Yup.string().max(255) : '',
            // startDate: !isBulkModify ? Yup.date().transform((value, originalValue) => {
            //   if(!originalValue) return originalValue;
            //   return moment(originalValue).format("DDMMYYYY")
            // }).min(moment().format("DDMMYYYY"), 'Start date cannot be past date') : '',
            ...compaFieldsValidations,
        }),
        onSubmit: async (values, helpers) => {
            try {
                handleSubmit(values);
                helpers.setSubmitting(false);
            } catch (err) {
                helpers.setStatus({ success: false });
                helpers.setErrors({
                    submit: err.message,
                });
                helpers.setSubmitting(false);
            }
        },
    });
    return formik;
};

const nonEmptyTest = (key) => ({
    name: key,
    message: `${key} is required`,
    test: (value) => {
        if (typeof value === 'string') {
            return value.trim().length > 0;
        }
        if (typeof value === 'number') {
            return !isNaN(value) && value !== null;
        }
        if (typeof value === 'boolean') {
            return value === true || value === false;
        }
        return value !== undefined && value !== null;
    },
});

export const useCustomAttributesFormik = (metaCustomFieldDefinitions, customObjectInstance, handleSubmit) => {
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState({});

    const getInitialValues = () => {
        return getFormInitialValues(
            metaCustomFieldDefinitions.metaCustomFieldDefinitions,
            customObjectInstance?.customFieldValues,
        );
    };

    useEffect(() => {
        if (getInitialValues && metaCustomFieldDefinitions?.metaCustomFieldDefinitions?.length) {
            const data = getInitialValues();
            const customFields = metaCustomFieldDefinitions?.metaCustomFieldDefinitions;
            const validationData = customFields.reduce((item, field) => {
                if (field.isRequired)
                    item[field.fieldLabel] = Yup.mixed().test(nonEmptyTest).required(`${field.fieldLabel} is required`);
                return item;
            }, {});
            setValidationSchema(validationData);
            setInitialValues(data);
        }
    }, [customObjectInstance, metaCustomFieldDefinitions]);

    const customAttributesFormik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object().shape(validationSchema),
        validateOnMount: false,
        validateOnChange: false,
        onSubmit: async (values, helpers) => {
            try {
                handleSubmit(values);
                // helpers.resetForm();
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
            } catch (err) {
                console.error(err);
                helpers.setStatus({ success: false });
                helpers.setErrors({
                    submit: err.message,
                });
                helpers.setSubmitting(false);
            }
        },
    });

    return { customAttributesFormik, metaCustomFieldDefinitions };
};
