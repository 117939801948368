import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment/moment';
import { styles } from './styles';
import { updateCRStatus, resetSelectedCRs, cancelChangeRequest } from '../../../store/slices/listChangeRequests';
import DateCalendar from '../../../components/common/DateCalendar';

export const ChangeRequestMenu = ({ Component, row, tab, handleAction }) => {
    const [hasApproverRole, setHasApproverRole] = useState(false);
    const [isRequestingUpdateCRStatus, setRequestingUpdateCRStatus] = useState(false);
    const [actionType, setActionType] = useState();
    const [modalType, setModalType] = useState(1);
    const [startDate, setStartDate] = useState();
    const [comment, setComment] = useState('');
    const currentUser = useSelector((state) => state.session.user);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        setModalType(1);
        setStartDate();
        setComment('');
    }, [actionType]);

    useEffect(() => {
        const approvers = row?.position?.changeRequestPolicy?.approvers;
        const currentCRCategory = row?.categoryType?.name;
        if (currentUser?.empDBID && currentCRCategory && Array.isArray(approvers) && approvers.length > 0) {
            const currentCRCategoryApprover = approvers.find(
                (item) =>
                    item?.categoryType?.name === currentCRCategory &&
                    parseInt(currentUser?.empDBID) === item?.employeeId,
            );
            if (currentCRCategoryApprover) setHasApproverRole(true);
        }
    }, [currentUser?.empDBID, row?.position?.changeRequestPolicy?.approvers]);

    const { allowUpdatePositionStartDate, position } = useMemo(() => {
        const position = row?.position;
        const categoryType = row?.categoryType?.name;
        const majorApprovers = position?.changeRequestPolicy?.approvers?.filter(
            (item) => item?.categoryType?.name === 'Major',
        );
        const lastApprover = majorApprovers.length > 0 ? majorApprovers[majorApprovers.length - 1] : null;
        const allowUpdatePositionStartDate =
            categoryType === 'Major' && lastApprover?.employeeId === parseInt(currentUser?.empDBID);
        return { allowUpdatePositionStartDate, position };
    }, [row, currentUser]);

    const handleConfirmStatus = (status) => {
        const payload = {
            actType: status,
            ...(startDate && { startDate }),
            ...(comment?.length > 0 && { comment }),
        };

        if (isRequestingUpdateCRStatus) return;
        setRequestingUpdateCRStatus(true);
        const body = new FormData();
        body.append('changeRequestId', row?.id);
        if (payload.actType !== 'Cancel') {
            Object.keys(payload).forEach((key) => body.append(key, payload[key]));
            dispatch(
                updateCRStatus({
                    body,
                }),
            )
                .then((res) => {
                    if (res?.payload?.status === 'SUCCESS')
                        enqueueSnackbar(res?.payload?.message, {
                            variant: 'success',
                        });
                    else
                        enqueueSnackbar(res?.payload?.message, {
                            variant: 'error',
                        });
                    dispatch(resetSelectedCRs());
                    handleAction();
                })
                .catch((err) => {
                    enqueueSnackbar('Failed to update change request.', {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setRequestingUpdateCRStatus(false);
                    setActionType();
                });
        } else {
            body.append('rejectionComment', payload?.comment);
            dispatch(
                cancelChangeRequest({
                    body,
                }),
            )
                .then((res) => {
                    if (res?.payload?.status === 'SUCCESS')
                        enqueueSnackbar(res?.payload?.message, {
                            variant: 'success',
                        });
                    else
                        enqueueSnackbar(res?.payload?.message, {
                            variant: 'error',
                        });
                    dispatch(resetSelectedCRs());
                    handleAction();
                })
                .catch((err) => {
                    enqueueSnackbar('Failed to cancel change request.', {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setRequestingUpdateCRStatus(false);
                    setActionType();
                });
        }
    };

    const dialogContent = useMemo(() => {
        if (actionType === 'approve') {
            switch (modalType) {
                case 1:
                    return {
                        title: 'Approve change request',
                        subTitle: 'Are you sure you want to approve the change request?',
                        actions: (
                            <>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        return allowUpdatePositionStartDate
                                            ? setModalType(2)
                                            : handleConfirmStatus('Approve');
                                    }}
                                >
                                    {allowUpdatePositionStartDate ? `Next` : `Approve`}
                                </Button>
                            </>
                        ),
                    };
                case 2:
                    return {
                        title: 'Reset Start Date',
                        subTitle: `Would you like to reset the start date for this position (${position?.positionCode})?`,
                        actions: (
                            <>
                                <Button variant="text" color="primary" onClick={() => handleConfirmStatus('Approve')}>
                                    Skip
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => setModalType(3)}>
                                    Yes
                                </Button>
                            </>
                        ),
                    };
                case 3:
                    return {
                        title: 'Update Position Start Date',
                        subTitle: '',
                        children: (
                            <Box>
                                <Box sx={styles.infoLine}></Box>
                                <Typography
                                    variant={'body2'}
                                    mt="4px"
                                >{`Position Code: ${position?.positionCode}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mt="4px"
                                >{`Current Start Date: ${position?.startDate ? moment(position?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Box sx={styles.infoLine}></Box>
                                <Typography variant="subtitle1">Select the updated date from calendar</Typography>
                                <DateCalendar
                                    value={startDate || position?.startDate}
                                    onChange={(_key, value) => setStartDate(value)}
                                    disableFuture={true}
                                />
                            </Box>
                        ),
                        actions: (
                            <>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        !startDate ||
                                        (position?.startDate && moment(position?.startDate).format('yyyy-MM-DD')) ===
                                            (startDate && moment(startDate).format('yyyy-MM-DD'))
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setModalType(4)}
                                >
                                    Update
                                </Button>
                            </>
                        ),
                    };
                case 4:
                    return {
                        title: 'Approve Change Request and Confirm Position Start Date',
                        subTitle: 'Are you sure you want to Approve Change Request and update position details of',
                        children: (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Box sx={styles.infoLine}></Box>
                                    <Typography
                                        variant={'body2'}
                                        mx="24px"
                                    >{`Position Code: ${position?.positionCode}`}</Typography>
                                    <Typography
                                        variant={'body2'}
                                        mx="24px"
                                        mt="6px"
                                    >{`Current Start Date: ${position?.startDate ? moment(position?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                    <Typography
                                        variant={'body2'}
                                        mx="24px"
                                        mt="6px"
                                        color={'#3F455A'}
                                    >{`Updated Start Date: ${startDate ? moment(startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                    <Box sx={styles.infoLine}></Box>
                                </Grid>
                            </Grid>
                        ),
                        actions: (
                            <>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleConfirmStatus('Approve')}
                                >
                                    Confirm
                                </Button>
                            </>
                        ),
                    };
            }
        } else if (actionType === 'reject')
            return {
                title: 'Reject Change Request',
                subTitle: `Are you sure you want to reject the change request?`,
                children: (
                    <TextField
                        sx={{ mt: '15px' }}
                        fullWidth
                        label="Reason"
                        placeholder="Type here"
                        size="small"
                        variant="outlined"
                        onChange={(e) => setComment(e.target.value)}
                        multiline
                        rows={5}
                    />
                ),
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={() => handleConfirmStatus('Revert')}>
                            Confirm
                        </Button>
                    </React.Fragment>
                ),
            };
        else if (actionType === 'cancel')
            return {
                title: 'Cancel Change Request',
                subTitle: `Are you sure you want to cancel the change request?`,
                confirmation: `Once cancelled, the change request would become unactionable, and no further actions can be taken on it.`,
                children: (
                    <TextField
                        sx={{ mt: '15px' }}
                        fullWidth
                        label="Reason"
                        placeholder="Type here"
                        size="small"
                        variant="outlined"
                        onChange={(e) => setComment(e.target.value)}
                        multiline
                        rows={5}
                    />
                ),
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={() => handleConfirmStatus('Cancel')}>
                            Confirm
                        </Button>
                    </React.Fragment>
                ),
            };
    }, [actionType, modalType, startDate, comment]);

    const actionItems = [
        row?.status === 'Active' &&
            row?.createdBy?.employeeId === parseInt(currentUser?.empDBID) && {
                label: 'Modify',
                type: 'modify_policy',
                icon: <EditIcon />,
                onClick: () => navigate(`/changeRequests/${row?.id}/modify/${row?.parentEntityId}`),
            },
        row?.status === 'Active' &&
            row?.createdBy?.employeeId === parseInt(currentUser?.empDBID) &&
            tab !== 'approver' && {
                label: 'Cancel',
                type: 'cancel',
                icon: <CloseIcon />,
                onClick: (action) => setActionType(action.type),
            },
        hasApproverRole &&
            tab === 'approver' && {
                label: 'Approve',
                type: 'approve',
                icon: <CheckIcon />,
                onClick: (action) => setActionType(action.type),
            },
        hasApproverRole &&
            tab === 'approver' && {
                label: 'Reject',
                type: 'reject',
                icon: <ClearIcon />,
                onClick: (action) => setActionType(action.type),
            },
    ];

    return <Component actions={actionItems} dialog={dialogContent} />;
};
