export const endpoints = {
    LIST_CHANGE_REQUEST_POLICIES: '/listChangeRequestPolicies',
    SEARCH_CHANGE_REQUEST_POLICIES_ACTION: '/searchChangeRequestPoliciesAction',
    SEARCH_CHANGE_REQUEST_ACTION: '/searchChangeRequestsAction',
    BULK_UPDATE_POSITION_STATUSES: '/bulkUpdatePositionStatuses',
    BULK_UPDATE_CR_STATUSES: '/bulkUpdateChangeRequestStatuses',
    CHANGE_CR_STATUS: '/actionChangeRequest',
    CANCEL_CHANGE_REQUEST: '/cancelActiveChangeRequest',
    CREATE_CHANGE_REQUEST_POLICY: '/createChangeRequestPolicy',
    LIST_CHANGE_REQUEST: '/listChangeRequests',
    DELETE_CHANGE_REQUEST: '/deleteChangeRequest',
    GET_POSITIONS_MAPPED_TO_CHANGE_REQUEST_POLICY: '/getPositionsMappedToChangeRequestPolicy',
    BULK_UPDATE_POSITION_CHANGE_REQUEST_POLICY_MAPPINGS: '/updatePositionsMappedToChangeRequestPolicy',
    DELETE_CHANGE_REQUEST_POLICY: '/deleteChangeRequestPolicy',
    PRE_CREATE_CHANGE_REQUEST_POLICY: '/preCreateChangeRequestPolicy',
    PRE_MODIFY_CHANGE_REQUEST_POLICY: '/preModifyChangeRequestPolicy',
    DISPLAY_CHANGE_REQUEST_POLICY: '/displayChangeRequestPolicy',
    MODIFY_CHANGE_REQUEST_POLICY: '/modifyChangeRequestPolicy',
    LIST_POSITIONS: '/listPositions',
    POSITION_SUMMARY_BY_STATUS: 'positionSummaryByStatus',
    CR_SUMMARY_BY_STATUS: 'changeRequestSummaryByStatus',
    POSITION_MAPPING_SUMMARY_BY_STATUS: 'positionMappingSummaryByStatus',
    POSITION_RECRUITMENT_POLICY_MAPPING_SUMMARY: 'fetchRecruitmentPolicySummary',
    POSITION_CLOSURE_SUMMARY_BY_STATUS: 'positionClosureSummary',
};
