import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    TextField as TextFieldMUI,
    ListItemIcon,
    ListItemText,
    MenuList,
} from '@mui/material';
import { useSelector } from 'react-redux';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { styles } from './styles';
import TextField from '../../../components/common/InputField/TextField';
import { styles as viewStyles } from '../ViewChangeRequest/styles';
import { hasPermission } from '../../../utils/permission';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import PositionStatusChip from '../../positions/Positions/PositionStatusChip';
import { downloadFileApi } from '../../../axios';
import ConfirmationDialog from '../../../components/common/Dialog/ConfirmationDialog';

const UpdateChangeRequestDetails = ({
    position,
    formik,
    categoryType,
    basicAttributesCategory,
    metaCustomFields,
    isBulkModify,
    changeRequest,
    preCreateUpdateDetails,
}) => {
    const {
        departmentsList = [],
        designationsList = [],
        locationsList = [],
        employeeList = [],
        availablePositionStatuses = [],
    } = preCreateUpdateDetails || {};
    const authorities = useSelector((state) => state.session.user.authorities);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const userHasCompaPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_CREATE_CHANGE_REQUEST',
    ]);

    const handleMenuClick = (event, index) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const designationOptions = useMemo(() => {
        return !designationsList?.length
            ? [{ id: null, name: '' }]
            : designationsList
                  .filter((item) => item?.id !== position.designationId)
                  .map((item) => ({
                      id: item?.id,
                      name: item?.name,
                  }));
    }, [designationsList, position?.designationId]);
    const departmentOptions = useMemo(() => {
        return !departmentsList?.length
            ? [{ id: null, name: '' }]
            : departmentsList
                  .filter((item) => item?.id !== position.departmentId)
                  .map((item) => ({
                      id: item?.id,
                      name: item?.name,
                  }));
    }, [departmentsList, position?.departmentId]);
    const locationOptions = useMemo(() => {
        return !locationsList?.length
            ? [{ id: null, name: '' }]
            : locationsList
                  .filter((item) => item?.id !== position.locationId)
                  .map((item) => ({
                      id: item?.id,
                      name: item?.name,
                  }));
    }, [locationsList, position?.locationId]);
    const recruiterOptions = useMemo(
        () =>
            !employeeList?.length
                ? [{ id: null, name: '' }]
                : employeeList
                      .filter((item) => item?.id !== position.employeeId)
                      .map((item) => ({
                          id: item?.id,
                          name: item?.fullName,
                      })),
        [employeeList, position?.employeeId],
    );

    const filteredStatus = useMemo(() => {
        return availablePositionStatuses?.filter((status) => status !== position?.status);
    }, [availablePositionStatuses, position?.status]);

    const compaData = userHasCompaPermisson
        ? [
              {
                  attributeName: 'Budget Lower Limit',
                  currentValue: position?.budgetLowerLimit,
                  category: basicAttributesCategory['Budget Lower Limit']?.name,
                  newValue: (
                      <TextField
                          error={Boolean(formik.touched.budgetLowerLimit && formik.errors.budgetLowerLimit)}
                          fullWidth
                          helperText={formik.touched.budgetLowerLimit && formik.errors.budgetLowerLimit}
                          label="Budget Lower Limit"
                          name="budgetLowerLimit"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.budgetLowerLimit}
                          type="number"
                      />
                  ),
              },
              {
                  attributeName: 'Budget Mid Limit',
                  currentValue: position?.budgetMidLimit,
                  category: basicAttributesCategory['Budget Mid Limit']?.name,
                  newValue: (
                      <TextField
                          error={Boolean(formik.touched.budgetMidLimit && formik.errors.budgetMidLimit)}
                          fullWidth
                          helperText={formik.touched.budgetMidLimit && formik.errors.budgetMidLimit}
                          label="Budget Mid Limit"
                          name="budgetMidLimit"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.budgetMidLimit}
                          type="number"
                      />
                  ),
              },
              {
                  attributeName: 'Budget Upper Limit',
                  currentValue: position?.budgetUpperLimit,
                  category: basicAttributesCategory['Budget Upper Limit']?.name,
                  newValue: (
                      <TextField
                          error={Boolean(formik.touched.budgetUpperLimit && formik.errors.budgetUpperLimit)}
                          fullWidth
                          helperText={formik.touched.budgetUpperLimit && formik.errors.budgetUpperLimit}
                          label="Budget Upper Limit"
                          name="budgetUpperLimit"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.budgetUpperLimit}
                          type="number"
                      />
                  ),
              },
          ]
        : [];

    const data = [
        {
            attributeName: 'Designation',
            currentValue: position?.designation?.name,
            category: basicAttributesCategory['Designation']?.name,
            newValue: (
                <TextField
                    error={Boolean(formik.touched.designation && formik.errors.designation)}
                    fullWidth
                    helperText={formik.touched.designation && formik.errors.designation}
                    label="Designation"
                    name="designation"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    select
                    value={formik.values.designation}
                >
                    {designationOptions.map((designationOption) => (
                        <MenuItem key={designationOption.id} value={designationOption.id}>
                            {designationOption.name}
                        </MenuItem>
                    ))}
                </TextField>
            ),
        },
        ...(['On Hold - HM Delay', 'Offer Released', 'Offer Accepted', 'Offer Negotiation'].indexOf(position?.status) <
        0
            ? [
                  {
                      attributeName: 'Status',
                      currentValue: <PositionStatusChip label={position?.status} />,
                      category: basicAttributesCategory['Position Status']?.name,
                      newValue: (
                          <TextField
                              error={Boolean(formik.touched.status && formik.errors.status)}
                              fullWidth
                              helperText={formik.touched.status && formik.errors.status}
                              label="Status"
                              name="status"
                              select
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.status}
                          >
                              {(filteredStatus || []).map((status, index) => (
                                  <MenuItem key={index} value={status}>
                                      <PositionStatusChip label={status} />
                                  </MenuItem>
                              ))}
                          </TextField>
                      ),
                  },
              ]
            : []),
        {
            attributeName: 'Department',
            currentValue: position?.department?.name,
            category: basicAttributesCategory['Department']?.name,
            newValue: (
                <TextField
                    error={Boolean(formik.touched.department && formik.errors.department)}
                    fullWidth
                    helperText={formik.touched.department && formik.errors.department}
                    label="Department"
                    name="department"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.department}
                    select
                >
                    {departmentOptions.map((departmentOption) => (
                        <MenuItem key={departmentOption.id} value={departmentOption.id}>
                            {departmentOption.name}
                        </MenuItem>
                    ))}
                </TextField>
            ),
        },
        {
            attributeName: 'Location',
            currentValue: position?.location?.name,
            category: basicAttributesCategory['Location']?.name,
            newValue: (
                <TextField
                    error={Boolean(formik.touched.location && formik.errors.location)}
                    fullWidth
                    helperText={formik.touched.location && formik.errors.location}
                    label="Location (Optional)"
                    name="location"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.location}
                    select
                >
                    {locationOptions.map((departmentOption) => (
                        <MenuItem key={departmentOption.id} value={departmentOption.id}>
                            {departmentOption.name}
                        </MenuItem>
                    ))}
                </TextField>
            ),
        },
        ...compaData,
        ...metaCustomFields,
    ];

    const filteredData = useMemo(() => {
        return data.filter((item) => item.category === categoryType);
    }, [data, categoryType]);

    const handleFileChange = (e) => {
        formik.setFieldValue('fileName', e.target.files?.[0]?.name);
        formik.setFieldTouched('file', true);
        formik.setFieldValue('file', e.target.files?.[0]);
    };

    const handleDelete = () => {
        handleMenuClose();
        formik.setFieldValue('fileName', null);
        formik.setFieldValue('file', changeRequest?.fileName ? '' : null);
        setAnchorEl(null);
    };

    const handleDownload = async () => {
        try {
            handleMenuClose();
            const bodyFormData = new FormData();
            bodyFormData.append('id', changeRequest?.id);
            const response = await downloadFileApi.post('/downloadAttachmentsForChangeRequest', bodyFormData);
            fileDownload(new Blob([response.data]), 'file');
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <Grid>
            <Grid my={3}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={styles.flexItem1(isBulkModify)}>
                        <Typography variant="tableHeader" color="text.secondary">
                            ATTRIBUTE NAME
                        </Typography>
                    </Box>
                    {!isBulkModify && (
                        <Box sx={styles.flexItem2}>
                            <Typography variant="tableHeader" color="text.secondary">
                                CURRENT VALUE
                            </Typography>
                        </Box>
                    )}
                    <Box sx={viewStyles.flexItem3(isBulkModify)}>
                        <Typography variant="tableHeader" color="text.secondary">
                            NEW VALUE
                        </Typography>
                    </Box>
                </Box>
                {preCreateUpdateDetails?.isLoading ? (
                    <Box mt="16px" textAlign={'center'}>
                        Loading...
                    </Box>
                ) : (
                    filteredData.map((field) => (
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={styles.flexItem1(isBulkModify)}>
                                <img
                                    src={field.category === 'Major' ? majorCategoryIcon : minorCategoryIcon}
                                    alt="Minor"
                                    style={{
                                        paddingRight: '6px',
                                    }}
                                />
                                {field.attributeName}
                            </Box>
                            {!isBulkModify && (
                                <Box sx={styles.flexItem2}>
                                    {field.currentValue && field.currentValue !== 'undefined' ? field.currentValue : ''}
                                </Box>
                            )}
                            <Box sx={styles.flexItem3(isBulkModify)}>{field.newValue}</Box>
                        </Box>
                    ))
                )}
            </Grid>
            <Grid my={3}>
                <Box display={'flex'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography color="neutral.600" variant="subtitle2">
                        Upload File
                    </Typography>
                </Box>
                {formik.values.fileName && (
                    <Box display={'flex'} sx={{ alignItems: 'center', justifyContent: 'space-between' }} mt={2}>
                        <Box display={'flex'} sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    backgroundColor: 'grey.300',
                                }}
                            >
                                <AttachFileIcon style={{ color: 'neutral.600', width: '16px' }} />
                            </Box>
                            <Box>
                                <Typography variant="body1" ml={2}>
                                    {formik.values.fileName}
                                </Typography>
                                <Typography variant="body1" ml={2} sx={{ color: 'red', fontSize: '14px' }}>
                                    {Boolean(formik.touched.file && formik.errors.file) &&
                                        formik.touched.file &&
                                        formik.errors.file}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <IconButton
                                aria-label="menu"
                                aria-controls="menu-options"
                                aria-haspopup="true"
                                onClick={(e) => handleMenuClick(e)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="menu-options"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuList dense>
                                    <MenuItem onClick={() => setOpenDialog(true)}>
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        <ListItemText variant="body2">Delete</ListItemText>
                                    </MenuItem>
                                    {!formik.values?.file && (
                                        <MenuItem onClick={handleDownload}>
                                            <ListItemIcon>
                                                <CloudDownloadIcon />
                                            </ListItemIcon>
                                            <ListItemText variant="body2">Download</ListItemText>
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </Menu>
                        </Box>
                        <ConfirmationDialog
                            title="Delete File"
                            message="Are you sure you want to delete file?"
                            open={openDialog}
                            onCancel={() => {
                                setOpenDialog(false);
                                handleMenuClose();
                            }}
                            onConfirm={handleDelete}
                        />
                    </Box>
                )}
                {!formik.values.fileName && (
                    <Box display={'flex'} sx={{ alignItems: 'center', justifyContent: 'space-between' }} mt={2}>
                        <Button
                            component="label"
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<AttachFileIcon />}
                            aria-label="Attach file"
                        >
                            Attach File
                            <input
                                type="file"
                                name="file"
                                hidden
                                accept=".jpg, .jpeg, .png, .pdf"
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Box>
                )}
            </Grid>
            <TextFieldMUI
                sx={{ my: 3 }}
                error={Boolean(formik.touched.comments && formik.errors.comments)}
                fullWidth
                placeholder="Type here"
                size="small"
                variant="outlined"
                multiline
                rows={4}
                label="Comment"
                name="comments"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.comments}
            />
        </Grid>
    );
};

export default UpdateChangeRequestDetails;
