import { createSlice } from '@reduxjs/toolkit';

const departments = createSlice({
    name: 'departments',
    initialState: {
        selectedDepartments: [],
        departments: {
            count: 0,
            data: [],
        },
    },
    reducers: {
        updateDepartments(state, action) {
            const { departmentInstanceList = [], departmentInstanceCount = 0 } = action.payload;
            state.departments.data = departmentInstanceList;
            state.departments.count = departmentInstanceCount;
        },
        updateSelectedDepartments(state, action) {
            const { isChecked, rowId } = action.payload;

            if (isChecked) {
                state.selectedDepartments.push(rowId);
                return;
            }
            state.selectedDepartments = state.selectedDepartments.filter((selectedRow) => selectedRow !== rowId);
        },
        updateAllSelectedDepartments(state, action) {
            const { isChecked } = action.payload;
            state.selectedDepartments = [];
            if (isChecked) {
                state.departments.data.map((row) => state.selectedDepartments.push(row['id']));
                return;
            }
            state.selectedDepartments = [];
        },
    },
});

export const { updateDepartments, updateSelectedDepartments, updateAllSelectedDepartments } = departments.actions;

export default departments.reducer;
