import React, { useState } from 'react';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterDialog from './FilterDialog';

const FilterButton = ({
    disabled,
    filters,
    onFiltersApply,
    onFiltersClear,
    filterOperators,
    filterProperties,
    initialFilters,
}) => {
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    return (
        <>
            <Button
                color="primary"
                disabled={disabled}
                onClick={() => setOpenFilterDialog(true)}
                startIcon={<FilterAltIcon />}
                size="medium"
                variant={filters.length ? 'contained' : 'text'}
            >
                Filter
            </Button>

            <FilterDialog
                onApply={onFiltersApply}
                onClear={onFiltersClear}
                onClose={() => setOpenFilterDialog(false)}
                open={openFilterDialog}
                operators={filterOperators || []}
                properties={filterProperties}
                initialFilters={initialFilters}
            />
        </>
    );
};

export default FilterButton;
