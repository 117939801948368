export const styles = {
    content: {
        padding: '0 64px',
        height: '100%',
        position: 'relative',
    },
    tabpanel: {
        pr: 0,
        pl: 0,
    },
    divider: {
        width: '60%',
    },
    backButton: {
        color: 'neutral.500',
    },

    primaryButton: {
        alignItems: 'center',
        display: 'flex',
        py: 4,
        paddingBottom: '0px !important',
    },
};
