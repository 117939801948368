import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import TodayIcon from '@mui/icons-material/Today';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { usePositionDialogContent } from './ActionDialog/usePositionDialogContent';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { formDataApi } from '../../../axios';
import { usePositionStatus } from '../../../hooks/use-position-status';
import { updateAllSelectedPositions } from '../../../store/slices/positions';

const PositionMenu = ({ Component, row, handleAction, type = 'single', label }) => {
    const [actionType, setActionType] = useState();
    const [modalType, setModalType] = useState(1);
    const { user } = useSelector((state) => state.session);
    const { selectedPositions } = useSelector((state) => state.positions);
    const [disableStatusMenuItem, positionStatus] = usePositionStatus();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleConfirm = async (payload) => {
        try {
            setActionType();
            const data = new FormData();
            const selectedPositionIds = type === 'bulk' ? selectedPositions : [row?.id];

            if (actionType === 'map-cr-policy') {
                data.append('positionIds', selectedPositionIds.join(','));
                data.append('changeRequestPolicyId', payload.crPolicy);
                const response = await formDataApi.post('/updatePositionsMappedToChangeRequestPolicy', data);
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    handleAction();
                }
            } else if (actionType === 'resync') {
                data.append('positionIds', selectedPositionIds.join(','));
                const response = await formDataApi.post('/resyncPosition', data);
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    handleAction();
                }
            } else if (actionType === 'schedule-intake' || actionType === 'reschedule-intake') {
                data.append('positionIds', selectedPositionIds.join(','));
                for (const key in payload) data.append(key, payload[key]);
                const response = await formDataApi.post('/scheduleIntake', data);
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    handleAction();
                }
            } else if (actionType === 'complete-intake') {
                data.append('positionIds', selectedPositionIds.join(','));
                const response = await formDataApi.post('/completeIntake', data);
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    handleAction();
                }
            } else if (actionType === 'change-status') {
                data.append('id', selectedPositionIds.join(','));
                for (const key in payload) {
                    data.append(key, payload[key]);
                }
                const response = await formDataApi.post('/modifyPosition', data);
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar('Position status updated successfully.', {
                        variant: 'success',
                    });
                    handleAction();
                }
            } else if (actionType === 'update-start-date') {
                data.append('positionId', selectedPositionIds.join(','));
                data.append('startDate', payload.startDate);
                const response = await formDataApi.post('/updatePositionStartDate', data);
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    handleAction();
                }
            } else if (actionType === 'open-intake' || actionType === 'reject') {
                if (type === 'bulk') data.append('positionIds', selectedPositionIds.join(','));
                else data.append('positionId', selectedPositionIds.join(','));
                for (const key in payload) data.append(key, payload[key]);
                const response = await formDataApi.post(
                    type === 'bulk' ? '/bulkUpdatePositionStatuses' : 'updateSinglePositionStatus',
                    data,
                );
                if (response.status === 200) {
                    if (response.data?.status === 'ERROR') throw new Error(response.data?.message);
                    enqueueSnackbar(response.data.message, {
                        variant: 'success',
                    });
                    handleAction();
                }
            }
        } catch (error) {
            console.log('error while updating bulk status update', error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            if (type === 'bulk')
                dispatch(
                    updateAllSelectedPositions({
                        isChecked: false,
                    }),
                );
        }
    };

    const dialogContent = usePositionDialogContent({
        data: row,
        actionType,
        setActionType,
        modalType,
        setModalType,
        handleConfirm,
        type,
    });

    const actionItems =
        type === 'bulk'
            ? [
                  {
                      label: 'Open Intake',
                      type: 'open-intake',
                      icon: <CheckIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
                  },
                  {
                      label: 'Reject',
                      type: 'reject',
                      icon: <CloseIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_STATUS'],
                  },
                  {
                      label: 'Map Change Policy',
                      type: 'map-cr-policy',
                      icon: <InsertDriveFileIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY'],
                  },
                  {
                      label: 'Schedule Intake',
                      type: 'schedule-intake',
                      icon: <TodayIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SCHEDULE_INTAKE'],
                  },
                  {
                      label: 'Reschedule Intake',
                      type: 'reschedule-intake',
                      icon: <EventRepeatIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SCHEDULE_INTAKE'],
                  },
                  {
                      label: 'Complete Intake',
                      type: 'complete-intake',
                      icon: <EventAvailableIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPLETE_INTAKE'],
                  },
                  {
                      label: 'Resync',
                      type: 'resync',
                      icon: <AutorenewIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_POSITION'],
                  },
              ]
            : [
                  {
                      label: 'Open Intake',
                      type: 'open-intake',
                      icon: <CheckIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_SINGLE_POSITION_STATUS'],
                  },
                  {
                      label: 'Modify',
                      type: 'modify',
                      icon: <EditIcon />,
                      onClick: () => navigate(`/positions/modify/${row.id}`),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION'],
                  },
                  {
                      label: 'Reject',
                      type: 'reject',
                      icon: <CloseIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_SINGLE_POSITION_STATUS'],
                  },
                  {
                      label: 'Map Change Policy',
                      type: 'map-cr-policy',
                      icon: <InsertDriveFileIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_MAP_POSITION_TO_CHANGE_REQUEST_POLICY'],
                  },
                  {
                      label: 'Create Change Request',
                      type: 'create-cr',
                      icon: <ChangeCircleIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST'],
                  },
                  {
                      label: 'Change Status',
                      type: 'change-status',
                      icon: <PublishedWithChangesIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION_STATUS'],
                  },
                  {
                      label: 'Schedule Intake',
                      type: 'schedule-intake',
                      icon: <TodayIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SCHEDULE_INTAKE'],
                  },
                  {
                      label: 'Reschedule Intake',
                      type: 'reschedule-intake',
                      icon: <EventRepeatIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SCHEDULE_INTAKE'],
                  },
                  {
                      label: 'Complete Intake',
                      type: 'complete-intake',
                      icon: <EventAvailableIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPLETE_INTAKE'],
                  },
                  {
                      label: 'Resync',
                      type: 'resync',
                      icon: <AutorenewIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_POSITION'],
                  },
                  {
                      label: 'Update Position Start Date',
                      type: 'update-start-date',
                      icon: <EditCalendarIcon />,
                      onClick: (action) => setActionType(action.type),
                      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_POSITION_START_DATE'],
                  },
              ];

    const filteredActionItems =
        type === 'bulk'
            ? actionItems.filter((action) => {
                  if ((action.type === 'open-intake' || action.type === 'reject') && positionStatus === 'Draft')
                      return true;
                  if (action.type === 'map-cr-policy') return true;
                  if (action.type === 'schedule-intake' && positionStatus === 'Open Pending Intake') return true;
                  if (action.type === 'reschedule-intake' && positionStatus === 'Intake Scheduled') return true;
                  if (action.type === 'complete-intake' && positionStatus === 'Intake Scheduled') return true;
                  if (
                      action.type === 'resync' &&
                      ['Draft', 'Open Pending Intake', 'Intake Scheduled'].indexOf(positionStatus) < 0 &&
                      user.isIntegratedWithCanvas
                  )
                      return true;
                  return false;
              })
            : actionItems.filter((action) => {
                  if ((action.type === 'open-intake' || action.type === 'reject') && row['status'] === 'Draft')
                      return true;
                  if (action.type === 'modify' && row['status'] === 'Draft') return true;
                  if (
                      action.type === 'map-cr-policy' &&
                      ['Offer Released', 'Offer Accepted', 'Filled'].indexOf(row['status']) < 0
                  )
                      return true;
                  if (
                      action.type === 'create-cr' &&
                      row['changeRequestPolicy'] &&
                      ['Draft', 'Offer Released', 'Offer Accepted', 'Filled'].indexOf(row['status']) < 0
                  )
                      return true;
                  if (action.type === 'change-status' && row['status'] !== 'Filled' && !user.isIntegratedWithCanvas)
                      return true;
                  if (action.type === 'schedule-intake' && row['status'] === 'Open Pending Intake') return true;
                  if (action.type === 'reschedule-intake' && row['status'] === 'Intake Scheduled') return true;
                  if (action.type === 'complete-intake' && row['status'] === 'Intake Scheduled') return true;
                  if (
                      action.type === 'resync' &&
                      ['Draft', 'Open Pending Intake', 'Intake Scheduled'].indexOf(row['status']) < 0 &&
                      user.isIntegratedWithCanvas
                  )
                      return true;
                  if (
                      action.type === 'update-start-date' &&
                      ['Open Active Sourcing', 'On Hold'].indexOf(row['status']) >= 0
                  )
                      return true;
                  return false;
              });

    return (
        <Component
            style={
                ((actionType === 'create-cr' && modalType !== 1) ||
                    ['schedule-intake', 'reschedule-intake', 'complete-intake'].indexOf(actionType) >= 0) && {
                    dialog: { maxWidth: 'md' },
                }
            }
            label={label}
            onDialogClose={() => setActionType()}
            actions={filteredActionItems}
            dialog={dialogContent}
        />
    );
};

export default PositionMenu;
