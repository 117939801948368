export const styles = {
    backButton: {
        color: (theme) => theme.palette.primary.main,
    },
    infoWrapper: {
        display: 'flex',
        pr: 2,
    },
    info: {
        pl: 1,
    },
    tabWrapper: {
        pt: 3,
        pb: 4,
    },
    tabpanel: {
        p: 0,
    },
    header: {
        py: 4,
    },
    growBox: {
        flexGrow: 1,
    },
    flexCenterNone: {
        alignItems: 'center',
        display: 'flex',
    },
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
};
