import React from 'react';

export const OpenActiveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.40002 4.20005C1.40002 3.82875 1.54752 3.47265 1.81007 3.2101C2.07263 2.94755 2.42872 2.80005 2.80002 2.80005H5.60002L7.00002 4.20005H9.80002C10.1713 4.20005 10.5274 4.34755 10.79 4.6101C11.0525 4.87265 11.2 5.22875 11.2 5.60005V6.30005H5.60002C5.04307 6.30005 4.50893 6.5213 4.1151 6.91512C3.72127 7.30895 3.50002 7.84309 3.50002 8.40005V9.45005C3.50002 9.72853 3.3894 9.9956 3.19249 10.1925C2.99557 10.3894 2.7285 10.5 2.45002 10.5C2.17155 10.5 1.90448 10.3894 1.70756 10.1925C1.51065 9.9956 1.40002 9.72853 1.40002 9.45005V4.20005Z"
            fill="white"
        />
        <path
            d="M4.20002 8.4C4.20002 8.0287 4.34752 7.6726 4.61008 7.41005C4.87263 7.1475 5.22872 7 5.60002 7H11.2C11.5713 7 11.9274 7.1475 12.19 7.41005C12.4525 7.6726 12.6 8.0287 12.6 8.4V9.8C12.6 10.1713 12.4525 10.5274 12.19 10.7899C11.9274 11.0525 11.5713 11.2 11.2 11.2H1.40002H2.80002C3.17133 11.2 3.52742 11.0525 3.78997 10.7899C4.05253 10.5274 4.20002 10.1713 4.20002 9.8V8.4Z"
            fill="white"
        />
    </svg>
);
