import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    CardContent,
    Card,
    Skeleton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FormData from 'form-data';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckIcon from '@mui/icons-material/Check';
import { styles } from './styles';
import { modifyChangeRequestPolicy, preModifyChangeRequestPolicy } from '../../../store/slices/listCRPolicies';
import { Plus } from '../../../assets/icons/plus';
import { Remove } from '../../../assets/icons/remove';

const ModifyCRPolicies = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isTouched, setTouched] = useState(false);

    const crPolicies = useSelector((state) => state.crPolicies);
    const { enqueueSnackbar } = useSnackbar();

    const { availableStatuses, modifyPolicy, createCRPolicy, majorApprovers, minorApprovers } = crPolicies;
    const [employeeList, setEmployeeList] = useState([]);

    const [policy, setPolicy] = useState({
        name: modifyPolicy?.positionChangeRequestPolicy?.name,
        status: modifyPolicy?.positionChangeRequestPolicy?.status,
        nameError: false,
        statusError: false,
    });

    useEffect(() => {
        const data = new FormData();
        data.append('id', Number(params?.id));
        dispatch(preModifyChangeRequestPolicy(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!Object.keys(createCRPolicy).length) {
            enqueueSnackbar(createCRPolicy.message, {
                variant: 'success',
            });
            navigate('/changerequest/crpolicies');
        }
    }, [createCRPolicy]);

    useEffect(() => {
        let employeeListById = {};
        minorApprovers?.forEach((item) => (employeeListById[item?.id] = item));
        majorApprovers?.forEach((item) => (employeeListById[item?.id] = item));
        const initialApproversList = modifyPolicy.approvers?.map((item, index) => {
            return {
                id: `employeeList_${index}`,
                label: `Approver`,
                selectValue: employeeListById[item?.employee?.id],
                type: item?.categoryType?.name,
                isActive: true,
            };
        });
        setEmployeeList(initialApproversList);
        setPolicy({
            name: modifyPolicy?.positionChangeRequestPolicy?.name,
            status: modifyPolicy?.positionChangeRequestPolicy?.status,
            nameError: false,
            statusError: false,
        });
    }, [modifyPolicy, majorApprovers, minorApprovers]);

    const handleRemove = (id, type) => {
        if (!isTouched) setTouched(true);
        const activeList = employeeList.filter((item) => item.isActive && item.type === type);
        if (activeList.length > 1) {
            const newEmployeeList = [...employeeList].map((item) => {
                if (item?.id === id && item.type === type) {
                    item.isActive = false;
                }
                return item;
            });
            setEmployeeList(newEmployeeList);
        }
    };

    const handleUpdate = () => {
        let majorApprovers = [];
        let minorApprovers = [];

        const { name, status } = policy;

        if (!!name && !!status) {
            const payload = {
                id: params?.id,
                name,
                status,
            };
            employeeList
                .filter((item) => item.isActive)
                .forEach((item) => {
                    if (item.selectValue === '') return;
                    item.type === 'Minor'
                        ? minorApprovers.push(item.selectValue.id)
                        : majorApprovers.push(item.selectValue.id);
                });
            payload['approverCount'] = minorApprovers.length + majorApprovers.length;
            payload['minorApproverIds'] = `${minorApprovers}`;
            payload['majorApproverIds'] = `${majorApprovers}`;
            dispatch(modifyChangeRequestPolicy(payload));
        }
        setPolicy({
            ...policy,
            nameError: !name,
            statusError: !status,
        });
        setTouched(false);
    };

    const handleAddEmployeeList = (type) => {
        setEmployeeList([
            ...employeeList,
            {
                id: `employeeList_${employeeList.length}`,
                label: 'Approver',
                selectValue: '',
                type,
                isActive: true,
            },
        ]);
    };

    const handleChange = (e, id, type) => {
        if (!isTouched) setTouched(true);
        setEmployeeList((prevList) =>
            prevList.map((item) => {
                if (item?.id === id && item.type === type) {
                    item.selectValue = e.target.value;
                }
                return item;
            }),
        );
    };

    const handleBack = () => {
        navigate(-1);
    };

    const isDisabled = useMemo(() => {
        const activeMajorList = employeeList?.filter(
            (item) => item.isActive && item.type === 'Major' && item.selectValue !== '',
        );
        const activeMinorList = employeeList?.filter(
            (item) => item.isActive && item.type === 'Minor' && item.selectValue !== '',
        );
        return activeMajorList.length === 0 || activeMinorList.length === 0;
    }, [employeeList]);

    const { majorApproverList, minorApproverList } = useMemo(() => {
        const minorEmployeeSelectedList = employeeList
            ?.filter((item) => item.type === 'Minor' && item.isActive && item.selectValue !== '')
            .map((item) => parseInt(item.selectValue?.id));
        const majorEmployeeSelectedList = employeeList
            ?.filter((item) => item.type === 'Major' && item.isActive && item.selectValue !== '')
            .map((item) => parseInt(item.selectValue?.id));
        const minorApproverList =
            minorApprovers?.filter((item) => minorEmployeeSelectedList.indexOf(item?.id) < 0) || [];
        const majorApproverList =
            majorApprovers?.filter((item) => majorEmployeeSelectedList.indexOf(item?.id) < 0) || [];
        return { majorApproverList, minorApproverList };
    }, [majorApprovers, minorApprovers, employeeList]);

    const { minorEmployeeList, majorEmployeeList } = useMemo(() => {
        const minorEmployeeList = employeeList?.filter((item) => item.type === 'Minor' && item.isActive);
        const majorEmployeeList = employeeList?.filter((item) => item.type === 'Major' && item.isActive);
        return { minorEmployeeList, majorEmployeeList };
    }, [employeeList]);

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Box
                    id="modifyChangeRequestHeader"
                    sx={{
                        pt: '12px !important',
                        pb: '32px',
                    }}
                >
                    <Box sx={styles.flexCenterNone}>
                        <Typography variant="h4">Edit Change Policy</Typography>
                    </Box>
                </Box>
                <Card
                    sx={{
                        ...styles.dataWrappper,
                        pt: 0,
                        mt: 0,
                    }}
                >
                    <CardContent>
                        <>
                            <Grid container direction="column" spacing={2}>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <Button
                                        sx={styles.backButton}
                                        startIcon={<ChevronLeftIcon fontSize="small" />}
                                        onClick={handleBack}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                            {modifyPolicy.isLoading ? (
                                <Box
                                    sx={{
                                        py: 4,
                                    }}
                                >
                                    <Skeleton height={42} />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                            ) : (
                                <Grid item xs={12}>
                                    <Box sx={styles.basicInformation} component="form" noValidate autoComplete="off">
                                        <Box sx={styles.inputBox}>
                                            <TextField
                                                required
                                                error={policy.nameError}
                                                variant="outlined"
                                                id="outlined-name"
                                                label="Name"
                                                sx={styles.inputs}
                                                size="small"
                                                value={policy.name ?? ' '}
                                                onChange={(e) => {
                                                    if (!isTouched) setTouched(true);
                                                    setPolicy({
                                                        ...policy,
                                                        name: e.target.value,
                                                        nameError: false,
                                                    });
                                                }}
                                            />
                                            <FormControl sx={styles.inputs} size="small" error={policy.statusError}>
                                                <InputLabel required id="demo-simple-select-label">
                                                    Available Status
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Available Statuses"
                                                    value={policy.status ?? ''}
                                                    onChange={(e) => {
                                                        if (!isTouched) setTouched(true);
                                                        setPolicy({
                                                            ...policy,
                                                            status: e.target.value,
                                                            statusError: false,
                                                        });
                                                    }}
                                                >
                                                    {availableStatuses.map((item, index) => (
                                                        <MenuItem value={item} key={index}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box sx={styles.bottomBLine}></Box>
                                    <Box sx={styles.additionalPreOfferButton}>
                                        <Typography variant="h6" sx={styles.basicInformationText}>
                                            Minor change request approvers
                                        </Typography>
                                        <Button
                                            color="primary"
                                            onClick={() => handleAddEmployeeList('Minor')}
                                            size="large"
                                            variant="text"
                                            startIcon={<Plus />}
                                        >
                                            Add Approver
                                        </Button>
                                    </Box>
                                    <Box sx={styles.employeeListsBox}>
                                        {minorEmployeeList.map((item) => (
                                            <Box key={item?.id} sx={styles.inputsAdditionalPreOfferBox}>
                                                <FormControl sx={styles.inputsAdditionalPreOffer} size="small">
                                                    <InputLabel>{item.label}</InputLabel>
                                                    <Select
                                                        label={item.label}
                                                        value={item.selectValue}
                                                        onChange={(e) => handleChange(e, item?.id, 'Minor')}
                                                    >
                                                        {(item.selectValue === ''
                                                            ? minorApproverList
                                                            : [item.selectValue, ...minorApproverList]
                                                        )?.map((val) => (
                                                            <MenuItem value={val} key={val?.id}>
                                                                {val?.fullNameWithRole}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {minorEmployeeList.length > 1 && (
                                                    <Box sx={styles.removeButtonBox}>
                                                        <Button
                                                            onClick={() => handleRemove(item?.id, 'Minor')}
                                                            size="large"
                                                            sx={styles.removeButton}
                                                            variant="text"
                                                            startIcon={<Remove />}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box sx={styles.bottomBLine}></Box>
                                    <Box sx={styles.additionalPreOfferButton}>
                                        <Typography variant="h6" sx={styles.basicInformationText}>
                                            Major change request approvers
                                        </Typography>
                                        <Button
                                            color="primary"
                                            onClick={() => handleAddEmployeeList('Major')}
                                            size="large"
                                            variant="text"
                                            startIcon={<Plus />}
                                        >
                                            Add Approver
                                        </Button>
                                    </Box>
                                    <Box sx={styles.employeeListsBox}>
                                        {majorEmployeeList.map((item) => (
                                            <Box key={item?.id} sx={styles.inputsAdditionalPreOfferBox}>
                                                <FormControl sx={styles.inputsAdditionalPreOffer} size="small">
                                                    <InputLabel>{item.label}</InputLabel>
                                                    <Select
                                                        label={item.label}
                                                        value={item.selectValue}
                                                        onChange={(e) => handleChange(e, item?.id, 'Major')}
                                                    >
                                                        {(item.selectValue === ''
                                                            ? majorApproverList
                                                            : [item.selectValue, ...majorApproverList]
                                                        )?.map((val) => (
                                                            <MenuItem value={val} key={val?.id}>
                                                                {val?.fullNameWithRole}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {majorEmployeeList.length > 1 && (
                                                    <Box sx={styles.removeButtonBox}>
                                                        <Button
                                                            onClick={() => handleRemove(item?.id, 'Major')}
                                                            size="large"
                                                            sx={styles.removeButton}
                                                            variant="text"
                                                            startIcon={<Remove />}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                    <Grid
                                        item
                                        sx={{
                                            pt: 2,
                                        }}
                                        xs={12}
                                    >
                                        <Box>
                                            <Box sx={styles.bottomBLine}></Box>
                                            <Box sx={styles.createButtonBox}>
                                                <Button
                                                    disabled={
                                                        !policy.status ||
                                                        !policy.name ||
                                                        !isTouched ||
                                                        (policy.status === 'Active' && isDisabled)
                                                    }
                                                    color="primary"
                                                    onClick={handleUpdate}
                                                    size="large"
                                                    sx={styles.createButton}
                                                    variant="contained"
                                                    startIcon={<CheckIcon fontSize="small" />}
                                                >
                                                    Update
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
};

export default ModifyCRPolicies;
