/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet-async';
import { usePageTitles } from '../hooks/use-page-title';
import PageLayout from '../components/layout/PageLayout';

const withLayout = (Component) => (props) => {
    const pageTitle = usePageTitles();
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <PageLayout menu={<Layout />}>
                <Component {...props} />
            </PageLayout>
        </>
    );
};

export default withLayout;
