import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filters: [],
    initialFilters: [],
    page: 0,
    sort: 'desc',
    sortBy: 'id',
    view: 'all',
    searched: false,
    pageSize: 10,
};

const tableController = createSlice({
    name: 'tableController',
    initialState: initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = action.payload;
            state.page = 0;
            state.searched = true;
        },
        setInitialFilters(state, action) {
            state.initialFilters = action.payload;
        },
        clearFilters(state) {
            state.filters = [];
            state.page = 0;
            state.searched = false;
        },
        onPageChange(state, action) {
            state.page = action.payload;
        },
        onPageSizeChange(state, action) {
            state.page = 0;
            state.pageSize = action.payload;
        },
        onSortChange(state, action) {
            const { sort, sortBy } = action.payload;
            state.page = 0;
            state.sort = sort;
            state.sortBy = sortBy;
        },
        setController(state, action) {
            return { ...state, ...(action.payload || {}) };
        },
        resetController(state, action) {
            return {
                ...initialState,
                ...(action.payload || {}),
            };
        },
    },
});

export const {
    setFilters,
    setInitialFilters,
    clearFilters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    setController,
    resetController,
} = tableController.actions;

export default tableController.reducer;
