export const styles = {
    tableContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    table: {
        minWidth: 800,
    },
    avatar: {
        width: 64,
        height: 64,
    },
    titleWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    link: {
        display: 'block',
    },
    resourceUnavailable: {
        flexGm: 2,
        row: 1,
    },

    // Search filter related search
    searchFilterContainer: {
        textAlign: 'end',
        alignItems: 'center',
        py: 1.5,
    },
    actionMenu: {
        mr: 2,
        order: 5,
    },
    searchInput: {
        order: {
            sm: 2,
            xs: 1,
        },
        width: '273px',
    },
    exportButton: {
        order: 3,
        color: (theme) => theme.palette.text.secondary,
    },
    filterButton: {
        order: 4,
        mr: 2,
    },
    //Bulk Actions Menu
    buttonMenu: {
        '& .MuiPaper-root': {
            minWidth: 160,
        },
    },
    dialog: {
        '& .MuiPaper-root': {
            width: '496px',
            minHeight: '317px',
            padding: '30px',

            '& .MuiTypography-h6': {
                paddingTop: 0,
            },
        },
        '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .MuiDialogActions-root': {
            alignItems: 'center',
            justifyContent: 'center',
            p: '10px',
        },
        '& .MuiDialogContentText-root': {
            textAlign: 'center',
            fontSize: 14,
        },
        '& .MuiDialogTitle-root	': {
            color: 'text.primary',
            pb: 0,
        },
        '& .MuiTextField-root': {
            mt: 2,
        },
        '& .MuiFormControl-root': {
            mt: 6,
            mb: 3,
        },
    },
    changeRequestHeader: {
        py: 4,
    },
    growBox: {
        flexGrow: 1,
    },
    dataWrappper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    flexCenterNone: {
        alignItems: 'center',
        display: 'flex',
    },
    mx: (n) => ({
        mx: n,
    }),
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    searchFilterContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        py: 1.5,
    },
    actionMenu: (selectedchangeRequests) => ({
        mr: 2,
        display: selectedchangeRequests.length > 0 ? 'flex' : 'none',
        order: {
            sm: 1,
            xs: 2,
        },
    }),
    searchInput: {
        order: {
            sm: 2,
            xs: 1,
        },
        width: '40%',
    },
    exportButton: {
        order: 3,
        color: (theme) => theme.palette.text.secondary,
    },
    filterButton: {
        order: 4,
    },
};
