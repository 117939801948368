import React from 'react';
import { Grid, TextField, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';

const BasicFields = ({ data = [] }) => {
    return data.map((item) => {
        const { attributeTitle, id, category } = item;
        return (
            <Grid item container spacing={4} key={id} sx={{ display: 'flex', my: '7px' }}>
                <Grid item xs={6}>
                    <TextField
                        name="fieldLabel"
                        label={'Title'}
                        value={attributeTitle}
                        disabled={true}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl
                        sx={{
                            mx: 1,
                            minWidth: 120,
                        }}
                        size="small"
                        fullWidth
                    >
                        <InputLabel id="data-type-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="category"
                            disabled={true}
                            value={category?.name}
                            label="Category"
                        >
                            <MenuItem value="Minor">
                                <img
                                    src={minorCategoryIcon}
                                    alt="Minor"
                                    style={{
                                        paddingRight: '6px',
                                    }}
                                />
                                Minor
                            </MenuItem>
                            <MenuItem value="Major">
                                <img
                                    src={majorCategoryIcon}
                                    alt="Major"
                                    style={{
                                        paddingRight: '6px',
                                    }}
                                />
                                Major
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    });
};

export default BasicFields;
