import React, { useState } from 'react';
import { Box, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import moment from 'moment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styles } from '../ModifyChangeRequest/styles';
import { utilStyles } from '../../../utils/styles';
import { styles as viewStyles } from './styles';
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import PositionStatusChip from '../../positions/Positions/PositionStatusChip';
import { downloadFileApi } from '../../../axios';

const UpdateChangeRequests = ({ changeRequest }) => {
    const { id, attributes, categoryType, fileName } = changeRequest?.changeRequest || {};

    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const data = attributes?.map((data) => ({
        attributeName: data.displayName,
        name: data.name,
        currentValue:
            data?.type === 'Date'
                ? !!data.currentValue
                    ? moment(data.currentValue).format('DD-MMM-yyyy')
                    : ''
                : data.currentValue,
        newValue: data.hasOwnProperty('newValueInstance') ? data.newValueInstance.name : data.newValue,
    }));

    const handleDownload = async () => {
        try {
            handleMenuClose();
            const bodyFormData = new FormData();
            bodyFormData.append('id', id);
            const response = await downloadFileApi.post('/downloadAttachmentsForChangeRequest', bodyFormData);
            fileDownload(new Blob([response.data]), 'file');
        } catch (error) {
            console.log('inside catche ', error);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <Box sx={utilStyles.mt(3)}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={styles.flexItem1(false)}>
                    <Typography variant="tableHeader" color="text.secondary">
                        ATTRIBUTE NAME
                    </Typography>
                </Box>
                <Box sx={styles.flexItem2}>
                    <Typography variant="tableHeader" color="text.secondary">
                        ORIGINAL VALUE
                    </Typography>
                </Box>
                <Box sx={viewStyles.flexItem3(false)}>
                    <Typography variant="tableHeader" color="text.secondary">
                        REQUESTED VALUE
                    </Typography>
                </Box>
            </Box>
            {data?.map((field) => (
                <Box sx={{ display: 'flex' }}>
                    <Box sx={styles.flexItem1(false)}>
                        <img
                            src={categoryType?.name === 'Major' ? majorCategoryIcon : minorCategoryIcon}
                            alt={categoryType?.name}
                            style={{
                                paddingRight: '6px',
                            }}
                        />
                        {field.attributeName}
                    </Box>
                    <Box sx={styles.flexItem2}>
                        {field.currentValue && field.currentValue !== 'undefined' ? (
                            field.name === 'status' ? (
                                <Box>
                                    <PositionStatusChip label={field.currentValue} />
                                </Box>
                            ) : (
                                field.currentValue
                            )
                        ) : (
                            ''
                        )}
                    </Box>
                    <Box sx={viewStyles.flexItem3(false)}>
                        {field.name === 'status' ? (
                            <Box>
                                <PositionStatusChip label={field.newValue} />
                            </Box>
                        ) : (
                            field.newValue
                        )}
                    </Box>
                </Box>
            ))}
            {fileName && (
                <Grid my={3}>
                    <Box display={'flex'} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography color="neutral.600" variant="subtitle2">
                            Attachment File
                        </Typography>
                    </Box>
                    <Box display={'flex'} sx={{ alignItems: 'center', justifyContent: 'space-between' }} mt={2}>
                        <Box display={'flex'} sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 32,
                                    height: 32,
                                    borderRadius: '50%',
                                    backgroundColor: 'grey.300',
                                }}
                            >
                                <AttachFileIcon style={{ color: 'neutral.600', width: '16px' }} />
                            </Box>
                            <Typography variant="body1" ml={2}>
                                {fileName}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                aria-label="menu"
                                aria-controls="menu-options"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="menu-options"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuList dense>
                                    <MenuItem onClick={handleDownload}>
                                        <ListItemIcon>
                                            <CloudDownloadIcon />
                                        </ListItemIcon>
                                        <ListItemText variant="body2">Download</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                    </Box>
                </Grid>
            )}
        </Box>
    );
};

export default UpdateChangeRequests;
