import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Typography, Chip, Box, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getChangeRequests } from '../../../../store/slices/listChangeRequests';
import { ChangeRequestDetails } from '../../../changeRequests/ViewChangeRequest';
import { hasPermission } from '../../../../utils/permission';
import { formDataApi } from '../../../../axios';
import minorCategoryIcon from '../../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../../assets/images/majorCategory.svg';
import { StatusBackgroundColors } from '../../../changeRequests/columns';
import TextField from '../../../../components/common/InputField/TextField';
import CustomTable from '../../../../components/common/CustomTable';
import DateCalendar from '../../../../components/common/DateCalendar';
import IntakeDialogContent from './IntakeDialogContent';
import { setLoading } from '../../../../store/slices/app';
import { styles } from './styles';

export const allCRcolumns = [
    {
        fieldName: 'designation',
        name: 'designation',
        label: 'Designation',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.position?.designation?.name}
            </Typography>
        ),
    },
    {
        fieldName: 'change_policy',
        name: 'change_policy',
        label: 'Change Policy',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.position?.changeRequestPolicy?.name}
            </Typography>
        ),
    },
    {
        fieldName: 'categoryType',
        name: 'c',
        label: 'Category',
        type: 'string',
        formatValue: ({ row, column }) => {
            return (
                <div>
                    {row[column.fieldName] && (
                        <img
                            src={row[column.fieldName]?.name === 'Minor' ? minorCategoryIcon : majorCategoryIcon}
                            alt={row[column.fieldName]?.name}
                            style={{
                                paddingRight: '6px',
                            }}
                        />
                    )}
                    {row[column.fieldName]?.name}
                </div>
            );
        },
    },
    {
        fieldName: 'status',
        name: 's',
        label: 'Request Status',
        type: 'string',
        formatValue: ({ row, column }) => {
            return (
                <Chip
                    label={row[column.fieldName]}
                    sx={{
                        backgroundColor: StatusBackgroundColors[row[column.fieldName].toLowerCase()],
                    }}
                />
            );
        },
    },
    {
        fieldName: 'submission_date',
        name: 'submission_date',
        label: 'Submission date',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.createdOn}
            </Typography>
        ),
    },
    {
        fieldName: 'submitted_by',
        name: 'submitted_by',
        label: 'Submitted by',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row?.createdBy?.employee.fullName}
            </Typography>
        ),
    },
];

export const usePositionDialogContent = (props) => {
    const { data, actionType, setActionType, modalType, setModalType, handleConfirm, type } = props;
    const [changeRequestId, setChangeRequestId] = useState();
    const [preCreationCRData, setPreCreationCRData] = useState();
    const [payload, setPayload] = useState({});
    const [changeRequest, setChangeRequest] = useState({
        isLoading: false,
    });
    const [controller, setController] = useState({
        page: 0,
        pageSize: 10,
    });
    const { changeRequests, isLoading } = useSelector((state) => state.changeRequests);
    const { positions, selectedPositions } = useSelector((state) => state.positions);
    const crPolicies = useSelector((state) => state.crPolicies.crPolicies);
    const authorities = useSelector((state) => state.session.user.authorities);
    const userHasCompaDisplayPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_CHANGE_REQUEST',
    ]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const getCRList = useCallback(
        async (page, pageSize) => {
            try {
                const query = new URLSearchParams({});
                query.append('positionId', data.id);
                query.append('activeOnly', true);
                query.append('order', 'desc');
                query.append('sort', 'id');
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                dispatch(getChangeRequests({ query }));
            } catch (error) {
                console.log(error);
            }
        },
        [changeRequestId],
    );

    const getCRDetails = useCallback(async () => {
        try {
            setChangeRequest({
                ...changeRequest,
                isLoading: true,
            });
            const bodyFormData = new FormData();
            bodyFormData.append('id', changeRequestId);
            const apiPath = userHasCompaDisplayPermisson
                ? '/displayChangeRequestWithCompaFields'
                : '/displayChangeRequest';
            const response = await formDataApi.post(apiPath, bodyFormData);
            if (response.data) setChangeRequest(response.data);
        } catch (error) {
            console.log(error);
        }
    }, [changeRequestId]);

    const handleSubmit = (data) => {
        handleConfirm({ ...payload, ...data });
    };

    useEffect(() => {
        if (actionType === 'create-cr') {
            if (modalType === 1) {
                const fetchData = async () => {
                    const bodyFormData = new FormData();
                    bodyFormData.append('parentEntityId', data.id);
                    bodyFormData.append('reviewActiveRequests', true);
                    try {
                        dispatch(setLoading(true));
                        const response = await formDataApi.post('/preCreateChangeRequest', bodyFormData);
                        if (!response.data?.activeChangeRequestCount) {
                            navigate(`/changeRequests/create/${data.id}`);
                        }
                        setPreCreationCRData(response.data);
                    } catch (error) {
                        enqueueSnackbar('Something went wrong!', { variant: 'error' });
                        console.error('Error fetching pre-creation data:', error);
                    } finally {
                        dispatch(setLoading(false));
                    }
                };
                fetchData();
            } else if (modalType === 2) getCRList(controller.page, controller.pageSize);
            else if (modalType === 3) getCRDetails();
        }
    }, [actionType, modalType, controller.page, controller.pageSize]);

    useEffect(() => {
        setModalType(1);
        setPreCreationCRData();
        setChangeRequestId();
        setChangeRequest({ isLoading: false });
    }, [actionType]);

    useEffect(() => {
        if (actionType === 'map-cr-policy') return setPayload({ crPolicy: data.changeRequestPolicyId });
        if (actionType === 'change-status') return setPayload({ status: data.status });
        if (actionType === 'update-start-date') return setPayload({ startDate: data?.startDate });
        return setPayload({});
    }, [actionType, data]);

    const handlePageChange = (newPage) => {
        setController({
            ...controller,
            page: newPage - 1,
        });
    };

    const handlePageSizeChange = (newPageSize) => {
        setController({
            ...controller,
            page: 0,
            pageSize: newPageSize,
        });
    };

    const filteredCRPolicies = useMemo(() => {
        if (actionType === 'map-cr-policy') return crPolicies.filter((crPolicy) => crPolicy?.status !== 'Inactive');
    }, [actionType, crPolicies]);

    const dialogContent = useMemo(() => {
        if (actionType === 'open-intake') {
            return {
                title: `Open ${type === 'bulk' ? 'Positions' : 'Position'} for Intake?`,
                confirmation:
                    type === 'bulk'
                        ? `Are you sure you want to open ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'} for intake?`
                        : `Are you sure you want to open this position for intake?`,
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() =>
                                handleSubmit({
                                    status: 'Open Pending Intake',
                                })
                            }
                        >
                            Confirm
                        </Button>
                    </React.Fragment>
                ),
            };
        } else if (actionType === 'reject') {
            return {
                title: 'Reject Positions',
                confirmation:
                    type === 'bulk'
                        ? `Are you sure you want to reject ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'}?`
                        : `Are you sure you want to reject position?`,
                children: (
                    <TextField
                        sx={{ mt: '16px' }}
                        name="comment"
                        label="Comment"
                        placeholder="Type here..."
                        value={payload.comment || ''}
                        onChange={(event) =>
                            setPayload({
                                ...payload,
                                comment: event.target.value,
                            })
                        }
                        fullWidth
                    />
                ),
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            onClick={() =>
                                handleSubmit({
                                    status: 'Cancelled',
                                })
                            }
                        >
                            Confirm
                        </Button>
                    </React.Fragment>
                ),
            };
        } else if (actionType === 'map-cr-policy') {
            return {
                title: 'Map Change Policy',
                children: filteredCRPolicies?.length ? (
                    <TextField
                        sx={{ mt: '12px' }}
                        fullWidth
                        label="Change Policy"
                        name="cr-policy"
                        onChange={(event) =>
                            setPayload({
                                ...payload,
                                crPolicy: event.target.value,
                            })
                        }
                        select
                        value={payload.crPolicy || ''}
                    >
                        {filteredCRPolicies.map((crPolicy) => (
                            <MenuItem key={crPolicy.id} value={crPolicy.id}>
                                {crPolicy.name}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <>No Change Policies to map</>
                ),
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        {filteredCRPolicies?.length > 0 && (
                            <Button
                                disabled={payload.crPolicy === data.changeRequestPolicyId}
                                size="large"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Confirm
                            </Button>
                        )}
                    </React.Fragment>
                ),
            };
        } else if (actionType === 'resync') {
            return {
                title: 'Resync',
                confirmation:
                    type === 'bulk'
                        ? `Are you sure you want to Resync ${selectedPositions.length} ${selectedPositions.length > 1 ? 'positions' : 'position'} to canvas?`
                        : `Are you sure you want to resync this position with Canvas?`,
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={handleSubmit}>
                            Confirm
                        </Button>
                    </React.Fragment>
                ),
            };
        } else if (actionType === 'create-cr') {
            switch (modalType) {
                case 1:
                    return preCreationCRData
                        ? {
                              title: 'Create Change Request',
                              confirmation:
                                  preCreationCRData?.activeChangeRequestCount > 1
                                      ? `There are currently ${preCreationCRData?.activeChangeRequestCount} active change requests for this position. Would you like to review them first or proceed with creating a new one?`
                                      : `There is currently ${preCreationCRData?.activeChangeRequestCount} active change request for this position. Would you like to review it first or proceed with creating a new one?`,
                              actions: (
                                  <React.Fragment>
                                      <Button variant="text" color="primary" onClick={() => setActionType()}>
                                          Close
                                      </Button>
                                      <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={() => navigate(`/changeRequests/create/${data.id}`)}
                                      >
                                          Create New
                                      </Button>
                                      <Button variant="contained" color="primary" onClick={() => setModalType(2)}>
                                          Review
                                      </Button>
                                  </React.Fragment>
                              ),
                          }
                        : null;
                case 2:
                    return {
                        title: `Active Change Requests (${data?.positionCode})`,
                        children: (
                            <Box width={'100%'}>
                                <Button
                                    style={{
                                        marginBottom: '16px',
                                    }}
                                    startIcon={<ChevronLeftIcon />}
                                    variant="text"
                                    color="secondary"
                                    onClick={() => setModalType(1)}
                                >
                                    Back
                                </Button>
                                <CustomTable
                                    isLoading={isLoading}
                                    onPageChange={handlePageChange}
                                    onPageSizeChange={handlePageSizeChange}
                                    page={controller.page + 1}
                                    onRowClick={(row) => {
                                        setModalType(3);
                                        setChangeRequestId(row);
                                    }}
                                    columns={allCRcolumns}
                                    rows={changeRequests?.changeRequests}
                                    rowsCount={changeRequests?.changeRequestCount}
                                    keyColumn="id"
                                    route="changerequests"
                                />
                            </Box>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(`/changeRequests/create/${data.id}`)}
                                >
                                    Create New
                                </Button>
                            </React.Fragment>
                        ),
                    };
                case 3:
                    return {
                        title: 'Create Change Request',
                        children: (
                            <Box width={'100%'}>
                                <Button
                                    style={{
                                        marginBottom: '16px',
                                    }}
                                    startIcon={<ChevronLeftIcon />}
                                    variant="text"
                                    color="secondary"
                                    onClick={() => setModalType(2)}
                                >
                                    Back
                                </Button>
                                <ChangeRequestDetails changeRequest={changeRequest?.changeRequest} />
                            </Box>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(`/changeRequests/create/${data.id}`)}
                                >
                                    Create New
                                </Button>
                            </React.Fragment>
                        ),
                    };
            }
        } else if (actionType === 'update-start-date') {
            switch (modalType) {
                case 1:
                    return {
                        title: 'Update Position Start Date',
                        children: (
                            <React.Fragment>
                                <Box sx={styles.infoLine}></Box>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                >{`Position Code: ${data?.positionCode}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                >{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Box sx={styles.infoLine}></Box>
                                <Typography variant="subtitle1" mx="24px">
                                    Select the updated date from calendar
                                </Typography>
                                <DateCalendar
                                    value={payload.startDate}
                                    onChange={(_key, value) =>
                                        setPayload({
                                            ...payload,
                                            startDate: value,
                                        })
                                    }
                                    disableFuture={true}
                                />
                            </React.Fragment>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        (data?.startDate && moment(data?.startDate).format('yyyy-MM-DD')) ===
                                        (payload?.startDate && moment(payload?.startDate).format('yyyy-MM-DD'))
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setModalType(2)}
                                >
                                    Update
                                </Button>
                            </React.Fragment>
                        ),
                    };
                case 2:
                    return {
                        title: 'Confirm Position Start Date',
                        confirmation: 'Are you sure you want to update position details of',
                        children: (
                            <React.Fragment>
                                <Box sx={styles.infoLine}></Box>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                >{`Position Code: ${data?.positionCode}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                >{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                    color={'#3F455A'}
                                >{`Updated Start Date: ${payload?.startDate ? moment(payload?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Box sx={styles.infoLine}></Box>
                            </React.Fragment>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        (data?.startDate && moment(data?.startDate).format('yyyy-MM-DD')) ===
                                        (payload?.startDate && moment(payload?.startDate).format('yyyy-MM-DD'))
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    Confirm
                                </Button>
                            </React.Fragment>
                        ),
                    };
            }
        } else if (actionType === 'change-status') {
            switch (modalType) {
                case 1:
                    return {
                        title: 'Change Position Status',
                        children: (
                            <React.Fragment>
                                <TextField
                                    sx={{
                                        mt: 1,
                                    }}
                                    fullWidth
                                    label="Change Position Status"
                                    name="change-status"
                                    onChange={(event) =>
                                        setPayload({
                                            ...payload,
                                            status: event.target.value,
                                        })
                                    }
                                    select
                                    value={payload.status || ''}
                                >
                                    {(data?.availablePositionStatuses || [])?.map((status, index) => (
                                        <MenuItem key={index} value={status}>
                                            {status}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </React.Fragment>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={data?.status === payload.status}
                                    color="primary" // Commeted position prompt integration
                                    onClick={handleSubmit} // () => setModalType(2)
                                >
                                    Confirm
                                </Button>
                            </React.Fragment>
                        ), // Next
                    };
                case 2:
                    return {
                        title: 'Reset Start Date',
                        confirmation: `Would you like to reset the start date for this position (${data?.positionCode})?`,
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={handleSubmit}>
                                    Skip
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => setModalType(3)}>
                                    Yes
                                </Button>
                            </React.Fragment>
                        ),
                    };
                case 3:
                    return {
                        title: 'Update Position Start Date',
                        children: (
                            <React.Fragment>
                                <Box sx={styles.infoLine}></Box>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                >{`Position Code: ${data?.positionCode}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                >{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Box sx={styles.infoLine}></Box>
                                <Typography variant="subtitle1" mx="24px">
                                    Select the updated date from calendar
                                </Typography>
                                <DateCalendar
                                    value={payload?.startDate}
                                    onChange={(_key, value) =>
                                        setPayload({
                                            ...payload,
                                            startDate: value,
                                        })
                                    }
                                    disableFuture={true}
                                />
                            </React.Fragment>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={
                                        (data?.startDate && moment(data?.startDate).format('yyyy-MM-DD')) ===
                                        (payload?.startDate && moment(payload?.startDate).format('yyyy-MM-DD'))
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setModalType(4)}
                                >
                                    Update
                                </Button>
                            </React.Fragment>
                        ),
                    };
                case 4:
                    return {
                        title: 'Confirm Position Start Date',
                        confirmation: ' Are you sure you want to update position details of',
                        children: (
                            <React.Fragment>
                                <Box sx={styles.infoLine}></Box>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                >{`Position Code: ${data?.positionCode}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                    color={'#3F455A'}
                                >{`Position Status: ${payload?.status}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                >{`Current Start Date: ${data?.startDate ? moment(data?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Typography
                                    variant={'body2'}
                                    mx="24px"
                                    mt="6px"
                                    color={'#3F455A'}
                                >{`Updated Start Date: ${payload?.startDate ? moment(payload?.startDate).format('MMM DD yyyy') : '-'}`}</Typography>
                                <Box sx={styles.infoLine}></Box>
                            </React.Fragment>
                        ),
                        actions: (
                            <React.Fragment>
                                <Button variant="text" color="primary" onClick={() => setActionType()}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleSubmit}>
                                    Confirm
                                </Button>
                            </React.Fragment>
                        ),
                    };
            }
        } else if (actionType === 'schedule-intake') {
            return {
                title: 'Schedule Intake',
                children: (
                    <Box width={'100%'}>
                        <IntakeDialogContent
                            actionType="Schedule"
                            type={type}
                            data={type === 'bulk' ? positions.data : [data]}
                            handleCancel={() => setActionType()}
                            handleSubmit={handleSubmit}
                        />
                    </Box>
                ),
            };
        } else if (actionType === 'reschedule-intake') {
            return {
                title: 'Reschedule Intake',
                children: (
                    <Box width={'100%'}>
                        <IntakeDialogContent
                            actionType="Reschedule"
                            type={type}
                            data={type === 'bulk' ? positions.data : [data]}
                            handleCancel={() => setActionType()}
                            handleSubmit={handleSubmit}
                        />
                    </Box>
                ),
            };
        } else if (actionType === 'complete-intake') {
            return {
                title: 'Complete Intake',
                children: (
                    <Box width={'100%'}>
                        <IntakeDialogContent
                            actionType="Complete"
                            type={type}
                            data={type === 'bulk' ? positions.data : [data]}
                            handleCancel={() => setActionType()}
                            handleSubmit={handleSubmit}
                        />
                    </Box>
                ),
            };
        }
        return null;
    }, [modalType, data, payload, preCreationCRData, isLoading, changeRequest, actionType, setActionType]);

    return dialogContent;
};
