export const styles = {
    fileButton: {
        width: '100%',
        height: '100%',
        color: 'text.primary',
        cursor: 'pointer',
        '& .fileLabel': {
            lineHeight: '1.4375em',
            letterSpacing: '0.15px',
            position: 'absolute',
            top: '-25%',
            left: '14px',
            fontSize: '12px',
            fontWeight: 500,
            background: 'white',
        },
        '&:hover': {
            backgroundColor: 'white',
        },
        '.fileName': {
            textAlign: 'left',
            whiteSpace: 'nowrap',
            overflow: 'hidden' /* "overflow" value must be different from  visible"*/,
            '-o-text-overflow': 'ellipsis' /* Opera < 11*/,
            textOverflow: 'ellipsis',
        },
    },
};
