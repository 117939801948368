export const hasPermission = (userPermissions, allowedPermissions, notAllowedPermissions) => {
    return (
        allowedPermissions?.every((allowedPermission) =>
            userPermissions?.some((userPermission) => userPermission.authority === allowedPermission),
        ) &&
        !notAllowedPermissions?.some((notAllowedPermission) =>
            userPermissions?.some((userPermission) => userPermission.authority === notAllowedPermission),
        )
    );
};
