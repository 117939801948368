import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styles } from './styles';

const AuditLog = ({ data, onChange }) => {
    const [tab, setTab] = useState(0);

    useEffect(() => {
        onChange && onChange(data[tab]);
    }, [tab]);

    return (
        <Accordion>
            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                <Typography color="neutral.600" variant="subtitle2">
                    {data.length > 1 ? 'Logs' : 'Audit Logs'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {data.length > 1 && (
                    <Tabs
                        value={tab}
                        onChange={(_e, value) => setTab(value)}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {data.map((item, index) => (
                            <Tab value={index} label={item.title} />
                        ))}
                    </Tabs>
                )}
                <Box sx={styles.auditLogBox}>
                    {data[tab].logs.map((item, index) => {
                        const Component = data[tab].Component;
                        return <Component key={index} {...item} />;
                    })}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AuditLog;
