import { configureStore, combineReducers } from '@reduxjs/toolkit';
import listCRPolicies from './slices/listCRPolicies';
import positions from './slices/positions';
import session from './slices/session';
import changeRequests from './slices/listChangeRequests';
import customAttributes from './slices/customAttributes';
import dashboard from './slices/dashboard';
import bulkUploadPositions from './slices/bulkUploadPositions';
import roles from './slices/roles';
import departments from './slices/departments';
import designations from './slices/designation';
import locations from './slices/locations';
import companies from './slices/companies';
import tableController from './slices/tableController';
import app from './slices/app';

const combinedReducer = combineReducers({
    dashboard,
    crPolicies: listCRPolicies,
    changeRequests,
    positions,
    session,
    customAttributes,
    bulkUploadPositions,
    roles,
    departments,
    designations,
    locations,
    companies,
    tableController,
    app,
});

const rootReducer = (state, action) => {
    if (action.type === 'session/logout') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
