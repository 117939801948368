import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PositionData from './PositionData';
import { styles } from './styles';
import { formDataApi } from '../../../axios';
import { hasPermission } from '../../../utils/permission';

const ViewPosition = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [positionDetails, setPositionDetails] = useState({
        isLoading: true,
    });

    const authorities = useSelector((state) => state.session.user.authorities);

    const userHasCompaDisplayPermisson = hasPermission(authorities, [
        'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_POSITION',
    ]);

    const fetchData = async () => {
        const bodyFormData = new FormData();
        bodyFormData.append('id', params.id);
        try {
            const positionDetailsApiPath = userHasCompaDisplayPermisson
                ? '/positionDetailsWithCompaFields'
                : '/positionDetails';
            const response = await formDataApi.post(positionDetailsApiPath, bodyFormData);
            if (response?.data?.positionInstance) {
                setPositionDetails(response?.data);
            }
        } catch (err) {
            setPositionDetails({ isLoading: false });
            toast.error('Something went wrong! Position could not be fetched.');
        }
    };

    useEffect(() => {
        setPositionDetails({ ...positionDetails, isLoading: true });
        fetchData();
    }, [params.id]);

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Grid item>
                            <Typography variant="h4">Position</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0px !important',
                        }}
                    >
                        <Card {...props}>
                            <CardContent>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Button
                                            startIcon={<ChevronLeftIcon />}
                                            variant="text"
                                            color="secondary"
                                            onClick={() => navigate('/positions')}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <PositionData position={positionDetails} fetchData={fetchData} />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ViewPosition;
