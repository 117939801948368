import React from 'react';
import { Result } from 'antd';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary" variant="contained" onClick={() => navigate('/dashboard')}>
                    Go To Dashboard
                </Button>
            }
        />
    );
};

export default PageNotFound;
