import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import WorkIcon from '@mui/icons-material/Work';
import CachedTwoToneIcon from '@mui/icons-material/CachedTwoTone';
import { useSnackbar } from 'notistack';
import { deleteChangeRequestPolicy } from '../../../store/slices/listCRPolicies';

export const CRPoliciesMenu = ({ Component, row, handleAction }) => {
    const [actionType, setActionType] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = () => {
        setActionType();
        dispatch(deleteChangeRequestPolicy({ id: row.id }))
            .unwrap()
            .then((res) => {
                if (res?.status === 'SUCCESS') {
                    enqueueSnackbar(res?.message, {
                        variant: 'success',
                    });
                    handleAction();
                } else
                    enqueueSnackbar(res?.message, {
                        variant: 'error',
                    });
            })
            .catch((err) => {
                enqueueSnackbar('Failed to delete change request policy.', {
                    variant: 'error',
                });
            });
    };

    const dialogContent = useMemo(() => {
        if (actionType === 'delete')
            return {
                title: 'Delete Change Policy',
                confirmation: `Are you sure you want to delete this Change Policy?`,
                actions: (
                    <React.Fragment>
                        <Button size="large" onClick={() => setActionType()}>
                            Cancel
                        </Button>
                        <Button size="large" variant="contained" onClick={handleDelete}>
                            Delete
                        </Button>
                    </React.Fragment>
                ),
            };
    }, [actionType]);

    const actionItems = [
        {
            label: 'Modify Policy',
            type: 'modify_policy',
            icon: <EditIcon />,
            onClick: () => navigate(`/changerequest/crpolicies/modify/${row.id}`),
            permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_UPDATE_CHANGE_REQUEST_POLICY'],
        },
        {
            label: 'Delete',
            type: 'delete',
            icon: <DeleteIcon />,
            onClick: (action) => setActionType(action.type),
            permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_DELETE_CHANGE_REQUEST_POLICY'],
        },
        {
            label: 'View Mapped Positions',
            type: 'view_mapped_positions',
            icon: <WorkIcon />,
            onClick: () => navigate(`/crpolicies/mappedpositions/view/${row.id}`),
            permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_VIEW_POSITIONS_MAPPED_TO_CHANGE_REQUEST_POLICY'],
        },
        {
            label: 'Update Mapped Positions',
            type: 'update_mapped_positions',
            icon: <CachedTwoToneIcon />,
            onClick: () => navigate(`/crpolicies/mappedpositions/update/${row.id}`),
            permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_BULK_UPDATE_POSITION_CHANGE_REQUEST_POLICY_MAPPINGS'],
        },
    ];

    return <Component actions={actionItems} dialog={dialogContent} />;
};
