import React, { useState } from 'react';
import { Table, Transfer } from 'antd';
import difference from 'lodash/difference';
import './index.css';

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;
            const rowSelection = {
                getCheckboxProps: (item) => ({
                    disabled: listDisabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };
            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{
                        pointerEvents: listDisabled ? 'none' : undefined,
                    }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                    })}
                />
            );
        }}
    </Transfer>
);

const leftTableColumns = [
    {
        dataIndex: 'title',
        title: 'Available',
    },
];
const rightTableColumns = [
    {
        dataIndex: 'title',
        title: 'Granted',
    },
];
const DragAndDrop = (props) => {
    const { allorders, targetKeys, setTargetKeys } = props;
    const [showSearch] = useState(true);
    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    return (
        <>
            <TableTransfer
                dataSource={allorders}
                targetKeys={targetKeys}
                disabled={false}
                showSearch={showSearch}
                onChange={onChange}
                filterOption={(inputValue, item) => {
                    return item.title.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1;
                }}
                leftColumns={leftTableColumns}
                rightColumns={rightTableColumns}
            />
        </>
    );
};
export default DragAndDrop;
