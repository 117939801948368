import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PermissionChecker from '../../../components/common/PermissionChecker';
import FilterDialog from '../../../components/common/FilterButton/FilterDialog';

export const UsersFilter = (props) => {
    const {
        filters,
        onFiltersApply,
        onFiltersClear,
        selectedUsers,
        filterProperties,
        onBulkStatusChangeConfirmation,
        initialFilters,
    } = props;

    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    return (
        <>
            <Grid container spacing={1} direction="row-reverse" justifyContent="flex-start" alignItems="center" mb={2}>
                {/* {selectedUsers.length > 0 && (
          <PermissionChecker
            requiredPermissions={[
              'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_EDIT_SECUSER',
              'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_DELETE_SECUSER',
              'ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEND_ESS_INVITE_TO_MULTIPLE_EMPLOYEES',
            ]}
            noAuthRedirect={false}
          >
            <Grid item alignContent='flex-end' xs='auto'>
              <UserBulkActionMenu
                disabled={false}
                selectedUsers={selectedUsers}
                selectedCount={selectedUsers.length}
                onBulkStatusChangeConfirmation={onBulkStatusChangeConfirmation}
              />
            </Grid>
          </PermissionChecker>
        )} */}
                <Grid item alignContent="flex-end" xs="auto">
                    <PermissionChecker
                        requiredPermissions={['ROLE_BASE_PERMISSION_EMPLOYEE_INDEX_SEARCH_SECUSER']}
                        noAuthRedirect={false}
                    >
                        <Button
                            color="primary"
                            disabled={false}
                            onClick={() => setOpenFilterDialog(true)}
                            startIcon={<FilterAltIcon />}
                            size="medium"
                            variant={filters.length ? 'contained' : 'text'}
                        >
                            Filter
                        </Button>
                    </PermissionChecker>
                    <FilterDialog
                        onApply={onFiltersApply}
                        onClear={onFiltersClear}
                        onClose={() => setOpenFilterDialog(false)}
                        open={openFilterDialog}
                        operators={[]}
                        properties={filterProperties}
                        initialFilters={initialFilters}
                    />
                </Grid>
                {/* <Grid item alignContent='flex-end' xs='auto'>
          <Button
            color='secondary'
            disabled={false}
            onClick={() => console.log(true)}
            size='medium'
            variant='text'
            startIcon={<ExportIcon fontSize='small' />}
          >
            Export
          </Button>
        </Grid> */}
            </Grid>
        </>
    );
};

UsersFilter.defaultProps = {
    filters: [],
    selectedUsers: [],
    view: 'all',
    actionItems: <></>,
};

UsersFilter.propTypes = {
    disabled: PropTypes.bool,
    filters: PropTypes.array,
    onFiltersApply: PropTypes.func,
    onFiltersClear: PropTypes.func,
    onViewChange: PropTypes.func,
    selectedUsers: PropTypes.array,
    view: PropTypes.string,
    actionItems: PropTypes.element,
};
