import React, { useCallback, useState } from 'react';
import { Button, MenuItem, Typography } from '@mui/material';
import TextField from './TextField';
import { styles } from '../DynamicForm/styles';
import { DatePicker } from '../Datepicker';

const DynamicField = ({ formik, fieldKey, field, disabled = false, sx = {} }) => {
    const [fileName, setFileName] = useState(null);
    const handleFileChange = useCallback(
        (key, value) => {
            formik.setFieldValue(key, value);
        },
        [formik?.setFieldValue],
    );

    const fieldName = fieldKey ? field[fieldKey] : field['fieldLabel'];

    if (field.fieldDataType === 'String' || field.fieldDataType === 'Double' || field.fieldDataType === 'Integer') {
        return (
            <TextField
                error={Boolean(formik.touched[fieldName] && formik.errors[fieldName])}
                fullWidth
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                label={field.fieldLabel}
                name={fieldName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={disabled}
                value={formik.values[fieldName]}
                type={field.fieldDataType === 'String' ? 'text' : 'number'}
                sx={sx}
            />
        );
    } else if (field.fieldDataType === 'Date') {
        return (
            <DatePicker
                error={Boolean(formik.touched[fieldName] && formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                label={field.fieldLabel}
                fullWidth
                disabled={disabled}
                name={fieldName}
                onBlur={formik.handleBlur}
                onChange={formik.setFieldValue}
                value={formik.values[fieldName]}
            />
        );
    } else if (field.fieldDataType === 'Single Selection List' || field.fieldDataType === 'Multiple Selection List') {
        return (
            <TextField
                error={Boolean(formik.touched[fieldName] && formik.errors[fieldName])}
                fullWidth
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                id={fieldName}
                disabled={disabled}
                name={fieldName}
                value={formik.values[fieldName]}
                label={field.fieldLabel}
                onChange={formik.handleChange}
                multiple={field.fieldDataType === 'Multiple Selection List'}
                select
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {field.fieldListOptions?.map((fieldListOption) => {
                    return (
                        <MenuItem
                            key={fieldListOption?.key || fieldListOption}
                            value={fieldListOption?.key || fieldListOption}
                        >
                            {fieldListOption?.label || fieldListOption}
                        </MenuItem>
                    );
                })}
            </TextField>
        );
    } else if (field.fieldDataType === 'Boolean') {
        return (
            <TextField
                error={Boolean(formik.touched[fieldName] && formik.errors[fieldName])}
                fullWidth
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                id={fieldName}
                disabled={disabled}
                name={fieldName}
                value={formik.values[fieldName]}
                label={field.fieldLabel}
                onChange={formik.handleChange}
                select
            >
                <MenuItem value={'true'}>True</MenuItem>
                <MenuItem value={'false'}>False</MenuItem>
            </TextField>
        );
    } else if (field.fieldDataType === 'File Attachment') {
        return (
            <Button sx={styles.fileButton} variant="outlined" component="label" color="secondary" disabled={disabled}>
                <div className="fileLabel">{fieldName}</div>
                {fileName ? (
                    <div className="fileName">
                        <Typography variant="body2">{fileName}</Typography>
                    </div>
                ) : (
                    <div className="noFile">
                        <Typography variant="body2">{'Upload File'}</Typography>
                    </div>
                )}
                <TextField
                    error={Boolean(formik.touched[fieldName] && formik.errors[fieldName])}
                    fullWidth
                    helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                    name={fieldName}
                    onBlur={formik.handleBlur}
                    type="file"
                    disabled={disabled}
                    sx={{
                        position: 'absolute',
                        opacity: 0,
                    }}
                    InputLabelProps={{
                        shrink: 'true',
                    }}
                    onChange={(e) => {
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            if (fileReader.readyState === 2) {
                                handleFileChange([fieldName], fileReader.result);
                            }
                        };
                        fileReader.readAsDataURL(e.target.files[0]);
                        setFileName(e.target.files[0].name);
                    }}
                />
            </Button>
        );
    }
    return null;
};

export default DynamicField;
