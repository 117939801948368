export const styles = {
    tableContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    avatar: {
        width: 64,
        height: 64,
    },
    titleWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    link: {
        display: 'block',
    },
    resourceUnavailable: {
        flexGrow: 1,
        m: 2,
    },
    tableCell: {
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden', // Hide overflow text
        textOverflow: 'ellipsis', // Display ellipsis (...) for overflow
        maxWidth: '250px',
    },
    noRecordsFound: {
        verticalAlign: 'center',
        fontSize: '14px',
        fontWeight: 600,
        color: '#6E6E72',
    },
};
