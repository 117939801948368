export const styles = {
    growBox: {
        flexGrow: 1,
    },
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        padding: '24px 64px',
        height: '100%',
        position: 'relative',
    },
    tabpanel: {
        pr: 0,
        pl: 0,
    },
    divider: {
        width: '60%',
    },
    backButton: {
        color: 'neutral.500',
    },
    primaryButton: {
        alignItems: 'center',
        display: 'flex',
        py: 2,
        mb: 2,
    },
};
