import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';
import { UserMenu } from './Users/UserMenu';
import ActionTableButton from '../../components/common/ActionButtonAndMenu/ActionTableButton';

export const columns = () => [
    {
        fieldName: 'username',
        name: 'username',
        label: 'USERNAME',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'emailAddresses',
        name: 'eId',
        label: 'EMAIL ID',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.[0]}
            </Typography>
        ),
    },
    {
        fieldName: 'firstName',
        name: 'firstName',
        label: 'FIRST NAME',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'lastName',
        name: 'lastName',
        label: 'LAST NAME',
        type: 'string',
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]}
            </Typography>
        ),
    },
    {
        fieldName: 'roles',
        name: 'displayName',
        label: 'ROLE',
        type: 'string',
        formatValue: ({ row, column }) => {
            const roles = row[column.fieldName];
            const role = (Array.isArray(roles) && roles.length > 0 ? Object.keys(roles[0]) : roles) || [];
            return (
                <Typography variant="body2" noWrap>
                    {role.join(',')}
                </Typography>
            );
        },
    },
    {
        fieldName: 'mobileNumbers',
        name: 'mb',
        label: 'MOBILE NUMBER',
        type: 'string',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName]?.[0]}
            </Typography>
        ),
    },
    {
        fieldName: 'passwordExpired',
        name: 'passExp',
        label: 'PASSWORD EXPIRED',
        type: 'boolean',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName] ? 'Yes' : 'No'}
            </Typography>
        ),
    },
    {
        fieldName: 'accountLocked',
        name: 'accountLocked',
        label: 'ACCOUNT LOCKED',
        type: 'boolean',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName] ? 'Yes' : 'No'}
            </Typography>
        ),
    },
    {
        fieldName: 'enabled',
        name: 'accountEnabled',
        label: 'ACCOUNT ENABLED',
        type: 'boolean',
        filter: true,
        formatValue: ({ row, column }) => (
            <Typography variant="body2" noWrap>
                {row[column.fieldName] ? 'Yes' : 'No'}
            </Typography>
        ),
    },
    {
        id: 'action',
        label: 'Actions',
        filter: true,
        sticky: true,
        formatValue: ({ row, keyColumn, action }) => {
            const roles = row['roles'];
            const role = (Array.isArray(roles) && roles.length > 0 ? Object.keys(roles[0]) : roles) || [];
            return (
                <UserMenu
                    Component={ActionTableButton}
                    row={{ ...row, roleList: role }}
                    keyColumn={keyColumn}
                    handleAction={action}
                />
            );
        },
    },
];

export const useColumns = () => {
    const [columnState, setColumnState] = useState([]);
    const { user } = useSelector((state) => state.session);

    useEffect(() => {
        let finalColumns = [...columns()];

        const isSuperAdmin = hasPermission(user?.authorities, ['ROLE_SUPER_ADMINISTRATOR']);
        if (isSuperAdmin) {
            const companyColumn = [
                {
                    fieldName: 'company',
                    name: 'company',
                    label: 'COMPANY',
                    type: 'string',
                    formatValue: ({ row, column }) => row[column.fieldName],
                },
            ];
            const actionItem = finalColumns.pop();
            finalColumns = [...finalColumns, ...companyColumn, actionItem];
        }
        setColumnState(finalColumns);
    }, []);
    return columnState;
};
