import React, { useState } from 'react';
import { Button, Menu, MenuList, MenuItem, DialogTitle, DialogContentText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import { usePopover } from '../../../hooks/use-popover';

const DesignationBulkActionMenu = (props) => {
    const { disabled, onArchive, onDelete, selectedCount, ...other } = props;
    const [anchorRef, open, handleOpen, handleClose] = usePopover();
    const [openDialog, setOPenDialog] = useState(false);
    const [actionType, setActionType] = useState('');

    const handleDialogClose = () => {
        setOPenDialog(false);
    };

    const handleMenuItemClick = (actionType) => {
        setOPenDialog(true);
        setActionType(actionType);
    };

    const getContent = (type) => {
        switch (type) {
            case 'edit':
                return (
                    <>
                        {' '}
                        <Box>
                            <DialogTitle>Edit Designations</DialogTitle>
                            <DialogContentText>Are You Sure you want to edit selected designations?</DialogContentText>
                        </Box>
                    </>
                );
            case 'delete':
                return (
                    <>
                        {' '}
                        <Box>
                            <DialogTitle>Delete Designations</DialogTitle>
                            <DialogContentText>
                                Are You Sure you want to delete selected designations?
                            </DialogContentText>
                        </Box>
                    </>
                );
            case 'close':
                return 'close';
            default:
                return null;
        }
    };

    return (
        <>
            <Button
                color="primary"
                disabled={disabled}
                onClick={handleOpen}
                ref={anchorRef}
                size="medium"
                startIcon={<ExpandMoreIcon />}
                variant="contained"
                {...other}
            >
                Bulk Actions
            </Button>
            <Menu
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        minWidth: 160,
                    },
                }}
            >
                <MenuList dense>
                    <MenuItem onClick={() => handleMenuItemClick('edit')}>
                        <ListItemIcon>
                            <EditIcon />{' '}
                        </ListItemIcon>
                        <ListItemText variant="body2">Edit</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('delete')}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText variant="body2">Delete</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
            <Dialog open={openDialog} onClose={() => setOPenDialog(false)}>
                <DialogContent>{getContent(actionType)}</DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleDialogClose}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DesignationBulkActionMenu;
