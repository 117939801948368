import React, { useEffect } from 'react';
import { Container, Grid, Typography, Card, CardContent } from '@mui/material';
import { Box } from '@mui/system';
import { styles } from './styles';
import PositionStatusGraph from './PositionStatusGraph';
import PositionMappedGraph from './PositionMappedGraph';
import { ChangeRequestsGraph } from './ChangeRequestsGraph';
import { useDispatch, useSelector } from 'react-redux';
import { getPositionMappingSummaryByStatus, getRecruitmentPolicySummary } from '../../store/slices/dashboard';

const Dashboard = (props) => {
    const { positionMappingSummaryByStatus, recruitmentPolicySummary } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPositionMappingSummaryByStatus());
        dispatch(getRecruitmentPolicySummary());
    }, []);

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        item
                        container
                        spacing={0}
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            py: '32px !important',
                        }}
                    >
                        <Grid item>
                            <Typography variant="h4">Dashboard</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            pt: '0px !important',
                        }}
                    >
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={12} xs={12}>
                                        <PositionStatusGraph />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <PositionMappedGraph
                                            data={positionMappingSummaryByStatus?.data}
                                            title={'Position with Change Policies'}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <PositionMappedGraph
                                            data={recruitmentPolicySummary?.data}
                                            title={'Positions with Recruitment Policies'}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <ChangeRequestsGraph />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Dashboard;
