import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

const series = [
    {
        key: 'mappedPositionsCount',
        label: 'Mapped Positions',
    },
    {
        key: 'nonMappedPositionsCount',
        label: 'Not Mapped Positions',
    },
];

export const colors = ['#BCE2E2', '#187B96', '#81c4db', '#40aace'];

const PositionMappedGraph = (props) => {
    const { data, title } = props;
    const theme = useTheme();
    const [apiChartSeries, setAPIChartSeries] = useState([]);

    useEffect(() => {
        const newSeries = series.map((item, index) => {
            return {
                color: colors[index],
                data: data?.[item?.key] || 0,
                name: item?.label,
            };
        });
        setAPIChartSeries(newSeries);
    }, [data]);

    const chartOptions = useMemo(
        () => ({
            chart: {
                background: 'transparent',
            },
            colors: apiChartSeries.map((item) => item?.color),
            dataLabels: {
                enabled: false,
            },
            labels: apiChartSeries.map((item) => item?.name),
            theme: {
                mode: theme.palette.mode,
            },
            legend: {
                show: false,
            },
        }),
        [apiChartSeries],
    );

    const chartSeries = useMemo(
        () => apiChartSeries.filter((item) => item?.data).map((item) => item?.data),
        [apiChartSeries],
    );

    return (
        <Card sx={{ height: '100%' }} {...props}>
            <CardHeader title={title} />
            <Divider />
            <Grid
                alignItems="center"
                container
                sx={{
                    justifyContent: {
                        sm: 'flex-start',
                        xs: 'center',
                    },
                    py: 3,
                }}
            >
                <Grid item>
                    <Chart height={200} options={chartOptions} series={chartSeries} type="donut" legend={false} />
                </Grid>
                <Grid item>
                    <Typography color="textSecondary" variant="subtitle2">
                        Total Policies
                    </Typography>
                    <Typography color="textPrimary" sx={{ my: 1 }} variant="h4">
                        {data?.['Total Policies']}
                    </Typography>
                    {apiChartSeries.map((item) => (
                        <Box
                            key={item?.name}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                '& + &': {
                                    mt: 1,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: item?.color,
                                    borderRadius: 1,
                                    height: 8,
                                    mr: 1,
                                    width: 8,
                                }}
                            />
                            <Typography color="textPrimary" variant="body2">
                                {`${item?.name} : ${item?.data}`}
                            </Typography>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Card>
    );
};

export default PositionMappedGraph;
