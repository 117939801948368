import React from 'react';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

const columns = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'Candidate',
        label: 'candidates',
    },
];

const RoleDetails = ({ data }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Table sx={{ minWidth: 1000 }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id}>{column.label}</TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Granted Permissions</TableCell>
                            <TableCell>
                                <Box>
                                    {!!data?.candidate &&
                                        data?.candidate.map((item) => {
                                            return (
                                                <Typography key={item.id} color="inherit" variant="inherit">
                                                    {item.description}
                                                </Typography>
                                            );
                                        })}
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </Box>
    );
};

export default RoleDetails;
