import { createSlice } from '@reduxjs/toolkit';

const companies = createSlice({
    name: 'companies',
    initialState: {
        selectedCompanies: [],
        companies: {
            count: 0,
            data: [],
            states: {},
        },
    },
    reducers: {
        updateCompanies(state, action) {
            const { companyInstanceList = [], companyInstanceCount = 0 } = action.payload;
            state.companies.data = companyInstanceList;
            state.companies.count = companyInstanceCount;
        },
        updateSelectedCompanies(state, action) {
            const { isChecked, rowId } = action.payload;

            if (isChecked) {
                state.selectedCompanies.push(rowId);
                return;
            }
            state.selectedCompanies = state.selectedCompanies.filter((selectedRow) => selectedRow !== rowId);
        },
        updateAllSelectedCompanies(state, action) {
            const { isChecked } = action.payload;
            state.selectedCompanies = [];
            if (isChecked) {
                state.companies.data.map((row) => state.selectedCompanies.push(row['id']));
                return;
            }
            state.selectedCompanies = [];
        },
    },
});

export const { updateCompanies, updateSelectedCompanies, updateAllSelectedCompanies } = companies.actions;

export default companies.reducer;
