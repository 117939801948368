import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { useStyles } from './styles';

const icons = {
    error: <ErrorIcon color="error" fontSize="large" />,
    warning: <WarningIcon color="warning" fontSize="large" />,
    info: <InfoIcon color="info" fontSize="large" />,
};

const ConfirmationDialog = (props) => {
    const { message, onCancel, onConfirm, open, title, variant, ...other } = props;
    const styles = useStyles();

    return (
        <Dialog onClose={onCancel} open={open} className={styles.dialog} maxWidth="sm" fullWidth {...other}>
            <DialogTitle className={styles.dialogTitle}>
                {icons[variant]}
                <Typography color="inherit" className={styles.typography} variant="inherit">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onCancel} variant="text">
                    Cancel
                </Button>
                <Button color="primary" onClick={onConfirm} variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.defaultProps = {
    open: false,
};

ConfirmationDialog.propTypes = {
    message: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['error', 'warning', 'info']),
};

export default ConfirmationDialog;
