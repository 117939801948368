import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Plus as PlusIcon } from '../../assets/icons/plus';
import CustomTable from '../../components/common/CustomTable';
import { styles } from './styles';
import { api } from '../../axios';
import { DesignationsFilter } from '../../features/designations/Designations/DesignationsFilter';
import { columns } from './columns';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateDesignations,
    updateSelectedDesignations,
    updateAllSelectedDesignations,
} from '../../store/slices/designation';
import PermissionChecker from '../../components/common/PermissionChecker';
import {
    clearFilters,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    setFilters,
    setInitialFilters,
} from '../../store/slices/tableController';

const Designations = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const controller = useSelector((state) => state.tableController);

    const { enqueueSnackbar } = useSnackbar();
    const { designations, selectedDesignations } = useSelector((state) => state.designations);

    useEffect(() => {
        dispatch(
            setInitialFilters([
                {
                    property: {
                        name: 'name',
                        type: 'string',
                    },
                    operator: {
                        value: '',
                        typesAccepted: [],
                    },
                    value: '',
                },
            ]),
        );
    }, []);

    const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
        setLoading(true);
        try {
            if (searched) {
                const query = new URLSearchParams({});
                if (filters.length) {
                    // filters.forEach((filter) => query.append(filter.property, filter.value));
                    query.append('c', filters[0].property);
                    query.append('q', filters[0].value);
                }
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/searchDesignationAction?${query.toString()}`);
                if (response.data) {
                    dispatch(updateDesignations(response.data));
                }
            } else {
                const query = new URLSearchParams({});
                if (sortBy) {
                    query.append('sort', sortBy);
                    query.append('order', sort);
                }
                query.append('offset', page * pageSize);
                query.append('max', pageSize);
                const response = await api.post(`/designations?${query.toString()}`);
                if (response.data) {
                    dispatch(updateDesignations(response.data));
                }
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (fetchData)
            fetchData(
                controller.searched,
                controller.filters,
                controller.sort,
                controller.sortBy,
                controller.page,
                controller.pageSize,
            );
    }, [
        controller.searched,
        controller.filters,
        controller.sort,
        controller.sortBy,
        controller.page,
        controller.pageSize,
        fetchData,
    ]);

    const handleFiltersApply = (newFilters) => {
        const parsedFilters = newFilters.map((filter) => ({
            property: filter.property.name,
            value: filter.value,
            operator: filter.operator.value,
        }));

        dispatch(setFilters(parsedFilters));
    };

    const handleFiltersClear = () => {
        dispatch(clearFilters());
    };

    const handlePageChange = (newPage) => {
        dispatch(onPageChange(newPage - 1));
    };

    const handlePageSizeChange = (newPageSize) => {
        dispatch(onPageSizeChange(newPageSize));
    };

    const handleSortChange = (event, property) => {
        const isAsc = controller.sortBy === property && controller.sort === 'asc';
        dispatch(
            onSortChange({
                sort: isAsc ? 'desc' : 'asc',
                sortBy: property,
            }),
        );
    };

    const handleRowClick = (row) => {
        navigate(`/settings/designations/${row}`);
    };

    const handleSelect = (event, rowId) => {
        dispatch(
            updateSelectedDesignations({
                isChecked: event.target.checked,
                rowId,
            }),
        );
    };

    const handleSelectAll = (event) => {
        dispatch(
            updateAllSelectedDesignations({
                isChecked: event.target.checked,
            }),
        );
    };

    return (
        <Box sx={styles.growBox}>
            <Container maxWidth="lg" sx={styles.pageWrapper}>
                <Grid container direction="column" spacing={2}>
                    <Grid
                        sx={{
                            py: '32px !important',
                        }}
                        item
                        container
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="center"
                        py={4}
                    >
                        <Grid item>
                            <Typography variant="h4">Designations</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        <PermissionChecker
                            requiredPermissions={['ROLE_BASE_PERMISSION_INDEX_CREATE_DESIGNATION']}
                            noAuthRedirect={false}
                        >
                            <Grid item xs="auto">
                                <Button
                                    color="primary"
                                    onClick={() => navigate('/settings/designations/add')}
                                    size="medium"
                                    startIcon={<PlusIcon fontSize="small" />}
                                    variant="contained"
                                >
                                    Add Designation
                                </Button>
                            </Grid>
                        </PermissionChecker>
                    </Grid>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <DesignationsFilter
                                disabled={false}
                                filters={controller.filters}
                                onFiltersApply={handleFiltersApply}
                                onFiltersClear={handleFiltersClear}
                                selectedDesignations={selectedDesignations}
                                filterProperties={columns}
                                initialFilters={controller.initialFilters}
                            />
                            <CustomTable
                                onRowClick={handleRowClick}
                                isLoading={loading}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handlePageSizeChange}
                                onSelect={handleSelect}
                                onSelectAll={handleSelectAll}
                                onSortChange={handleSortChange}
                                page={controller.page + 1}
                                columns={columns}
                                rows={designations.data}
                                rowsCount={designations.count}
                                selectedRows={selectedDesignations}
                                sort={controller.sort}
                                sortBy={controller.sortBy}
                                showRowSelector={false}
                                showPagignation
                                keyColumn="id"
                                action={() =>
                                    fetchData(
                                        controller.searched,
                                        controller.filters,
                                        controller.sort,
                                        controller.sortBy,
                                        controller.page,
                                        controller.pageSize,
                                    )
                                }
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        </Box>
    );
};

export default Designations;
