export const styles = {
    actionMenuButton: {
        color: 'secondary.main !important',
        backgroundColor: 'primary.light',
    },
    titleWrapper: {
        display: 'flex',
        marginTop: '32px',
    },
    dialogTitle: {
        textAlign: 'center',
    },
    buttonsWrapper: {
        justifyContent: 'center',
    },
    growBox: {
        flexGrow: 1,
    },
};
