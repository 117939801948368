import { createSlice } from '@reduxjs/toolkit';

const roles = createSlice({
    name: 'roles',
    initialState: {
        selectedRoles: [],
        roles: {
            count: 0,
            data: [],
            states: {},
        },
    },
    reducers: {
        updateRoles(state, action) {
            const { secRoleInstanceList = [], secRoleInstanceCount = 0 } = action.payload;
            state.roles.data = secRoleInstanceList.map((item) => ({
                ...item,
                ...item.role,
            }));
            state.roles.count = secRoleInstanceCount;
        },
        updateSelectedRoles(state, action) {
            const { isChecked, rowId } = action.payload;

            if (isChecked) {
                state.selectedRoles.push(rowId);
                return;
            }
            state.selectedRoles = state.selectedRoles.filter((selectedRow) => selectedRow !== rowId);
        },
        updateAllSelectedRoles(state, action) {
            const { isChecked } = action.payload;
            state.selectedRoles = [];
            if (isChecked) {
                state.roles.data.map((row) => state.selectedRoles.push(row['id']));
                return;
            }
            state.selectedRoles = [];
        },
    },
});

export const { updateRoles, updateSelectedRoles, updateAllSelectedRoles } = roles.actions;

export default roles.reducer;
